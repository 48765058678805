@import "src/scss/includes";

@mixin tableTemplate {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(300px, 4fr) minmax(200px, 1fr) minmax(350px, 2fr);
  grid-auto-rows: 40px;
  align-items: center;
  .table-item {
    padding: 0 20px 0 20px;
  }
}

.mapping-template-page {
  padding: 0;
  .template-table {
    display: grid;
    .table-header{
      background-color: $gray10;
      @include tableTemplate;
      .table-item {
        font-size: 17px;
      }
    }
    .table-rows {
      @include tableTemplate;
      &:hover {
        background-color: $gray5;
        border: 1px $gray15 dashed;
        cursor: pointer;
      }
    }
    .table-placeholder {
      display: flex;
      margin: 60px;
      align-items: center;
      justify-content: center;
    }
  }

}
