@import "src/scss/includes";

.icf-files-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .icf-files-list {
    display: flex;
  }

  .single-file-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
    white-space: nowrap;

    .file-name {
      color: $darkBlue100;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-left: 7px;
      text-decoration: underline;
    }
  }
}

@media (max-width: 950px) {
 .icf-files-container .single-file-container .file-name {
    white-space: normal;
  }
}
