.eds-modal-box.revenue-adjustment-history-modal {
  .eds-modal-box-container {
    width: 100%;
    max-width: 1700px;

    .eds-modal-box-body {
      padding: 0;
    }
  }
}
