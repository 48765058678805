@import "src/scss/includes";

@mixin box-shadow($shadow...) {
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

$shadowColor: #00000026;
$shadows: 0 1px 3px 0 $shadowColor, 0 2px 9px 0 $shadowColor;

.r-ss-wrap {
  border-radius: 0;
  font-size: 14px;
  .r-ss-selected-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
  .r-ss-expanded {
    z-index: 100;
  }
  > .r-ss-trigger {
    background-color: $white;
    .r-ss-selection-clear {
      right: 33px;
      color: $gray30;
      background-color: transparent;
      border: none;
      font-size: 18px !important;
      height: 18px;
      width: 18px;
      > span {
        &:before {
          font-weight: normal;
          font-style: normal;
          font-size: 20px;
          line-height: 1;
          content: url("../images/icons/cancel-clear-button.svg");
          width: 80px;
          pointer-events: none;
          left: -30px;
          top: 0;
          color: initial;
        }
      }
      &:hover {
        background-color: transparent;
        color: $purple100;
      }
      &:focus {
        background-color: transparent;
        outline: none;
      }
    }
  }
  &.r-ss-expanded {
    z-index: 102;
    > .r-ss-trigger {
      border-bottom-color: $purple75;
      outline: 0 none;
      background-color: $white;
      border-bottom-width: 2px;
      padding-bottom: 13px;
    }
  }
}

.selected-dropdown-items {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.r-ss-wrap {
  min-width: 206px;

  .r-ss-trigger {
    border-radius: 0;
    height: 36px;
    padding-top: 3px;
    padding-left: 7px;
    padding-bottom: 3px;
    border-color: $gray15;

    &.r-ss-disabled {
      background-color: $gray5 !important;
      color: $gray85;
      opacity: 1;
      border-color: $gray15;
    }

    .r-ss-selected-label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
    .r-ss-selection-clear {
      top: 7px;
    }
    &.r-ss-open {
      border-bottom: 1px solid $gray10;
      &:focus {
        border-color: $gray10;
      }
    }
    .carat {
      border: 5px solid transparent;
      right: 12px;
      &.down {
        bottom: 9px;
        border-color: $gray60 transparent transparent transparent;
      }
      &.up {
        bottom: 14px;
        border-color: transparent transparent $gray60 transparent;
      }
    }
  }
  .r-ss-dropdown {
    top: 110%;
    border: none;
    border-radius: 4px 4px 0 0;
    @include box-shadow($shadows);
    &:after{
      display: block;
      content: '';
      width: 100%;
      background-color: transparent;
      height: 30px;
      position: absolute;
    }
    .r-ss-option-group-heading {
      margin-top: 0;
      font-size: 16px;
      padding-left: 15px;
      border-color: $gray5;
      background-color: $white;
      font-weight: 500;
      line-height: 28px;
      &:first-child {
        border-top: none;
      }
    }

    .r-ss-search-wrap {
      padding: 0;
      background-color: $purple20;
      border-bottom: 1px solid $gray15;
      border-radius: 4px 4px 0 0;

      .r-ss-search-inner {
        height: 55px;
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;

        .r-ss-search-clear {
          color: $gray30;
          background-color: transparent;
          opacity: 0.25 !important;
          width: 20px;
          height: 20px;
          padding: 0;
          font-size: 24px;
          font-weight: 400;
          border: none;
          border-radius: 10px;
          top: 18px;
          right: 36px;
          > span {
            &:before {
              font-weight: normal;
              font-style: normal;
              font-size: 20px;
              line-height: 1;
              content: url("../images/icons/cancel-clear-button.svg");
              width: 80px;
              pointer-events: none;
              left: -30px;
              top: 0;
              color: initial;
            }
          }
          &:hover {
            background-color: transparent;
            color: $purple100;
            opacity: 1 !important;
          }
          &:focus {
            background-color: transparent;
            outline: none;
            opacity: 1;
          }
        }
        .r-ss-magnifier {
          top: 16px;
          right: 12px;
          background-image: url(../images/search_black_24dp.svg);
          width: 21px;
          height: 21px;
          color: $gray60;
        }
        .r-ss-search-aria-label {
          display: block;
          padding-left: 16px;
          padding-top: 5px;
          margin: 0;
          color: $purple80;
          font-size: 12px;
        }
        input {
          height: 24px;
          padding: 0 60px 0 16px;
          line-height: 20px;
          border: none;
          background: transparent;
        }
      }
    }

    .r-ss-options-wrap {
      max-height: 267px;
      border-radius: 4px 4px 0 0;
      .r-ss-dropdown-options {
        .r-ss-dropdown-option {
          padding: 14px 14px 14px 15px;
          border: none;
          &::selection {
            background-color: $white;
          }
          &:focus {
            background-color: $purple20;
          }
          &:hover {
            background-color: $gray5;
            color: $gray85;
          }
          &.r-ss-selected {
            background-color: $white;
            color: $purple80;
            font-weight: bold;
            &:focus {
              background-color: $purple20;
            }
          }
          i {
            font-size: 14px;
          }
        }
      }
    }
  }
  label {
    top: 2px !important;
  }
}
