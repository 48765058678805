.new-table-fields-content {
  padding-top: 64px;
}

.new-table-answer-layout {
  display: inline-block;
}

.file-type-cell {
  -webkit-line-clamp: unset;
}