@import "src/scss/includes";

.appointment-window {
  padding-top: 16px;
  padding-bottom: 4px;
  .appointment-window-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 6px;
  }
  .projected-start-end-date {
    width: 250px;
    padding-right: 20px;
    font-size: 14px;
    color: $gray70;
    font-weight: normal;
    line-height: 40px;
  }
}
