@import "src/scss/includes";

.calendar-event-type-selector-container {
  .category-title {
    font-size: 14px;
    color: $gray70;
    margin: 15px 0 10px 5px;
  }

  label.eui-checkbox {
    padding: 5px 0;
  }
}
