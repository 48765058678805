@import "src/scss/includes";
.erc-question-answer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 -20px;
  padding: 0 20px;

  .erc-question-answer-label {
    padding: 15px 15px 15px 0;
    display: flex;
    align-items: center;
    flex: 0 0 40%;
    width: 40%;
    max-width: 400px;
    color: $gray85;
  }

  .erc-question-answer-field {
    padding: 15px;
    text-align: center;
    flex: 0 0 250px;
    width: 250px;
    * {
      cursor: not-allowed;
    }
  }
  .erc-question-answer-field-comment {
    flex: 1 0 140px;
    width: 140px;
    font-style: italic;
    font-weight: 100;
  }

  .erc-question-answer-highlight {
    padding-left: 80px;
    padding-right: 40px;
    flex: 0 0 140px;
    width: 140px;
  }

  &.erc-question-answer-edited {
    background-color: #FFF9C7;
  }
  &:first-child {
    .erc-question-answer-highlight {
      position: relative;
      &:before {
        text-align: center;
        content: 'Highlighted for Review';
        position: absolute;
        top: -44px;
        left: 50%;
        width: 170px;
        display: block;
        margin-left: -65px;
        font-weight: 600;
      }
    }
  }
}
