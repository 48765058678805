@import "src/scss/includes";

.status-progress-section {
  min-height: 232px;
  overflow-y: auto;
  margin-bottom: 12px;
  &.item-groups {
    margin-top: 36px;
    min-height: 200px;
  }
  .status-progress-header {
    p{
      &:first-child{
        font-weight: bolder;
        font-size: 16px;
      }
    }
  }
  .status-progress-wrapper {
    display: flex;
    flex-grow: 1;
    margin-top: 30px;
    &:after{
      content: '';
      min-width: 103px;
      flex-grow: 1;
    }
  }
}
.forbidden-field *:hover {
  cursor: not-allowed;
}