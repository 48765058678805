.invoice-filters{
  display: flex;
  flex-direction: column;

  .general-header-wrapper > div {
    margin-bottom: 0;
  }
  .invoice-radio-inputs {
    flex-wrap: wrap;
    display: flex;
    align-items: baseline;
    margin-top: -15px;
    gap: 12px 34px;

    .invoice-radio {
      flex-wrap: wrap;
      display: flex;
      align-items: baseline;
      > span {
        font-weight: 500;
        margin-right: 16px;
      }
      .eui-form-group {
        align-items: baseline;
      }
    }
  }
}
