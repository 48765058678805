@import "src/scss/includes";
.eds-review-item-group-element {

  .eds-top-item-group{
    .history {
      width: 110px;
      padding: 0 10px;
      height: 26px;
    }

    .rie-not-performed {
      box-shadow: none;
      border-top: 1px solid $gray5;
      font-weight: 600;
      padding: 15px;
      > span {
        color: $purple75;
      }
    }
  }
}
