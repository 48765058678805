@import "src/scss/includes";

.section-container {
  border: 1px solid $gray10;

  .section-header {
    font-size: 14px;
    color: $black;
    min-height: 22px;

    .title{
      display: flex;
    }
    button {
      position: absolute !important;
      top: 8px;
      right: 10px;
    }
  }

  .section-body {
    .list-group {
      padding-top: 10px;

      .label-list {
        display: flex;
        flex-direction: column;
        .log-check-list-group-item {
          display: flex;
          min-height: 42px;
          padding: 10px 32px 10px 22px;
          color: $gray85;
          overflow: hidden;
          border: 1px  dashed $white;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;

          &:hover, &.dragging {
            background-color: $gray5;
            border: 1px dashed $blue100;

          button.right-position {
            display: block;
            position: absolute !important;
            top: 10px;
            right: 10px;
            font-size: 22px;
            color: $gray60;
          }
        }

          button.left-position {
            top: -2px;
          }
          span {
            margin-left: 10px;
          }
          button.right-position {
            display: none;
          }
        }
      }
    }
  }
}
