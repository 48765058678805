@import "src/scss/includes";

.time-template{
  display: flex;
  position: relative;
  height: 35px;

  .time-icon{
    color: $gray60;
    padding-right: 5px;
    font-size: 22px;
  }

  .dateicon3 {
    position: absolute;
    color: $gray30;
    display:inline-block;
    right: 10px;
    font-size: 20px;
    padding-top: 5px;
  }

  input{
    display: flex;
    flex-grow: 1;
    border-radius: 0;
    height: 35px;
    border: 1px solid $gray15;
    padding-left: 10px;
    width: 100%;
    &:focus{
      outline: none;
    }
  }
}