@import "src/scss/includes";
.eds-review-sidebar {
  background: $white;
  border-right: 1px solid $gray10;
  border-top: none;
  height: 100%;
  padding-top: 7px;
  overflow-y: auto;
  .eds-review-sidebar-list {
    padding-top: 8px;
    padding-bottom: 8px;
    h4 {
      padding-left: 26px;
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      padding-bottom: 8px;
    }
  }
}
