.eds-modal-box.patient-instruction-change-modal {
  .eds-modal-box-container {
    max-height: 90%;
    display: flex;
    flex-direction: column;
    .eds-modal-box-body{
      display: flex;
      overflow-y: auto;
      .eds-textarea{
        width: 100%;
        textarea{
          max-height: 300px;
        }
      }
    }
  }
}
