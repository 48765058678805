.eui-btn-group {
  display: flex;
  &.eui-btn-group-direction-row {
    flex-direction: row;
    .eui-btn {
      &.eui-btn-size-h56 {
        margin: 0 12px;
      }

      &.eui-btn-size-h40 {
        margin: 0 8px;
      }

      &.eui-btn-size-h28 {
        margin: 0 8px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &.eui-btn-group-type-singular {
      .eui-btn {
        margin: 0;
        &:not(:first-child):not(:last-child) {
          border-radius: 0;
        }
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  &.eui-btn-group-direction-column {
    flex-direction: column;
    .eui-btn {
      margin: 5px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
