@import "src/scss/includes";

.ae-log-row-history-link {
  display: flex;
  align-items: center;
  color: $darkBlue100;
  width: fit-content;

  i {
    font-size: 25px;
  }
}
