@import "src/scss/includes";

.deposit-history-modal {
  .eds-modal-box-body {
    overflow: auto;
    height: 74vh;
    display: flex;
    flex-direction: column;
    .invoice-info-section {
      display: flex;
      gap:20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $gray10;
      >div{
        margin-bottom: 8px;
      }
      .info-items-wrapper {
        display: flex;
        gap:20px;
        flex-wrap: wrap;
        flex-grow: 1;

        .info-item{
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          span {
            width: max-content;
          }
          .info-item-label {
            color: $gray50;
            font-weight: 600;
          }
          &.multi-line-content {
            max-width: 500px;
            span {
              width: auto;
            }
          }
        }
      }
    }

    .header {
      font-weight: 600;
      font-size: 18px;
    }

    .credit-application-reverse-all-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .history-wrapper {
      height: 100%;
      overflow-y: auto;
      display: grid;
      grid-template-columns: minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(250px, 1fr) minmax(max-content, 1fr);
      grid-auto-rows: max-content;
      &.credit-application {
        grid-template-columns: repeat(11, minmax(max-content, 1fr));
      }
      &.credit-application-no-reversal {
        grid-template-columns: repeat(10, minmax(max-content, 1fr));
      }
      .history-table-header-item {
        color: $gray50
      }
      >div {
        border-bottom: 1px solid $gray10;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .pinned-action {
        position: sticky;
        right: 0;
        background: $gray2;
        border-left: 1px solid $gray10;
        box-shadow: -2px 0 4px -2px rgba(0, 0, 0, 0.21);
      }
    }
  }
}
