@import "src/scss/includes";

.eui-toggle {
  cursor: pointer;
  align-items: center;
  margin: 0;
  display: inline-flex;
  user-select: none;
  position: relative;
  height: 24px;
  padding-right: 2px;
  > input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;

    ~ .eui-toggle-control {
      background-color: $gray60;
    }

    &:checked {
      ~ .eui-toggle-control {
        background-color: $green100;

        &:after {
          left: 21px;
        }

        &:before {
          content: "ON";
          left: 5px;
        }
      }
    }

    &[readonly] {
      ~ .eui-toggle-label, ~ .eui-toggle-control {
        cursor: default;
      }
    }
  }

  .eui-toggle-label {
    padding-right: 10px;
    line-height: 24px;
    font-size: 14px;
  }

  .eui-toggle-control {
    height: 18px;
    width: 36px;
    border-radius: 10px;
    position: relative;
    display: block;
    will-change: background-color;
    transition: background-color .2s ease-out;
    &:after {
      position: absolute;
      content: ' ';
      top: 3px;
      left: 3px;
      height: 12px;
      width: 12px;
      border-radius: 8px;
      background: $white;
      will-change: left;
      transition: left .2s ease-out;
    }

    &:before {
      color: $white;
      font-size: 9px;
      line-height: 14px;
      font-weight: 700;
      content: "OFF";
      position: absolute;
      left: 17px;
      top: 2px;
    }
  }

  &.eui-toggle-disabled {
    user-select: none;
    cursor: not-allowed;

    > input:disabled {
      ~ .eui-toggle-label {
        color: $gray30;
      }

      ~ .eui-toggle-control {
        background-color: $gray30;
        &:before {
          content: "";
        }
      }
    }
  }
}
