@import "src/scss/includes";

%read-only-overlay {
  cursor: not-allowed;
  content: ' ';
  opacity: 0.2;
  background: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 300;
}

.new-encounter-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: calc(100% - 124px);
  width: 100%;
  background-color: white;

  .page-info-header {
    margin-bottom: 0 !important;
    .edit-patient-primary-contact .edit-patient-primary-contact-field-group {
      display: flex;
      align-items: center;
      margin-top: -6px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: inherit;

    .left {
      background-color: #f6edf2;
      width: 355px;
      min-width: 355px;
      display: flex;
      height: 100%;
      flex-direction: column;

      .eui-input {
        padding: 20px;
      }

      .left-header {
        display: flex;
        flex-direction: column;
        background-color: #f6edf2;
        height: fit-content;
        top: 0;
        z-index: 100;
        h4{
          span{
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            max-width: 265px;
          }
        }

        >div{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        h4 {
          font-size: 24px;
          font-weight: 500;
          display: flex;
          flex-wrap: wrap;
        }

        .status-label {
          background-color: $gray10;
          border-radius: 20px;
          width: fit-content;
          padding: 5px 10px 5px 10px;
          display: inline-block;
          font-size: 14px;
          margin-top: 3px;
        }
        .canceled {
          background-color: $gray70;
          color: $white;
        }
      }

      .left-content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        position: relative;
        overflow-y: auto;

        .encounter-item-group-new, .encounter-item-group {
          box-shadow: none;
          border: none;
          border-radius: 0;
          margin: 0;

          &:hover {
            background-color: white;
          }
        }
      }

      .left-footer {
        display: flex;
        flex-direction: column;
        background-color: #f6edf2;
        padding: 16px 30px 30px 30px;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > span {
            display: flex;
            flex-grow: 1;
          }
        }
      }

      .search {
        position: relative;

        input {
          width: 100%;
          border-radius: 50px;
          outline: none;
          border: none;
          padding: 8px;
          padding-left: 40px;
        }

        .eui-icon {
          position: absolute;
          top: 3px;
          left: 8px;
          font-size: 22px;
          color: $gray60;
        }
      }

      a {
        color: $gray85;
      }

      .item-group {
        width: 100%;
        min-height: 50px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        justify-content: space-between;
        padding-left: 30px !important;
        padding-right: 15px !important;
        .bar {
          width: 50px;
          height: 7px;
          display: inline-block;
          margin-right: 5px;
        }
        > div {
          display: flex;
          flex-direction: row;
          > div {
            max-width: 250px;
            padding-left: 10px;
            > div {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

          }
        }

        &:hover {
          background-color: white;
        }

        .eui-icon {
          font-size: 22px;
        }

        .np {
          padding: 2px;
          background-color: #2D9CDB;
          border-radius: 3px;
          color: white;
          height: 24px;
          width: 24px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      width: calc(100% - 355px);
      position: relative;
      .log-ig-header-with-buttons{
        display: flex;
        flex-direction:row;
        margin: 4px 0px 26px -10px;
      }

      .disabled-cover{
        opacity: 0.7;
        background: white;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
      }
      &.disabled{
        label{
          z-index: 1!important;
        }
        i{
          z-index: 1!important;
        }
      }
      .get-domain-detail-elements{
        position: relative;
        height: 100%;

        section{
          margin: 0 !important;
          .cancel-button {
            left: calc(100% - 45px);
            top: 20px;
          }
        }
        .border{
          border: none !important;
        }
      }

      .logs-page {
        display: flex;
        overflow-y: auto;
        position: relative;
        height: 100%;
        top: 0;

        section {
          border: none !important;
          padding: 0!important;
        }
      }
      &.read-only {
        .table-item-group {
          > .table-item-group-scroll {
            > .table-item-group-general, > .table-item-group-section-headline, > .table-item-group-results, > .table-item-group-comment {
              &:after {
                @extend %read-only-overlay;
              }
            }
          }

          > .with-auto-direction {
            position: relative;
            &:after {
              @extend %read-only-overlay;
            }
          }
        }
        .multiple-upload-container .list-group {
          position: relative;
          z-index: 301;
          .close-icon {
            display: none;
          }
        }
        .item-group-field-input .item-group-body-dropdown-tooltip{
          z-index: 301;
        }
        .get-domain-detail-elements {
          height: auto;
          > section.border {
            > .cancel-button {
              z-index: 301;
            }
            &:after {
              @extend %read-only-overlay;
            }
          }
        }
        .logs-page {
          > .log-check {
            position: relative;
            &:after {
              @extend %read-only-overlay;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .new-encounter-page {
    .content {
      .left {
        width: 280px;
        min-width: 280px;

        .left-header {
          .px-3 {
            padding: 0 8px !important;
          }

          .mt-4 {
            margin-top: 8px !important;
          }

          .search {
            margin: 0 8px 8px 8px !important;
          }
        }

        > .left-content > div {
          &:first-child {
            > h5 {
              padding-left: 8px !important;

              &:first-child {
                margin-top: 8px !important;
              }
            }
          }
        }

        .encounter-item-group {
          max-width: 280px;

          .details-container {
            padding-left: 8px;
            padding-right: 8px;
          }

          .right-corner-button {
            margin-right: 8px;
          }
        }

        .left-footer {
          justify-items: center;
          padding: 8px !important;

          > div {
            .menu-button {
              width: 120px;
            }
          }
        }

        .edc-visit-date-button {
          .rc-menu {
            padding: 0;
            width: 260px;
            position: fixed;
            left: 24px !important;
            bottom: 40px !important;
            top: unset !important;
            z-index: 11111;
          }

          .dropdown-container {
            min-width: 228px;
            padding: 8px;
          }
        }
      }

      .right {
        width: calc(100% - 280px);
        padding-left: 0 !important;

        .mt-4 {
          margin-top: 8px !important;
        }

        .item-group-field-row .item-group-field-input {
          width: 25%;
          min-width: 180px !important;
        }

        .item-group-field-label{
          width: 25% !important;
          min-width: 180px !important;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .new-encounter-page {
    .content {
      .left {
        width: 220px;
        min-width: 220px;

        .encounter-item-group {
          max-width: 220px;

          .details-container {
            padding-left: 8px;
            padding-right: 8px;
          }

          .right-corner-button {
            margin-right: 8px;
          }
        }

        .left-footer {
          padding: 8px !important;
          > div {
            flex-direction: column;
            .menu-button {
              width: 130px;
            }
          }
          .rdtPicker .rdtDay {
            line-height: 27px;
            height: 27px;
          }
        }

        .study-manager-selector{
          .rc-menu {
            left: 36px !important;
          }
        }
        .edc-visit-date-button {
          .rc-menu {
            padding: 0;
            width: 240px;
            position: fixed;
            left: 24px !important;
            bottom: 40px !important;
            top: unset !important;
            z-index: 1111;
          }

          .dropdown-container {
            min-width: 228px;
            padding: 8px;
          }
        }
      }

      .right {
        width: calc(100% - 220px);
        padding-left: 0 !important;

        .eui-date-picker{
          max-width: 130px;
        }
        .eds-select{
          min-width: 150px;
          .r-ss-wrap{
            min-width: 150px;
          }
        }
      }
    }
  }
}
.log-item-group-name-status {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-right: 70px;
  >div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    .eui-btn {
      margin-top: 15px;
    }
  }
  .item-group-name{
    z-index: 3;
    &.review {
      color: $purple80;
      font-weight: 800;
      font-size: 22px;
    }

  }
  .log-row-status-chip {
    width: fit-content;
    background: $gray10;
    border-radius: 24px;
    padding: 2px 10px;
    margin-left: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: -19px
  }
}
