@import "src/scss/includes";

.calendar-page-info-header {
  .left-section {
    width: 283px;
  }
}

.rc-collapse.calendar-filter-collapse {
  border-right: none;
  border-top: none;
  border-bottom: none;
  background: none;
  height: 100%;
  .other-calendars {
    height: 50%;
    .rc-collapse-content {
      min-height: 400px;
      height: 100%;
    }
  }
  .calendar-date-picker-container{
    display: flex;
    padding-top: 10px;
    .rdtPicker {
      background: none;
      border: none;
    }
  }
  .rc-collapse-item {
    .rc-collapse-header {
      padding-right: 50px;
      font-weight: bold;
      border-bottom: none;

      .count {
        background-color: $purple80;
        color: $white;
        padding: 6px;
        display: inline-block;
        border-radius: 20px;
        line-height: 8px;
        min-width: 20px;
        min-height: 20px;
      }
    }
    .rc-collapse-content {
      background: none;
      height: fit-content;
      .rc-collapse-content-box {
        margin-bottom: 10px;
        height: fit-content;
      }
      .filter-container{
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        min-height: 150px;
        .eds-select-with-search{
          border-radius: 3px;
          max-width: 290px;
        }
      }
    }
  }
}

.calendar-page{
  &.full-screen-layout {
    width: 100%;
    height: calc(100% - 190px);
    position: absolute;
    padding-bottom: 36px;
    top: 0;
    margin-top: 150px;
    display: flex;
  }
  .navigation-flex-container{
    background-color: $gray2;
    .navigation-overlap-wrapper{
      height: calc(100% - 76px) !important;
      min-height: calc(100% - 76px) !important;
    }
  }
  .filter-buttons{
    position: fixed;
    bottom: 40px;
    background-color: $gray2;
    width: 310px;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 5px 30px;
    border-top: 1px solid $gray15;
    button{
      min-width: 100px;
    }
  }
}

@media (max-width: 1300px) {
  .calendar-page{
    .navigation-flex-container{
      position: absolute;
      height: calc(100% - 35px);
      .navigation-overlap-wrapper{
        border-right: 1px solid $gray15;
      }
    }
    .content-panel{
      padding-left: 30px;
    }
  }
}
