@import "src/scss/includes";
.eds-modal-box.cancel-ae-log-row-modal {
  .eds-modal-box-container {
    margin-top: 15vh;
    margin-bottom: auto;

    .eds-modal-box-body {
      max-height: 286px;
      overflow-y: auto;
      line-height: 110%;
      padding-top: 4px;
      padding-bottom: 10px;
      color: $gray85;
      font-size: 14px;
    }

    .reason-comment-div {
      padding: 18px 25px 0 25px;
    }
  }
}
