@import "src/scss/includes";

label.eui-input-selectors.eui-radio-button {
  > span em {
      border-radius: 50%;
      &:before {
        transition: all .2s ease 0s;
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        top: 50%;
        left: 50%;
        margin-top: -5px;
        margin-left: -5px;
        border-radius: 50%;
      }
    }
}
