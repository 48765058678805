@use 'sass:math';
@import "src/scss/includes";

.mr-dashboards {
  border-bottom: 1px solid $gray88;
  padding: 0 25px 16px 25px;
  margin-bottom: 15px;
  z-index: 1000;
  position: relative;
  background-color: $gray2;
  &.home-page{
    border: none;
    margin-bottom: 0;
  }
  .page-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-name-section {
      h1 {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: $black;
        margin: 0;
        padding: 0 25px 0 0;
      }
    }
  }
  .mr-dashboards-options-section {
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    overflow-y: auto;
    margin-top: 5px;
    .option-container{
      flex: 0 0 math.div(100%, 3);
      margin-top: 20px;
      padding: 15px;
      @media  screen and (max-width: 1115px) {
        flex: 0 0 math.div(100%, 2);
      }
      min-height: 120px;
      .option-content{
        display: flex;
        justify-content: space-between;
        .option-content-name{
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 160%;
          .option-content-icon{
            margin-right: 10px;
            font-size: 40px;
            color: #7d3559;
          }
        }
        .option-content-action{
          display: flex;
          align-items: center;
        }
      }
      .option-text{
        font-size: 13px;
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.6);;
      }
    }

  }
  .options-collapse-btn {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid $gray10;
    position: absolute;
    left: 50%;
    margin-left: math.div(29px,-2);
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    overflow: hidden;
    color: $gray70;
    cursor: pointer;
  }
  &.options-section-collapsed {
    .mr-dashboards-options-section {
      height: 0;
      overflow: hidden;
    }
  }
}
