@import "src/scss/includes";
.eds-review-control{
  border: 1px solid $gray10;
  border-top: none;
  border-left: none;
  background-color: $white;
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  .eds-review-history{
    margin: 0 20px 0 20px;
  }

  .eds-confirm-item-groups-count{
    font-weight: 700;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
