.patient-profile-sidebar-tab {
  width: 100%;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 25px;

  color: #555;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
  }

  &.patient-profile-sidebar-tab-selected, &:hover{
    background-color: white;
    color: #555;
    transition: .1s ease;
  }

  .patient-profile-sidebar-tab-icon {
    font-size: 35px;
    padding-right: 10px;
  }

  .patient-profile-sidebar-alert {
    font-size: 30px;
    margin-left: 20px;
    color: red;
    padding-right: 15px;
  }
}