@import "src/scss/includes";

.add-payable-to-modal-box .eds-modal-box-container {
  font-family: $ffRoboto;
  .eds-modal-box-header {
    >div {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
    }
    .header-text {
      font-weight: 500;
    }
  }
  .eds-modal-box-body {
    padding: 35px;
    .account-details {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .add-account-input {
      width: 345px;
      .eui-input-validation-message {
        padding-top: 0;
        padding-bottom: 20px;
      }
    }

    .banking-information-header {
      width: 100%;
      font-weight: 500;
      color: $gray85;
      margin-bottom: 23px;

    }
  }
}