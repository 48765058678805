@import "src/scss/includes";

.setup-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .statuses-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: $purple80;
    p{
      margin-bottom: 10px;
      font-weight: bold;
    }
    span{
      color: $blue100;
      font-weight: normal;
    }
    .edit-statuses {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: $purple80;
      cursor: pointer;
    }
    .edit-statuses-inactive {
      color: $gray50;
    }

  }

  .selected-statuses {
    display: flex;

    .selected-column {
      display: flex;
      flex-direction: column;
      width: 230px;
      margin-top: 16px;
      &.invalid {
        color: $gray60;
      }
      .selected-statuses-header {
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
      }
      ul {
        padding-left: 27px;
        .optional-status {
          font-style: italic;
          color: $gray70;
        }
      }
    }

  }
}
