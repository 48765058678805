@import "src/scss/includes";

$modal-boxes-widths: (
        "w1250": 1250px,
        "w1100": 1100px,
        "w950": 950px,
        "w800": 800px,
        "w650": 650px,
        "w500": 500px,
        "w350": 350px,
);

.eds-modal-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1120;
  overflow: auto;

  @each $modal-boxes-width, $i in $modal-boxes-widths {
    &.eds-modal-box-size-#{$modal-boxes-width} > .eds-modal-box-container {
      max-width: $i;
    }
  }

  .eds-modal-box-overlay {
    background-color: rgba($black, .2);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .eds-modal-box-container {
    position: relative;
    width: 80%;
    margin: 20px auto;
    background-color: $white;
    .eds-modal-box-header {
      padding: 15px 25px;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .eds-modal-box-body {
      padding: 35px 50px;
      background-color: $gray2;
    }
    .eds-modal-box-footer {
      display: flex;
      justify-content: flex-end;
      padding: 25px;
    }
  }
}
