@import "src/scss/includes";
.icon-with-dropdown-wrapper {
  position: relative;
  .dropdown-option-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35px;
    left: 20px;
    z-index: 2;
    background-color: $white;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    .toolbar-dropdown-option {
      padding: 8px;
      white-space: nowrap;
      cursor: pointer;
      &.selected {
        font-weight: 600;
      }
    }
  }
}