@import "src/scss/includes";

@mixin setupItemMapping {
  .setup-item {
    display: flex;
    align-items: center;
    > span, .eui-checkbox > span {
      min-width: 150px;
      font-size: 16px;
      white-space: nowrap;
      margin-bottom: 23px;
    }
    .mapping-input {
      width: 350px;
      margin-right: 16px;
    }
    &.setup-multiselect {
      margin-bottom: 23px;
      > span, .eui-checkbox > span  {
        margin: 0;
      }
    }
    &.checkbox-wrapper {
      flex-grow: 1;
      justify-content: space-between;
      align-items: start;
    }
  }
}

.rc-tooltip.rc-tooltip-placement-top {
  &.mapping-key-tooltip {
    height: 40px;
    padding: 20px 0 0 0;
    z-index: 1130;
    .rc-tooltip-arrow {
      display: none;
    }
  }
  &.mapping-dropdown-tooltip {
    z-index: 1130;
    .rc-tooltip-arrow {
      display: none;
    }
  }
}

.edit-mapping-modal {
  .eds-modal-box-body {
    display: flex;
    flex-direction: column;
    height: 74vh;
    .mapping-setup-header {
      display: flex;
      flex-wrap: wrap;
      .setup-parameters {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        max-height: 500px;
        @include setupItemMapping;
        &.hidden {
          overflow: hidden;
          max-height: 0;
          transition: min-height 0.25s linear;
        }
        .template-name {
          margin-bottom: 24px;
          min-width: 250px;
          max-width: 250px;
        }
      }
      .collapse-button-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        margin-bottom: 24px;
        border-bottom: 2px solid $gray15;
        .collapse-button {
          position: absolute;
          right: 50%;
          bottom: -14px;
        }
        .section-header {
          font-size: 16px;
          font-weight: bold;
          position: absolute;
          top: -24px;
        }

      }
    }
    .add-new-mapping-button {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
      .eui-btn {
        width: 210px;
        margin-bottom: 16px;
      }
    }
    .mapping-field-item-wrapper {
      display: flex;
      flex-direction: column;
      .mapping-field-item {
        width: fit-content;
        display: flex;
        margin: 8px 0 8px 1px;
        .eds-select, .eui-input{
          margin-right: 16px;
          min-width: 250px;
          max-width: 250px;
        }
        .delete-option-item {
          margin: 18px;
          cursor: pointer;
          min-width: 20px;
          height: 20px;
          background-image: url("../../../../../../../images/icons/delete-icon.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .always-sent-checkbox {
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 1430px) {
  .edit-mapping-modal {
    .eds-modal-box-body {
      .mapping-setup-header {
        .setup-parameters {
          max-height: 400px;
          overflow-y: auto;
        }
      }
    }
  }
}
