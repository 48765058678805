.table-item-group-comment {
  display: flex;
  flex-direction: column;
  padding: 40px 15px;
  position: sticky;
  left: 0;
  > div {
    &:first-child {
      flex: 3 3;
    }
    &:last-child {
      flex: 7 7;
    }
  }
}
