@import "src/scss/includes";
.add-ledger-content{
  margin: 6px 25px 18px 23px;
  background: $white;
  border: 1px solid $gray10;
  display: flex;
  flex: 1 0;
  height: calc(100vh - 211px);
  flex-direction: column;
  .al-content {
    display: flex;
    flex: 1 0;
    padding: 22px 21px 0 21px;
    overflow: auto;
  }
}
