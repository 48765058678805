.eui-travel-requests-worklist {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
  height: calc(100vh - 125px);
  .page-info-header {
    .general-header-wrapper {
      .eds-loader {
        margin: 0 auto;
      }
    }
  }
  .MuiDataGrid-root {
    min-height: 600px;
    padding: 0 40px 0 40px;
    border: none;
  }
}
