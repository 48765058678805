@import "src/scss/includes";

.eds-progress-notes-widget {
  font-size: 14px;
  .pnw-header {
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 150%;
      padding-right: 5px;
      margin: 0;
    }
    .eds-icon-btn {
      width: 24px;
      height: 24px;
    }
  }
  .pnw-list {
    color: $gray85;
    padding: 0 24px 0 15px;
    .eds-loader {
      margin: 40px auto;
    }
    .pnw-list-no-data {
      text-align: center;
      padding: 24px;
      color: rgba($gray85, 0.4);
      background-color: rgba($gray85, 0.04);
      margin: 0 8px;
      border-radius: 5px;
    }
    .pnw-list-item {
      padding: 8px 10px;
      border-radius: 3px;
      transition: all 0.2s ease 0s;
      cursor: pointer;
      .pnw-list-item-header {
        font-weight: 600;
        display: flex;
        padding-bottom: 5px;
        font-weight: 600;
      }
      .pnw-list-item-content {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 42px;
      }
      &:hover{
        background: $gray10;
        .pnw-list-item-header{
          color: $purple80;
        }
      }
    }
  }
}
