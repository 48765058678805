@import "src/scss/includes";
.patient-status-change{

  flex-direction: column;

  section{
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .info-section{
    margin-top: 0;
    padding: 0;
    flex-direction: column;
    box-sizing: border-box;
    .info-bar{
      justify-content: space-between;
      font-size: 1rem;
      padding: 14px 16px;
    }
    .status-bar{
      display: flex;
      align-items: center;
      border-top: 1px solid $aero40;
    }
  }

  .content-section{
    display: flex;
    position: relative;
    overflow-y: auto;
    margin-bottom: 20px;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0;
    margin-top: 0;
    .section-bar{
      display: flex;
      justify-content: space-between;
      .missing-status-btn{
        border: 1px solid $gray15;
      }
    }
    .filters{
      display: flex;
    }

    .status-history-table {
      max-height: 400px;
      .rt-tr{
        &.highlight{
          background-color: $purple20;
        }
        &.grey-out{
          color: $gray50;
        }
      }
      .rt-thead{
        .rt-th{
          padding-top: 8px;
        }
      }
      .rt-tbody{
        .reason-cell{
          .short-reason{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .full-reason{
            display: none;
            position: absolute;
            padding: 10px;
            border-color: $gray10!important;
            -webkit-box-shadow: 0 0 2px 1px rgba($black,0.15)!important;
            -moz-box-shadow: 0 0 2px 1px rgba($black,0.15)!important;
            box-shadow: 0 0 2px 1px rgba($black,0.15)!important;
            border-radius: 4px;
            z-index: 11;
            top: 40px;
            background-color: $white;
            max-width: 200px;
            white-space: normal;
          }
          &:hover{
            .full-reason{
              display: block;
            }
          }
        }
      }
    }
  }

  .floating-panel{
    width: 350px;
    position: fixed;
    right: 0;
    top: 54px;
    z-index: 1112;
    overflow-y: auto;
    background-color: $white;
    height: calc(100% - 54px);
    box-shadow: 0 12px 12px 7px rgba($black, 0.15);
    .floating-panel-container{
      position: relative;
      width: 100%;
      height: 100%;
      .close-floating-panel {
        position: absolute;
        top: 8px;
        right: 18px;
      }
    }
  }

}
