@import "src/scss/includes";

.field-item-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border: 1px solid $gray5;
  border-collapse: collapse;
  cursor: grab;
  &:hover, &.dragging  {
    background-color: $gray5;
    border: 1px dashed $blue100;
  }

  .column {
    display: flex;
    align-items: center;
    margin: 0 15px;
    &.big {
      display: flex;
      width: 150px;
      flex-grow: 3;
      align-items: center;
      .col-md-3 {
        flex: auto;
        max-width: 100%;
      }
      .eui-input-validation-message {
        display: none;
      }
      .radio-template-toggle-switch {
        width: 100%;
        justify-content: center;
      }
      .edit-conditional-configuration-icon {
        margin-left: 5px;
        font-size: 20px;
        &.hide_all_configuration {
          color: $orange100;
        }
      }
      .conditional-logic-alert {
        color: orange;
        font-size: 22px;
        margin-left: 20px;
      }
    }
    &.normal {
      width: 50px;
      flex-grow: 1;
      justify-content: center;
      .item-sequence {
        width: 55px;
      }
    }
    &.short {
      width: 25px;
      flex-grow: 1;
      justify-content: center;
      .delete-option-item {
        cursor: pointer;
        width: 20px;
        height: 20px;
        background-image: url("../../../../../../../../images/icons/delete-icon.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .edit-name {
      margin-top: -1px;
    }
  }
}
