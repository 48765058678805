@import "scss";
@import "common/buttons/eds-btn-group";
@import "src/components/pages/setup/Budget/BudgetDetails/modal/add-or-edit-budget-event-modal";
@import "src/scss/includes";
/* Custom Media Queries */

/* ipad */
@media screen and (max-width: 1024px) {
    .ipadnd8{     -webkit-box-flex: 0;
        -ms-flex: 0 0 52.666667%;
        flex: 0 0 52.666667%;
        max-width: 52.666667%;}
}

   /* laptop*/
   @media screen and (min-width: 1025px) and (max-width: 1366px) {
    @-moz-document url-prefix() {
    }
    .custonDate .dateicon3 { top: 2px; z-index: 0;   }
  }

  /* Large Desktop*/
  @media screen and (min-width: 1367px) and (max-width: 1600px) {
    @-moz-document url-prefix() {
    }
    .custonDate .dateicon3 { top : 2px; z-index: 0;}
  }

  @media screen and (min-width: 1601px) and (max-width: 1920px) {
    @-moz-document url-prefix() {
    }
    .custonDate .dateicon3 { top : 2px; z-index: 0;}
  }

/* End of Media Queries */
  html{
    position: relative;
    min-height: 100%!important;
  }

  .root{
    min-height: 100%;
    .page-outer-container{
      min-height: 100%;
      .page-inner-container{
        min-height: 100%;
        .page-content{
          min-height: 100%;
          padding-bottom: 35px;
        }
      }
    }
  }
  body{
    min-height: 100%;
    font-size: 0.9rem!important;
    background-color: $gray2;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  }
  section{    background-color: $white;margin:0 1rem;}
  h5{    font-size: 1rem; font-weight: 600;}
  h6:not(.MuiTypography-root),h3{    font-weight: normal;}
  h6.c-p strong,h6.c-p b{
      font-size: 0.9rem !important;
      font-weight: 500;
      white-space: nowrap;
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  a,a:hover{text-decoration: none;color:$blue100}
  select.form-control:not([size]):not([multiple]){      height: calc(2.0rem + 2px);  }
  .form-control:not(textarea){padding: .3rem .75rem;height: calc(2.0rem + 2px);}
  .input-group-text{padding: 0 .75rem}
  .bg-e{    background-color: $red100}
  .bg-p{    background-color: $purple80}
  .bg-light-p{    background-color: $purple50}
  .bg-light-pink{    background-color: $purple20}
  .border-light-p{   border-bottom: 3px $purple50 solid}
  .bg-ligh-blue{    background-color: $blue100;}
  .border-right-transparent{border-right:10px $gray2 solid}
  .border-p-gradient::after{    width: 1px;   height: 90%;    position: absolute;    right: 0;    top: 15%;    content: "";
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiP…Igd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g);    background-size: 100%;
      background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(20%, rgba($white, 0)), color-stop(40%, rgba($purple80, 0.8)), color-stop(60%, rgba($purple80, 0.8)), color-stop(80%, rgba($white, 0)));
      background-image: -moz-linear-gradient(bottom, rgba($white, 0), rgba($white, 0.8) 20%, rgba($purple80, 0.8) 60%, rgba($white, 0));
      background-image: -webkit-linear-gradient(bottom, rgba($white, 0), rgba($white, 0.8) 20%, rgba($purple80, 0.8) 60%, rgba($white, 0));
      background-image: linear-gradient(to top, rgba($white, 0), rgba($white, 0.8) 20%, rgba($purple80, 0.8) 60%, rgba($white, 0));
  }
  .c-p{    color: $purple75}
  .c-b{    color:$blue100;}
  .c-w{ color : $blue100}
  .border-white{    border-right:4px $white solid}
  .bg-light-grey1{    background-color: $gray5}
  .bg-light-grey{    background-color: $gray10}
  .btn{    border-radius: 0}
  .add-btn{        width: 25px;
    height: 25px;      border-radius: 50%;      border: 4px $blue100 solid;      background-color: $blue10;
      float: right;      margin-right: 5px;      color: $blue100;      cursor: pointer;}
  .add-btn i{    font-size: 15px;    font-weight: 700;    float: left;    margin-top: 1px;    margin-left: 1px;    }
  .edit-btn-group{    /* background-color: $blue100;   */  border-radius: 20px;     width:93px;     padding:3px;  padding-left: 60px; }
  .edit-btn-group > i:not(.arrow){    padding:6px;    font-size: 17px;    color: $blue100;    border-radius: 50%;  cursor:pointer;  background-color: $white ;margin:0 3px;border: 1px solid $gray15;}
  .edit-btn-group > i:not(.arrow):hover{   background-color: $purple50; border: 1px solid $purple75; color: $white;}
  .edit-btn-group i.active{    background-color: $white;    color: $blue100;}
  .border-bottom-dotted{    border-bottom: 2px $gray10 dotted}
  .border-bottom-p-dotted{border-bottom:1px $purple75 dotted}
  .table.border-0 tr td:first-child,.table.border-0 tr th:first-child{    border-left:none;    }
  .table.border-0 tr th{    border-top:none;    border-bottom: none}
  .table td, .table th{padding: .5rem .75rem}
  .table.border-0 tr td:last-child,.table.border-0 tr th:last-child{    border-right:none;    }
  .border-bottom-p{    border-bottom:1px $blue100 solid}
  .form-control{    border-radius: 0%;font-size: .9rem}
  .input-group.active{    border-top-width:2px!important;border-top-color: $purple50!important;border-top-style: solid!important;color: $purple50 }
  .input-group.active .input-group-text{    background-color: $purple20}
  .custom-file-label{    border:1px $blue100 solid;border-radius: 0;height: 34px;z-index:0;}
  .custom-file-label:after{    background-color:$blue100;    color: $white;border-radius: 0;height: 32px;padding: .275rem .75rem;z-index:0}
  .custom-file-input{z-index: 0; overflow: hidden;}
  .custom-file-input:lang(en)~.custom-file-label::after{    content:"Upload"}
  .custom-checkbox .custom-control-input:checked~.custom-control-label::before{background-color: $blue100}
  .d-inline.position-relative{z-index: 2}

  footer{font-size: 13px}
  /*Activity List Page*/
  .activity-table{ border:none;}
  .activity-table th{ border-top: 0px transparent solid;    border-bottom-color:$purple50!important;    border-bottom-width: 2px!important;    color: $purple80}
  .activity-table th:last-child{ border-right:none;}
  .activity-table tr{border-bottom: 1px $gray10 solid}
  .activity-table tr:last-child{border-bottom:none}
  .activity-table tr td,.activity-table tr th{border-right: 1px $gray10 solid}
  .activity-table tr td:last-child{border-right:none}
  .activity-table tr td .input-group{min-width: 300px}

  @media (min-width:1000px){
      h6:not(.c-p):not(.MuiTypography-root){font-size: .75rem}
  }

  .search-icon{height: 34px; background-color:$purple80}

  .active.arrow-down:after {
       content: "";
        position: absolute;
         bottom: -8px;
          border-width: 10px 10px 0;
      border-style: solid;
      border-color: $purple75 transparent;
         display: block;
          width: 0;  }
  .table-border{      border: 1px solid $gray10!important  }
  table thead tr th{ font-weight : 500;}

.display-inline{    display: inline-block;}
/* .select-width{    min-width: 148px;} */
/*   min-width: 148px;     */
.rdt .form-control{  padding: 6px 0px;    padding-left: 12px;}


/*------------React Table Custom End ---------*/
.cursor-pointer{    cursor : pointer}

/* .element-setup .dateicon3{    top : 35px;} */
.width100{    width: 100%}
.width50{    width: 50%}
/*----------------------3 Radio Button------------------- */
input[type="radio"] {    display: none;}
i {   z-index: 99;  font-size: 18px;}

@keyframes slidein {
    from {
        transform: translate(25px, 0);
    }
    to {
        transform: translate(0px,0px);
    }
}
@keyframes slideno {
    from {
      transform: translate(-25px,0);
    }
    to {
        transform: translate( 0px, 0px);
    }
}
/*---------Confirm Modal ------------*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {     -webkit-appearance: none;    -moz-appearance: none;    appearance: none;    margin: 0; }
/*---------Pagination CSS ------------*/
.jtk-surface-nopan { overflow: auto !important;}

.modal-footer{    border: none}
.footpadb{   margin-bottom: 15px !important;}
.reqfeild {  border-left: 3px solid $red100;}

.bluetxt{color: $blue100!important;    font-weight: 400;}

i.glyphicon-ok{z-index:0; }
.accordion .accordion__title{   padding : 10px;margin-bottom: 4px;    border-bottom: 1px solid $purple20; }
.accordion h3{font-size: 1rem;    font-weight: 600;}
.formseqicon{  font-size: 19px;    color: $blue100;    padding-top: 6px;    padding-right: 5px;}

/* do not group these rules */
*::-webkit-input-placeholder {    color: $gray15!important;}
*:-moz-placeholder {    /* FF 4-18 */    color: $gray15!important;}
*::-moz-placeholder {    /* FF 19+ */    color: $gray15!important;}
*:-ms-input-placeholder {    /* IE 10+ */    color: $gray15!important;}

/* Calender css */
.subtitle{ font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    font-size: 0.9rem;    color: $gray70;    margin-bottom: 10px;    background: $gray5;
    padding: 8px 8px;    border-bottom: 1px dashed $gray30;}
.editicn:hover{ color: $purple80;}
.editicn{ color: $blue100;    font-weight: normal;    font-size: 19px; cursor: pointer;}
.dropdown-item.active, .dropdown-item:active {    color: $white;    text-decoration: none;   background-color: $purple100;}
.alerttxt{ float: left; padding-left: 10px;}
.alerticn { float: left; }
.glyphicon-edit,.glyphicon-remove,.glyphicon-remove-circle{cursor: pointer;}
.element-setup-modals .fvalMsg{    font-size: 0.6rem; color: $blue100;}
.dialog .ReactTable .-pagination { width: 315px;}
/* SpriteImage - Icons CSS */
/*
    .add-txt, .remove-txt, .update-txt {    background-image: url('./images/elligo-sprite.png');    background-repeat: no-repeat;  }
    .add-txt {    height: 33px;    background-position: 19px 18px;  }
    .remove-txt {    height: 33px;    background-position: 19px 78px;  }
    .update-txt {    height: 33px;    background-position: 19px 138px;  }
*/
.unexpected-enc .edit-btn-group{
    width: auto;
}
.overflow-ellipsis-multiple-line{
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}
.overflow-ellipsis{
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}
@media print{
    body{ -webkit-print-color-adjust:exact; padding:40px!important;}
    .overflow-ellipsis{
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }
	.wrapper{
		background:$white; font-size: 9px!important;
	}

	.header div.download{
		display:none!important;
	}
	.alert-danger,
	.close{
		display:none!important;
	}
	/*UNPRINTABLE----------------*/
	svg g[class$="-button"]{
		display:none!important;
	}
	/*BOOTSTRAP-------------*/
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
	.col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
	.col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
	.col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
	.col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
	.col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
	.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
	.col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
	.col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
	.col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
	.col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
	.col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
		position: relative!important;
		min-height: 1px!important;
		padding-right: 15px!important;
		padding-left: 15px!important;
		float: left!important;
	}
	.col-md-1 {		width: 8.333333333333332%!important;	}
	.col-md-2 {		width: 16.666666666666664%!important;	}
	.col-md-3 {		width: 25%!important;	}
	.col-md-4 {		width: 33.33333333333333%!important;	}
	.col-md-5 {		width: 41.66666666666667%!important;	}
	.col-md-6{		width: 50%!important;	}
	.col-md-7 {    	width: 58.3333%!important;	}
	.col-md-8 {		width: 66.66666666666666%!important;	}
	.col-md-9 {		width: 75%!important;	}
	.col-md-10 {		width: 83.33333333333334%!important;	}
	.col-md-11 {		width: 91.66666666666666%!important;	}
    .col-md-12 {		width: 100%!important;	}

}
.ft-500{font-weight: 500;}
.pt-sub{font-size: 0.9rem;}
.form-control:disabled{background-color: $gray5 !important;}
.itemSeq .itemSeqWidth{ width : 55px}
.itemMinWidth{min-width: 70px}
.ansHeaderMarginLR{
    margin-left: -20px;
    margin-right: 10px;
}
.itemSeqMargin{margin-right:-2px;}
.element_preview_modal .predefined-table.activity-table th:last-child{
    border-right: 1px $gray10 solid !important;
}
.form_required_class_after::after{
    content:"*";
    font-weight:bold;
    color:$red100;
}
.pl31{padding-left:31px;}
.editAns{font-size: 15px !important;    top: 5px !important;}
.hrdivider{border-top: 1px dashed $gray15;clear: both;}
.drag-clear{float: right;padding-top:5px;color:$blue100;font-size: 12px;font-weight: 600; cursor: pointer !important;}
.dragOptions{cursor: move;}
.emptyOptions{text-align: center;color:$gray50;}
.fw500{font-weight: 500;}
.custAdd{font-size: 21px !important;}
.display_inline{
    display: inline;
}

.activation-date-calendar .rdtPicker {
    top: -240px;
    height: 239px;
    width: 244px;
}
.cursor-pointer-date input {
    cursor: pointer;
}

.float-left{
    float: left !important;
}

.patient-preferred-name{
  font-weight: 200;
  color: $gray70;
}

.voicemail-not-allowed-icon {
  color: $red70;
  font-size: 21px;
  font-weight: bold;
  width: 25px;
  vertical-align: text-top;
}

.file-status-icon {
  margin-right: 2px;
  font-size: 20px;
  line-height: 20px;
  z-index: 1;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($gray85, 0.25);
  border: 2px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($gray85, 0.5);
}

::-webkit-scrollbar-track {
  background-color: rgba($gray85, 0.05);
}



.more-than-five-fields-wrapper {
  display: grid;
  grid-template-columns: repeat(5, minmax(150px, 400px));
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  width: 100%;
  .eds-select, .eui-date-picker {
    min-width: unset !important;
    max-width: 400px !important;
  }

  .apply-btn {
    width: fit-content;
  }

  .r-ss-wrap {
    min-width: unset !important;
    .r-ss-trigger {
      width: inherit !important;
      min-width: unset !important;
    }
  }
}

.more-than-ten-fields{
  grid-template-columns: repeat(6, minmax(150px, 400px));
}

.item-group-field-row{
  display: flex;
  padding: 5px 0;
  width: 100%;
  align-items: center;
  .eds-toggle-switch{
    width: 100%;
  }
  .r-ss-wrap .r-ss-trigger{
    min-width: auto!important;
  }

  .item-group-field-label{
    width: 33.33333%;
    min-width: 300px;
    padding-right: 5px;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: flex;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }
  .item-group-field-input{
    width: 25%;
    min-width: 280px;
  }
}

.eds-encounter-details-page{
  display: block;
  overflow-y: auto;
  .encounter-details{
    .col-3 img{    height: 95px;    max-width: 100%;    min-height: 50px;}
  }
}
