@import "src/scss/includes";


.worklist-new{
  background-color: white;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: calc(100% - 126px);
  padding-bottom: 150px;

  .worklist-header {
    .count {
      right: 35px !important;
    }
    .worklist-widget-container {
      display: flex;
      flex-direction: row;
    }
    .eds-select-validation-message:empty {
      display: none;
    }

    .chips-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .eui-chip {
        background-color: #e7e7e7;
        margin-bottom: 0;
        border-radius: 27px;
        padding: 2px 10px 2px 10px;

        .values {
          max-width: 120px;
        }
      }

    }
    .eds-select.eds-select-multiselect {
      min-width: 10px !important;
      max-width: 212px !important;
    }
  }

  .worklist-divider {
    margin: 40px 24px 45px;
    width: auto;
    border-bottom: 1px solid $purple80;
  }
}
