@import "src/scss/includes";

.encounter-modification-dropdown-menu {
  box-shadow: 0 0 20px rgba(28, 81, 88, 0.35);

  .encounter-modification-dropdown-option {
    font-size: 14px;
    line-height: 150%;
  }

  .hint {
    font-size: 12px;
    color: $gray50;
  }
}