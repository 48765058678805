@import "src/scss/includes";
.adjusting-history-ledger-summary {
  display: flex;
  padding: 25px 0;

  .summary-item {
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .summary-item-title {
      font-size: 13px;
      color: $gray50;
      margin-bottom: 15px;
      text-align: end;
    }
    .eds-amount, .amount-view {
      text-align: end;
    }

    &.summary-header {
      .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .budget-details {
        display: flex;

        span:not(:first-child) {
          padding-left: 20px;
        }
      }
    }
  }
}
