@import "src/scss/includes";
.eds-modal-box.cancel-encounter-modal {
  font-style: normal;

  .eds-modal-box-container {
    margin-top: 15vh;
    margin-bottom: auto;

    .eds-modal-box-header {
      font-size: 20px;
      font-weight: 500;
      color: $black;
    }

    .eds-modal-box-body {
      max-height: 286px;
      overflow-y: auto;
      line-height: 150%;
      padding-top: 10px;
      padding-bottom: 10px;
      color: $gray85;
      font-size: 14px;

      h5 {
        font-weight: bold;
      }

      p {
        font-weight: normal;
      }
    }

    .cancel-comment-div {
      padding: 18px 25px 0 25px;
    }

    .eds-modal-box-footer {
      .eds-btn-group {
        button + button {
          margin-left: .5rem;
          width: 11.125rem;
        }
      }
    }
  }
}
