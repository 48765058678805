.erv-review-sidebar-header {
  .erv-review-sidebar-header-headline {
    padding: 20px 24px 14px 24px;
    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      margin: 0;
      a {
        color: inherit;
      }
    }
  }
  .erv-review-sidebar-header-search {
    padding: 0 24px 18px 24px;
    position: relative;
    input {
      background: #FFFFFF;
      border: 1px solid #E7E7E7;
      border-radius: 20px;
      height: 30px;
      width: 100%;
      padding: 0 30px;
    }
    .eui-icon {
      position: absolute;
      left: 35px;
      top: 15px;
      margin-top: -10px;
      color: #DBDBDB;
    }
  }
}
