@import "src/scss/includes";

.eds-task-container {
  height: 100%;
  background-color: white;
  width: 350px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 12px 12px 7px rgba($black, 0.15);
}
