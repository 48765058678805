@import "src/scss/includes";

.preview-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px $gray10 dotted;
  .preview-header {
    color: $purple75;
    padding: 0 0 0.5rem 0;
    font-size: 1rem;
    font-weight: 600;
  }
  .preview-button-wrapper {
    display: flex;
    margin: 0.25rem 0 0.25rem 0;
    padding: 0 15px 0 15px;
    color: $blue100;
    cursor: pointer;
    span {
      margin-bottom: 0.5rem;
    }
    .add-btn-preview {
      width: 25px;
      height: 25px;
      line-height: 1;
      font-size: 17px;
      font-weight: 600;
      border-radius: 50%;
      border: 4px $blue100 solid;
      background-color: $blue10;
      margin-right: 5px;
      color: $blue100;
    }
  }
}