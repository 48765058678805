@import "src/scss/includes";

.eui-nav-bar {
  .eui-link-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 150%;
      color: $white;
      opacity: 0.7;
    }
    .elligo-logo-img{
      min-width: 102px;
    }
  }
  border-top: 7px solid $elligoRed;
  position: sticky!important;
  top: 0;
  z-index: 1113;
  display: flex;
  background-color: var(--environment-main-color, $purple80);
  align-items: center;
  .invisible-ref-container{
    position: fixed;
    top: -1000px;
    .eui-nav-item, .eui-nav-link{
      color: transparent!important;
      &.menu{
        &:after{
          color: transparent;
        }
      }
    }
  }
  .nav-items{
     display: flex;
     flex-direction: row;
     padding-left: 0;
     list-style: none;
     margin: 0;
   }
  .nav-items-container{
    display: flex;
    flex-grow: 1;
    height: 47px;

    .add-task-button {
      padding: 2px;
      width: 40px;
      height: 40px;
      background-image: url("../../../../../images/icons/Add-passive.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      &:active, &:hover {
        background-image: url("../../../../../images/icons/Add-active.svg");
      }
    }

  }

  .rc-menu-container .rc-menu--dir-right {
    margin-left: 2px!important;
  }

  .rc-menu__submenu{
    > div{
      &.rc-menu__item{
        padding: 9px 34px 9px 15px!important;
        &:after{
          left: auto!important;
          right: 8px!important;
        }
      }
    }
  }


  .eui-nav-item {
    color: white;
    padding: 0;
    margin: 0 2px;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    &.menu{
      padding: 13px 34px 10px 10px;
      &:hover{
        background: var(--environment-extra-color-1, $purple75) !important;
      }
      &:after {
        content: "";
        position: absolute;
        top: 23px;
        right: 17px;
        color: $white;
        opacity: 0.7;
        border-top: 4px solid;
        border-bottom: none;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
    &.active{
      background: var(--environment-extra-color-1, $purple75);
    }
    &:hover{
      background: var(--environment-extra-color-1, $purple75) !important;
    }
    .eui-nav-link{
      color: $white;
      font-size: 14px;
      width: 100%;
      padding: 13px 10px!important;
      display: block;
      &.active{
        background: var(--environment-extra-color-1, $purple75);
      }

    }
  }

  .rc-menu__item--hover{
    background: $gray10!important;
  }

  .rc-menu__item{
    padding: 0!important;
    &.active{
      background: $gray10;
    }
    .eui-nav-link{
      width: 100%;
      padding: 9px 34px 9px 15px!important;
      color: $black;
      background: transparent;
      &.active{
        background: $gray10;
      }
    }
  }

  .rc-menu--dir-bottom {
    margin-top: 4px!important;
  }
}
