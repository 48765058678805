@import "src/scss/includes";

.addition-mapping-info-modal {
  .eds-modal-box-body {
    .log-info {
      display: flex;
      flex-direction: column;
      .log-row {
        display: flex;
        .log-row-header {
          color: $purple80;
          padding: 8px;
          min-width: 200px;
          font-weight: bold;

        }
        .white-spase {
          display: flex;
          flex-grow: 1;
        }
        .log-row-value {
          display: flex;
          width: 500px;
          padding: 8px;
          div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .log-data-collapse-container {
      border: none;
      background-color: transparent;
      .rc-collapse-item {
        .rc-collapse-header {
          justify-content: start;
          flex-direction: row-reverse;
          padding: 4.8px 8px 4.8px 8px;
          border: none;
          .collapse-button {
            margin-left: 30px;
          }
          .collapse-title {
            color: $purple80;
            font-weight: bold;
          }
        }
        .rc-collapse-content {
          pre {
            height: 45vh;
            margin: 0;
          }
        }
      }
    }
  }
}
