.eds-modal-box.copy-item-group-template-modal {

  .eds-modal-box-container{
    min-height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
  }

  .eds-modal-box-body {
    max-height: 700px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
