@import "src/scss/includes";

.siteDetails {
  textarea {
    resize: none
  }

  .site-info-table {
    i {
      color:$gray50;
      cursor:pointer
    }

    .rt-tbody .rt-tr:hover {
      background-color:$gray7!important;
      cursor: default;
    }
  }

}