@import "src/scss/includes";

.eui-file-upload-drag-zone-outer {
  min-height: 80px;
  padding: 12px;
  background-color: $gray5;
  border-radius: 4px;
  color: $gray30;
  .eui-file-upload-drag-zone-inner {
    display: flex;
    justify-content: left;
    align-items: center;
    border: 1px dashed $gray30;
    box-sizing: border-box;
    border-radius: 4px;
    height: 56px;
    color: $gray70;
    padding-left: 20px;
    padding-right: 5px;
    cursor: pointer;

    i {
      margin-right: 5px;
    }
    + div {
      padding-top: 5px;
      font-size: 0.75rem;
      color: #d32f2f;
    }
  }
  &.eui-file-upload-drag-zone-outer-error {
    .eui-file-upload-drag-zone-inner {
      border-color: #d32f2f;
      color: #d32f2f;
    }
  }
}
