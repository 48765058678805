@import "src/scss/includes";

$disabled-color: $gray15;

%signing-icon {
  font-size: 20px;
  font-family: $ffMaterialIcons;
  font-weight: 600;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}

.erv-sidebar-item {
  height: 52px;
  padding-left: 26px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 10px;
  .erv-sidebar-item-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border {
      width: 14px;
      height: 14px;
      border-width: 2px;
    }
  }
  .erv-sidebar-item-name-and-status {
    display: flex;
    flex: 1;
    padding-right: 9px;
    flex-direction: column;
    .erv-sidebar-item-name {
      position: relative;
      padding-left: 17px;
      font-size: 14px;
      max-width: 151px;
      line-height: 18px;
      word-break: break-word;
      min-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .erv-sidebar-item-status {
      padding-left: 17px;
      font-size: 12px;
    }
  }
  .erv-sidebar-item-mark {

    .erv-sidebar-item-mark-not-performed {
      background: #B7B7B7;
      border-radius: 2px;
      color: $white;
      font-size: 12px;
      padding: 0 2px;
      font-weight: bold;
    }

    .erv-sidebar-item-mark-highlighted {
      @extend %signing-icon;
      color: #2465C6;
      font-weight: 400;
    }

    .erv-sidebar-item-mark-highlights-confirmed {
      @extend %signing-icon;
      color: #2465C6;
      font-weight: 400;
    }
  }
  //&.erv-sidebar-item-not-required {
  //  color: #B7B7B7;
  //  .erv-sidebar-item-name-and-status > .erv-sidebar-item-status {
  //    color: #B7B7B7;
  //  }
  //}
  &.erv-sidebar-item-pending, &.erv-sidebar-item-signed {
    .erv-sidebar-item-name-and-status > .erv-sidebar-item-status {
      padding-left: 67px;
      position: relative;
      color: #6E6E6E;
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 17px;
        top: 50%;
        margin-top: -5px;
        width: 40px;
        height: 10px;
      }
    }
  }
  &.erv-sidebar-item-pending {
    .erv-sidebar-item-name-and-status > .erv-sidebar-item-status {
      &:before {
        background-color: #7D3559;
      }
    }
  }
  &.erv-sidebar-item-signed {
    .erv-sidebar-item-name-and-status > .erv-sidebar-item-status {
      &:before {
        background-color: #27AE60;
      }
    }
  }
  &.erv-sidebar-item-disabled {
    color: $disabled-color;
    cursor: auto;
    pointer-events: none;
  }
  &.erv-sidebar-item-active, &:hover {
    background: #FFFFFF;
  }
}
