@import "src/scss/includes";

.etc-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px;
  overflow-y: auto;
  .eds-loader {
    align-self: center;
    top: 50%;
    margin-top: -25px;
  }
  .eui-divider-horizontal{
    margin: 5px 0;
  }
  .etc-body-details {
    padding: 10px 0;
    .etc-body-details-block {
      padding-top: 10px;
      .etc-body-details-block-label {
        color: #575757;
        font-size: 13px;
        line-height: 15px;
        display: flex;
        justify-content: space-between;
        > button{
          font-size: 13px;
          padding: 0;
          color: #2465C6;
          border: none;
          background-color: inherit;
          margin-top: -4px;
        }
      }
      .etc-body-details-block-value {
        font-size: 15px;
        line-height: 25px;
        .etc-body-details-comment{
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .eui-icon{
            font-size: 20px;
            color: #575757;
            padding-right: 10px;
          }
          p{
            margin: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            span{
              margin-left: 3px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .etc-body-details-assignees{
        .etc-body-details-assignees-list-item{
          display: flex;
          justify-content: space-between;
          .etc-body-details-assignee-info{
            margin-bottom: 7px;
            display: flex;
            align-items: center;
            .eui-icon{
              padding-right: 10px;
            }
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                margin: 0;
              span{
                display: block;
                font-style: italic;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }
          .eui-icon{
            color: #575757;
            font-size: 20px;
            cursor: pointer;
          }
        }
    }
  }
}
