@import "src/scss/includes";

.outreach-attempt-tracking-modal {
  .callback-requested-toggle {
    flex-direction: row-reverse;
    margin-right: 10px;
    .eui-toggle-label {
      font-size: 12px;
      padding-left: 8px;
      line-height: 15px;
      width: 80px;
    }
  }
}
