@import "src/scss/includes";

.lt-deactivated {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .lt-deactivated-additional-info {
    margin-top: 15px;
    padding: 8px 15px 13px 11px;
    background: $yellow10;
    border: 1px solid $gray10;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
