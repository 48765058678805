@import "src/scss/includes";

.protocol-setup-comment {
  background-color: $gray5;
  .comment-title {
    display: block;
    font-weight: bold;
    font-size: 17px;
    margin-top: -10px;
    margin-left: 0;
  }
  .comment-textarea {
    margin-left: 0;
    margin-top: 20px;
  }
  p {
    margin: 0;
  }
}
