.not-performed-log-check-reason-modal {
  .eds-modal-box-container {
    max-width: 650px;

    .eds-modal-box-footer {
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
}
