@import "src/scss/includes";

.eds-patient-info-main-section {

.eds-patient-info-main-section-patient-contact-status {
    font-weight: bold;
  }

  .never-call-again {
    text-decoration: line-through;
    color: $gray60;
  }
  .rc-collapse-header-title{
    padding-right: 20px;
  }
  .rc-collapse-header-content {
    flex-grow: 1;
    > div {
      margin-right: 20px;
    }
  }
  .rc-collapse-content{
    border-radius: 0;
  }
  .rc-collapse-header{
    border-bottom: none!important;
    padding-right: 55px!important;
  }
  .rc-collapse-content-active{
    border-top: 1px solid $gray10;
    .rc-collapse-content-box{
      section{
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
  .rc-collapse-content-box {
    > .eds-loader {
      margin: 25px auto;
    }
    section {
      margin: 0;
      display: flex;
      border-bottom: 1px solid $gray10;
      header {
        padding: 15px 20px 10px 20px;
        display: flex;
        align-items: center;
        h3 {
          margin: 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: $gray85;
          padding-right: 5px;
        }
      }
    }

    .ims-info-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow-x: auto;
      .ims-info {
        display: flex;
        flex-direction: column;

        .ims-content {
          display: flex;
          padding: 0 0 20px 20px;
          line-height: 17px;
          min-width: 300px;
          ul {
            padding-left: 0;
            list-style-type: none;
            li{
              line-height: 23px;
              color: $gray85;
            }
          }
          > div {
            &.ims-content-table {
              margin: 0 50px 0 0;
              >ul{
                margin: 0;
              }
              > div {
                .show-alternate-phone{
                  position: absolute;
                  top: -5px;
                  left: 5px;
                }
                > div {
                  color: $gray30;
                  white-space: nowrap;
                  padding: 8px 0 8px 0;

                  > span {
                    color: $gray85;
                    line-height: 17px;
                  }
                  > div {
                    color: $gray85;
                    line-height: 17px;
                    width: 121px;
                  }
                  .emergency-show-button {
                    padding: 0 17px;
                    line-height: 17px;
                    color: $purple75;
                  }
                  .eds-toggle {
                    display: block;
                  }

                }
                &:last-child {
                  div {
                    white-space: normal;
                    max-width: 270px;
                  }
                }
              }
              .nowrap{
                display: inline-block;
                float: left;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 100px) {
  .eds-patient-info-main-section .ims-content{
    flex-direction: column;
  }
}
@media (min-width: 1280px) {
  .eds-patient-info-main-section .ims-content{
    flex-direction: row;
  }
}