@import "src/scss/includes";

.etc-header {
  background-color: #F5F5F5;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray15;
  line-height: 18.75px;
  height: 70px;
  position: relative;
  .menu-button{
    color: #49454F;
    width: 100px;
    height: 32px;
    border-radius: 8px;
    &:hover{
      color: #49454F;
    }
  }
  .rc-menu-container{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }
}
