@import "src/scss/includes";

.field-wrapper {
  &.scrollable {
    overflow: auto;
  }
  .setting-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .view-options {
      display: flex;
      align-items: center;
      span {
        font-weight: 600;
      }
      .add-custom-item {
        margin-left: 16px;
      }
    }
  }
  .labels-row {
    display: flex;
    width: 100%;
    border: 1px dashed $gray15;
    padding: 2px 0 ;
    color: $purple80;
    .column {
      display: flex;
      margin: 0 15px;
      align-items: center;
      &.big {
        width: 150px;
        flex-grow: 3;
      }
      &.normal {
        width: 50px;
        flex-grow: 1;
        justify-content: center;
        text-align: center;
      }
      &.short {
        width: 25px;
        flex-grow: 1;
        justify-content: center;
      }
    }
  }
}
