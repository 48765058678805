@import "src/scss/includes";

%skeletonCover {
  background: #ccc;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}

%center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%encounterTemplate {
  @extend %center;
  > div {
    width: 25px;
    height: 25px;
    @extend %skeletonCover;
  }
}

%itemGroupTemplate {
  @extend %center;
  > div {
    width: 280px;
    height: 25px;
    @extend %skeletonCover;
  }
}

%epochTemplate-extended {
  @extend %center;
  > div {
    width: 240px;
    height: 25px;
    @extend %skeletonCover;
  }
}

%epochTemplate-regular {
  @extend %center;
  > div {
    width: 150px;
    height: 25px;
    @extend %skeletonCover;
  }
}

.item-groups-encounter-table-skeleton {
  display: grid;
  grid-template-columns: 300px repeat(14, 90px);
  grid-template-rows: repeat(2, 38px) repeat(13, 45px);

  .item-groups-encounter-table-skeleton-legend {
    grid-area: 1 / 1 / 3 / 2;
    @extend %center;

    > div {
      width: 280px;
      height: 61.5px;
      @extend %skeletonCover;
    }
  }

  .item-groups-encounter-table-skeleton-epoch-name {
    grid-area: 1 / 2 / 2 / 5;
    @extend %epochTemplate-extended;
  }
  .item-groups-encounter-table-skeleton-encounter-1 {
    grid-area: 2 / 2 / 3 / 3;
    @extend %encounterTemplate;
  }
  .item-groups-encounter-table-skeleton-encounter-2 {
    grid-area: 2 / 3 / 3 / 4;
    @extend %encounterTemplate;
  }
  .item-groups-encounter-table-skeleton-encounter-3 {
    grid-area: 2 / 4 / 3 / 5;
    @extend %encounterTemplate;
  }

  .item-groups-encounter-table-skeleton-epoch-name-middle {
    grid-area: 1 / 5 / 2 / 7;
    @extend %epochTemplate-regular;
  }
  .item-groups-encounter-table-skeleton-encounter-5 {
    grid-area: 2 / 5 / 3 / 6;
    @extend %encounterTemplate;
  }
  .item-groups-encounter-table-skeleton-encounter-6 {
    grid-area: 2 / 6 / 3 / 7;
    @extend %encounterTemplate;
  }

  .item-groups-encounter-table-skeleton-epoch-name-large {
    grid-area: 1 / 7 / 2 / 10;
    @extend %epochTemplate-extended;
  }
  .item-groups-encounter-table-skeleton-encounter-7 {
    grid-area: 2 / 7 / 3 / 8;
    @extend %encounterTemplate;
  }
  .item-groups-encounter-table-skeleton-encounter-8 {
    grid-area: 2 / 8 / 3 / 9;
    @extend %encounterTemplate;
  }
  .item-groups-encounter-table-skeleton-encounter-9 {
    grid-area: 2 / 9 / 3 / 10;
    @extend %encounterTemplate;
  }

  .item-groups-encounter-table-skeleton-epoch-name-1 {
    grid-area: 1 / 10 / 2 / 12;
    @extend %epochTemplate-regular;
  }
  .item-groups-encounter-table-skeleton-encounter-1-1 {
    grid-area: 2 / 10 / 3 / 11;
    @extend %encounterTemplate;
  }
  .item-groups-encounter-table-skeleton-encounter-1-2 {
    grid-area: 2 / 11 / 3 / 12;
    @extend %encounterTemplate;
  }

  .item-groups-encounter-table-skeleton-epoch-name-2 {
    grid-area: 1 / 12 / 2 / 14;
    @extend %epochTemplate-regular;
  }
  .item-groups-encounter-table-skeleton-encounter-2-1 {
    grid-area: 2 / 12 / 3 / 13;
    @extend %encounterTemplate;
  }
  .item-groups-encounter-table-skeleton-encounter-2-2 {
    grid-area: 2 / 13 / 3 / 14;
    @extend %encounterTemplate;
  }

  .item-groups-encounter-table-skeleton-epoch-name-3 {
    grid-area: 1 / 14 / 2 / 16;
    @extend %epochTemplate-regular;
  }
  .item-groups-encounter-table-skeleton-encounter-3-1 {
    grid-area: 2 / 14 / 3 / 15;
    @extend %encounterTemplate;
  }
  .item-groups-encounter-table-skeleton-encounter-3-2 {
    grid-area: 2 / 15 / 3 / 16;
    @extend %encounterTemplate;
  }

  // Item Group styling
  @for $i from 3 through 15 {
    .item-groups-encounter-table-skeleton-item-group-#{$i} {
      grid-area: #{$i} / 1 / #{$i+1} / 2;
      @extend %itemGroupTemplate;
    }
  }

  .item-groups-encounter-table-skeleton-cell {
    @extend %center;

    > div {
      width: 60px;
      height: 25px;
      @extend %skeletonCover;
    }
  }
}
