@import "src/scss/includes";

.icf-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0;
  margin-top: 17px;
  margin-bottom: 17px;
  max-height: 60px;

  .completed-icf {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border: 1px solid $gray10;
    padding: 24px;
    margin-left: 15px;
    font-weight: 500;
    max-height: inherit;

    .completed-icf-link {
      color: $darkBlue100;
      text-decoration: underline;
    }

    .disabled-icf-link {
      color: $gray15;
      text-decoration: none;
    }
  }

  .new-icf-container {
    background-color: $yellow10;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $gray10;
    flex: 1;
    max-height: inherit;
    width: 85%;

    .new-message-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icf-divider {
        height: 33px;
        width: 0;
        border-left: 4px solid #C62424;
        border-radius: 20%;
      }

      h2 {
        padding-right: 30px;
        font-weight: 600;
        font-size: 20px;
        margin-left: 10px;
        letter-spacing: 0.02em;
        color: #C62424;
        margin-bottom: 0;
      }
    }
  }
}
