@import "src/scss/includes";

.encounter-segment-card-header {
  .est-progress {
    width: 110px;
    min-width: 100px;
    padding-left: 5px;
    padding-right: 5px;
    color: $gray50;
    font-size: 14px;
    display: flex;
    align-items: center;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .est-progressbar {
      width: 40px;
      height: 10px;
      background-color: $gray5;
      position: relative;
      margin-right: 8px;

      i {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: auto;
        background-color: var(--encounter-status, $purple80);
      }
    }
  }

  .est-finance {
    color: $green80;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    min-width: 15px;

    display: flex;
    align-items: center;

    padding-right: 40px;
    box-sizing: content-box;
  }

  .est-visit-date {
    width: 100px;
    font-size: 14px;
    color: $gray50;

    display: flex;
    align-items: center;
  }

  .est-comments {
    font-size: 14px;
    color: $gray85;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .side-group {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
  }

  .encounter-dates-tooltip {
    &.eds-rc-tooltip {
      font-size: 14px;
      &.rc-tooltip-placement-top {
        .rc-tooltip-arrow {
          border-top-color: $white;
          left: 25%;
        }
      }
      .rc-tooltip-arrow {
        display: block;
      }

      .overlay-container {
        width: 160px;
        .content-title {
          font-weight: bold;
        }
        .dates-layout {
          display: flex;
          flex-direction: column;

          .date-item {
            display: flex;
            .title {
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: 80px;
            }
          }
        }
      }
    }
  }

  @at-root .encounter-segment-canceled & {
    .est-name .encounter-name, .side-group .est-visit-date {
      text-decoration: line-through;
    }
  }
}

