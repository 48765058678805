@import "src/scss/includes";

.eds-item-group-templates {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  height: calc(100vh - 141px);

  .igt-header {
    background-color: $gray5;
    padding: 7px 25px 16px;
    h3 {
      font-weight: 600;
      font-size: 24px;
    }

    .igt-selects {
      border: none;

      > :nth-child(1n) {
        margin-right: 10px;
      }
    }
  }

  .igt-content {
    padding: 0 15px 25px 35px;
    flex-direction: row-reverse;
    flex: 1 0;
    display: flex;
    overflow: auto;
    background-color: $white;

    .igt-table.eui-table.ReactTable {
      margin-left: -10px;
      box-sizing: border-box;
      border: 1px solid $gray15 !important;
      overflow: auto;
      flex: 1 1;
      margin-top: 0;

      .rt-table {
        flex: 1 1;
        .rt-tbody {
          overflow-y: auto;
          .rt-tr-group {
            min-height: 54px;
            max-height: 78px;
            &:last-child{
              border-bottom: solid 1px rgba(0,0,0,0.05);
              margin-bottom: 10px;
            }
            .rt-td {
              height: inherit;
            }
          }
          .rt-tr {
              .igt-button {
                display: none;
              }
            &:hover {
                .igt-button {
                  display: flex;
                }
            }
          }
        }

      }
    }
  }
}
