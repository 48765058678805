.missed-budget-filters {
  margin-bottom: 22px;
  display: flex;
  align-items: flex-start;

  .eds-ssu-select {
    width: 256px;
  }

  .eds-ssu-select {
    padding-right: 9px;
  }
}
