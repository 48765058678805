@import "src/scss/includes";

.eds-editor-date-select{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  >div{
    margin-right: 15px;
  }
  .eui-date-picker{
    max-width: 160px;
  }
  .eds-select {
    max-width: 208px;
  }
}
