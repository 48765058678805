@import "src/scss/includes";

.data-mapping-page {
  padding: 0;
  .data-mapping-table {
    .rt-td {
      display: flex;
      align-items: center;
    }
  }

}
