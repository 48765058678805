.eui-table.ReactTable {
  width: 100%;
  margin-top: 20px;
  border: none!important;
  overflow-y: auto;

  .rt-tr{
    position: relative;
  }

  .rt-td{
    height: 54px;
    align-items: center;
    display: flex;
    border: none!important;
    &:first-child{
      padding-left: 40px;
    }
  }

  .rt-thead{
    border-color: $gray10!important;
    -webkit-box-shadow: 0 0 2px 1px rgba($black,0.15)!important;
    -moz-box-shadow: 0 0 2px 1px rgba($black,0.15)!important;
    box-shadow: 0 0 2px 1px rgba($black,0.15)!important;
    padding-right: 10px;
    .rt-th{
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 12px;
      color: $gray50;
      border: none;
      &:first-child{
        padding-left: 40px;
      }
    }
  }

  .rt-tbody{
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
