@import "src/scss/includes";

.eui-input {
  position: relative;
  font-size: 16px;

  > label {
    margin-bottom: 0;
    position: relative;
    display: flex;

    > input[type="file"]{
      opacity: 0;
    }
    > input {
      order: 2;
      border: 0 none;
      background-color: transparent;
      display: block;
      width: 100%;
      height: 20px;
      font-size: inherit;
      color: $gray85;
      margin: 22px -5px 14px -5px;
      line-height: 20px;
      z-index: 1;
      border-radius: 8px;
      padding: 0 5px;

      ~ span.eui-input-face {
        z-index: 0;
        border-radius: 5px 5px 0 0;
        background-color: $white;
        border-color: $gray10;
        border-style: solid;
        border-width: 1px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:required {
        ~ span.eui-input-face {
          border-bottom-color: $purple75;
        }
      }

      &:placeholder-shown {
        ~ span.eui-input-label {
          font-size: 12px;
          line-height: 16px;
          top: 6px;
          margin-top: 0;
          color: $gray70;
        }
      }

      &:disabled {
        color: $gray60;
        -webkit-text-fill-color: $gray60;
        opacity: 1;

        ~ span.eui-input-face {
          background-color: $gray5;
          border-color: $gray5;
        }

        ~ span.eui-input-label {
          color: $gray60;
        }
        &:required {
          ~ span.eui-input-face {
          border-bottom-color: $gray15;
        }
        }
      }

      &:focus {
        outline: 0 none;

        ~ span.eui-input-face {
          background-color: $purple20;
        }

        &:required {
          ~ span.eui-input-face {
            border-bottom-color: $purple75;
            border-bottom-width: 2px;
          }
        }

        ~ span.eui-input-label {
          font-size: 12px;
          line-height: 16px;
          top: 6px;
          margin-top: 0;
          color: $purple80;
        }
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-transition-delay: 99999s;
        transition-delay: 99999s;
      }

      @include placeholder {
        color: $gray60 !important;
      }
    }

    > span.eui-input-icons-after {
      z-index: 1;
      order: 4;
      padding: 13px 16px 13px 0;
      color: $gray30;
      display: flex;
      user-select: none;

      > i.eui-icon {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-size: 24px;
        &.eui-icon-type-input-button {
          cursor: pointer;
          transition: all .2s ease 0s;
          &:hover {
            color: $gray60;
          }
        }
      }

      &:not(:empty) {
        padding-right: 11px;
        padding-left: 8px;
      }
    }

    > span.eui-input-prefix {
      padding: 22px 0 14px 16px;
      z-index: 1;
      order: 1;
      white-space: nowrap;
      color: $gray60;
      font-size: inherit;
      line-height: 20px;

      &:not(:empty) {
        padding-right: 8px;
      }
    }

    > span.eui-input-suffix {
      padding: 19px 0 0 0;
      z-index: 1;
      order: 3;
      white-space: nowrap;
      color: $gray60;
      font-size: inherit;
      line-height: 20px;

      &:not(:empty) {
        padding-left: 8px;
      }
    }

    > span.eui-input-label {
      z-index: 1;
      position: absolute;
      line-height: 18px;
      font-size: inherit;
      top: 23px;
      left: 16px;
      transition: top .2s, font-size .2s;
      cursor: text;
      color: $gray85;
    }
  }

  > .eui-input-validation-message {
    line-height: 18px;
    font-size: 12px;
    color: $red100;
    min-height: 23px;
    padding: 5px 16px 0 16px;
  }

  &.eui-input-not-empty {
    > label {
      > span.eui-input-label {
        font-size: 12px;
        line-height: 16px;
        top: 6px;
        margin-top: 0;
        color: $gray70;
      }
    }
  }

  &.eui-input-not-valid {
    > label {
      > input:not(:focus) {
        ~ span.eui-input-face {
          border-bottom-color: $red100;
          border-bottom-width: 2px;
        }
      }

      > span.eui-input-label {
        color: $red100;
      }
    }

    &.eui-input-not-empty {
      > label {
        > input:not(:focus) {
          ~ span.eui-input-face {
            border-bottom-width: 2px;
            background-color: $purple20;
          }
        }
      }
    }
  }
}
