@import "src/scss/includes";

.eds-review-sidebar-item {
  min-height: 30px;
  padding-left: 26px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
  padding-right: 10px;

  .eds-review-sidebar-item-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border {
      width: 14px;
      height: 14px;
      border-width: 2px;
    }
  }
  .eds-review-sidebar-name-and-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding-right: 9px;

    .eds-review-sidebar-item-name {
      position: relative;
      padding-left: 17px;
      font-size: 14px;
      max-width: 151px;
      line-height: 18px;
      word-break: break-word;
    }

    .eds-review-sidebar-item-text-icon {
      font-size: 14px;
      width: 20px;
      display: flex;
      justify-content: center;
      color: $gray70;
    }

    .eds-review-sidebar-item-not-performed-icon {
      background: $blue100;
      border-radius: 2px;
      color: $white;
      font-size: 12px;
      padding: 0 2px;
      font-weight: bold;
    }

    .eds-review-sidebar-item-signing-icon {
      font-size: 20px;
      font-family: $ffMaterialIcons;
      font-weight: 600;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
    }

    .eds-review-sidebar-item-signed-icon {
      @extend .eds-review-sidebar-item-signing-icon;
      color: $green80;
    }

    .eds-review-sidebar-item-not-signed-icon {
      @extend .eds-review-sidebar-item-signing-icon;
      color: $gray15;
    }
    .eds-review-sidebar-item-denied-icon {
      @extend .eds-review-sidebar-item-signing-icon;
      color: $gray15;
      font-weight: 500;
      font-size: 22px;
    }

  }

  &:hover {
    background-color: darken($gray10, 3%);
    color: $purple80;
    .eds-review-sidebar-item-text-icon{
      span{
        color: $purple80;
      }
    }
  }

  &.active {
    background: $purple20;
    border-left: 3px solid $purple80;
    padding-left: 23px;
    color: $purple80;
  }
  $disabled-color: $gray15;

  &.eds-review-sidebar-item-disabled{
    color:$disabled-color;
    cursor: auto;
  }
}
