@import "src/scss/includes";
.eds-fv-info {
  position: absolute;
  bottom: 10px;
  left: 15px;
  .fvi-icon{
    user-select: none;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    color: $green100;
    font-size: 50px;
    opacity: 0.7;
  }
}
  .eds-rc-tooltip.fv-tooltip {
    z-index: 10000;
    .eds-file-viewer-info {
      width: 411px;
      margin: 8px 20px 8px 8px;
      font-size: 16px;

      .fvi-block {
        display: flex;
        flex-direction: column;

        .fvi-sign {
          line-height: 25px;
          margin-bottom: 5px
        }
      }
      .fvi-hint {
        line-height: 18px;
        font-size: 12px;
        color: $gray70
      }
    }

    .fv-tooltip-close {
      font-size: 24px;
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 10px;
      color: $gray60;

      &:hover {
        color: $gray70;
      }
    }
  }
