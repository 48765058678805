@use 'sass:math';
@import "src/scss/includes";

.page-info-header {
  border-bottom: 1px solid $gray88;
  padding: 0 25px 16px 25px;
  margin-bottom: 15px;
  z-index: 1000;
  position: relative;
  background-color: $gray2;
  .page-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-section {
      h1 {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: $black;
        margin: 0;
        padding: 0 25px 0 0;
        span {
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          color: $gray70;
          margin: 0;
          a {
            text-decoration: underline;
            color: inherit;
          }
        }
      }
    }
    .additional-info {
      position: relative;
      flex: 1 1;
      min-height: 18px;
      display: flex;
      .additional-info-item {
        flex-wrap: nowrap;
        white-space: nowrap;
        line-height: 18px;
        display: flex;
        position: relative;
        a {
          text-decoration: underline;
          color: inherit;
        }
      }
      .shadow-items > .additional-info-item, .visible-items > .additional-info-item, > .additional-info-item  {
        padding: 0 15px;
        border-right: 1px solid $gray88;
        &:first-child {
          border-left: 1px solid $gray88;
        }
        &:last-child {
          border-right: 0 none;
        }
      }
    }
    .right-section {
      display: flex;
      align-items: center;
    }
  }
  .filters-section {
    .general-header-wrapper {
      padding: 0;
    }
    .general-header-group-container {
      margin: 0.5rem 0;
    }
    .search-input {
      padding-left: 0;
    }
  }
  .general-header-wrapper {
    div:last-child {
      margin-right: 0;
    }
  }
  .page-info-header-collapse-btn {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid $gray10;
    position: absolute;
    left: 50%;
    margin-left: math.div(29px,-2);
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    overflow: hidden;
    color: $gray70;
    cursor: pointer;
  }
  &.page-info-header-with-record-label {
    .page-info {
      flex-wrap: wrap;
      .additional-info {
        order: 1;
        flex-basis: 100%;
        margin-top: 10px;
        .shadow-items > .additional-info-item, .visible-items > .additional-info-item, > .additional-info-item  {
          &:first-child {
            padding-left: 0;
            border-left: 0 none;
          }
        }
      }
    }
  }
  &.filters-section-collapsed {
    .filters-section {
      height: 0;
      overflow: hidden;
    }
  }
}
