@import "src/scss/includes";

.encounters-tab-loader {
  margin: 12px;
  .encounters-tab-loader-1 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 13px;
    .encounters-tab-loader-1-1 {
      width: 188px;
      height: 25px;
    }
    .encounters-tab-loader-1-2 {
      width: 132px;
      height: 25px;
    }
  }
  .encounters-tab-loader-2 {
    background-color: $white;
    padding: 20px 25px 25px 25px;
    .encounters-tab-loader-2-1 {
      display: flex;
      padding-bottom: 20px;
      .encounters-tab-loader-2-1-1 {
        width: 132px;
        height: 25px;
        margin-right: 20px;
      }
      .encounters-tab-loader-2-1-2 {
        width: 101px;
        height: 25px;
      }
    }
    .encounters-tab-loader-2-2 {
      background-color: $gray88;
      height: 1px;
      width: 100%;
    }
    .encounters-tab-loader-2-3 {
      width: 188px;
      height: 12px;
      margin-top: 20px;
    }
    .encounters-tab-loader-2-4 {
      width: 188px;
      height: 12px;
      margin-top: 25px;
    }
  }
  .encounters-tab-loader-gradient {
    cursor: progress;
    background:
            linear-gradient(0.25turn, transparent, #fff, transparent),
            linear-gradient(#eee, #eee),
            radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
            linear-gradient(#eee, #eee);
    background-repeat: no-repeat;
    background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
