@import "src/scss/includes";

.eds-select-with-search {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .r-ss-trigger{
    border-radius: 5px!important;
    background-color: $gray10!important;
    border-color: $gray15!important;
    height: 40px!important;
    color: $gray85;
    padding: 11px 26px 9px 16px!important;
    .carat{
      display: none;
    }
  }
  .r-ss-dropdown{
    top: 0!important;
    .r-ss-dropdown-option {
      padding-top: 0!important;
      padding-bottom: 0!important;
    }
  }

  .user-info{
    .label{
      font-size: 12px;
      line-height: 12px;
      color: $gray50;
    }
    .name{
      font-size: 14px;
      line-height: 21px;
    }
  }

  .eds-select{
    position: absolute;
    width: 258px;
  }
  .selected-groups-container {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    height: 100%;
    border-top: none;
    margin-top: 5px;
    padding-top: 46px;
    .selected-group {
      overflow-y: auto;
      .group-label {
        font-size: 14px;
        line-height: 40px;
      }

      .group-item {
        font-size: 14px;
        padding: 8px 16px 8px 12px;
        justify-content: space-between;
        margin: 4px 0;

        .eds-icon-btn {
          font-size: 24px;
          display: none;
          color: $gray15;
          &:focus{
            outline: none;
          }
          &:hover{
            color: $gray60;
          }
        }
        .button-container{
          max-width: 18px;
          min-width: 18px;
        }

        &:hover {
          background-color: $gray10;
          .button-container{
            .eds-icon-btn {
              display: block;
              color: $gray60;
            }
          }
        }

        .check-box-label{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .user-info{
            flex-grow: 1;
          }
        }
        &.mobile{
          .eds-icon-btn {
            display: block;
          }
        }
      }
    }
  }
}
