@use "sass:color";
@import "src/scss/includes";

.Toastify {
  .Toastify__toast-container {
    width: 400px;
    padding: unset;

    &.Toastify__toast-container--top-right {
      top: 60px;
    }

    .Toastify__toast.Toastify__toast-theme--colored {
      width: 100%;
      color: $gray85;

      --font-family: 'sans-serif';
      --toastify-color-success: #{$green10};
      --toastify-color-info: #{$blue10};
      --toastify-color-warning: #{$yellow10};
      --toastify-color-error: #{$red10};
      --toastify-color-transparent: #{rgba(#000000, 0.7)};

      &.nm-multiline {
        .Toastify__toast-body {
          white-space: pre-wrap;
        }
      }

      &.Toastify__toast--success {
        border: 1px solid $green100;
      }

      &.Toastify__toast--warning {
        border: 1px solid $yellow100;
      }

      &.Toastify__toast--info {
        border: 1px solid $blue100;
      }

      &.Toastify__toast--error {
        border: 1px solid $red100;
      }
    }

    .Toastify__toast-icon {
      align-self: baseline;
      margin-right: 12px;
    }

    .Toastify__close-button.Toastify__close-button--colored {
      color: $gray85;
      align-self: baseline;
      outline: none;
    }
  }
}
