@import "src/scss/includes";

.work-list-current-status-cell-portal {
  top: 40px;
  width: 160px;
  position: absolute;
  overflow-y: auto;
  max-height: 180px;
  background-color: white;
  border: 1px solid lightgray;
  -webkit-box-shadow: 0 0 2px 1px rgba($black, 0.15) !important;
  -moz-box-shadow: 0 0 2px 1px rgba($black, 0.15) !important;
  box-shadow: 0 0 2px 1px rgba($black, 0.15) !important;

  .status-el {
    padding: 10px;
    cursor: pointer;

    &.active{
      font-weight: bold;
      color: $purple80;
      background-color: $gray7;
    }
    &:hover {
      background-color: $gray7;
    }
  }

  &:after {
    position: fixed;
    content: '';
    display: block;
    height: 35px;
    width: 160px;
    background-color: transparent;
  }
}
