@import "src/scss/includes";
.not-performed-shortcut-modal {
  font-style: normal;

  .eds-modal-box-container.eds-modal-box-container {
    margin-top: 15vh;
    margin-bottom: auto;
  }

  .eds-modal-box-container .eds-modal-box-header.eds-modal-box-header {
    font-size: 20px;
    font-weight: 500;
    color: $black;
  }

  .eds-modal-box-container .eds-modal-box-body.eds-modal-box-body {
    max-height: 286px;
    overflow-y: auto;
    line-height: 150%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .eds-modal-box-body {
    color: $gray85;
    font-size: 14px;
    line-height: 150%;

    h5 {
      font-weight: bold;
    }

    p {
      font-weight: normal;
    }
  }

  .close-reason-div {
    padding: 18px 25px 0 25px;

    .close-reason-input {
      background-color: $gray5;
      font-size: 16px;
      line-height: 25px;
      color: $gray60;
      padding: 16px 16px 15px 16px;
      width: 100%;
      border: none;
    }
  }

  .eds-modal-box-footer {
    .eds-btn-group {
      button {
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;
        width: 169px;
        height: 40px;
      }
    }
  }

}
