.payments-stipend-section {
  .MuiDataGrid-row {
    .MuiSvgIcon-root.stipend-help-icon {
      visibility: hidden;
      vertical-align: sub;
    }
    &.Mui-hovered {
      .MuiSvgIcon-root.stipend-help-icon {
        visibility: visible;
      }
    }
  }
}

