@import "src/scss/includes";
button.review-file-view{
  background-color: $gray2;
  height: 38px;
  color: $gray60;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid $gray15;
  padding: 0 20px;
  border-radius: .25rem;
  margin: 5px;
}
