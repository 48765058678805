@import "src/scss/includes";

.bottom-panel-container {
  position: fixed;
  width: 100%;
  height: calc(100% - 54px);
  z-index: 1112;
  top: -200vh;
  background-color: $white;
  overflow-y: auto;
  display: flex;
  box-shadow: 0 6px 20px -13px rgba($black, 0.35);
  transition: top 1s;

  &.bottom-panel-visible {
    top: 54px;
    transition: top 1s,  box-shadow 5s ;
    box-shadow: none;
  }
}