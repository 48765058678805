@import "src/scss/includes";
.encounter-item-group {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 355px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;

  border: 1px solid $gray10;
  box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);
  -webkit-box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);
  -moz-box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);


  &.selected{
    background-color: white;
  }

  a{
    width: 100%;
  }
  .commentbox {
    min-height:208px !important;
  }

  .details-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding-left: 24px;
    padding-right: 15px;
    align-items: center;
    min-height: 56px;
    i{
      font-size: 24px;
      z-index: 0;
    }
  }

  .add-item-group{
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .right-corner-button{
    margin-right: 13px;

    .hide-element {
      visibility: hidden;
    }
  }

  .remove-button {
    color: $gray60;
    cursor: pointer;
    &:hover {
      color: $gray70;
    }
  }

  .p-4 {
    display: flex;
    flex-direction: column;
    min-height: 210px;
    height: 100%;
  }

  .eye-icon {
    button {
      font-size: 11px;
      padding: 2px;
      background-color: white;
      border: 1px solid $purple80;
      color: $purple80;
      border-radius: 3px;
      &.e-hide {
        background-color: $purple80;
        color: $white;
        border: none;
      }
    }
  }

  .item-group-name {
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  .item-group-date {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: $gray60;
    font-size: 12px;
    padding: 3px;
  }

  .blinded {
    background-color: $gray5;
    opacity: 0.5;
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
  }

  .color-grey {
    color: $gray50;
  }

  &.mobile{
    min-width: 220px;
    .item-group-name {
      -webkit-line-clamp: 4;
    }
  }
}

