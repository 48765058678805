@import "src/scss/includes";
.eds-modal-box.print-version-generator-of-encounters {
  .eds-modal-box-container {
    height: 612px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .eds-modal-box-body {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      overflow: auto;
      flex: 1 1;
      color: $black;

      h3 {
        font-weight: 600;
        font-size: 18px;
      }

      .encounters-selector {
        padding-top: 25px;
        padding-bottom: 10px;
        flex: 0 0 385px;
        overflow-y: auto;
      }

      .item-groups-selector {
        padding-left: 45px;
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }
}
