@import "src/scss/includes";
.erc-item-group-label {
  padding-right: 20px;
  display: flex;

  .erc-item-group-label-content {
    color: #2465C6;
    line-height: 34px;
    font-weight: 600;
    font-size: 20px;
    a {
      color: inherit;
    }
  }

  .erc-item-group-label-status {
    padding-left: 10px;
    > span {
      background: #E7E7E7;
      border-radius: 27px;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 150%;
      font-weight: 400;
    }
  }
}
