@import "src/scss/includes";

.right-panel{
  position: fixed;
  top: 54px;
  right: 0;

  display: flex;
  flex-direction: column;

  width: 365px;
  height: calc(100% - 54px);
  padding-left: 15px;
  overflow-y: auto;

  z-index: 1112;

  animation: move-from-right-to-left 0.2s;
  transition: right 0.2s linear;

  .right-panel-container {
    display: flex;
    flex-direction: column;

    width: 350px;
    height: 100%;

    background-color: white;
    box-shadow: 0 12px 12px 7px rgba($black, 0.15);
    overflow-y: auto;

    .right-panel-header {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      border-bottom: 1px solid $gray15;

      font-weight: 600;
      font-size: 20px;
      line-height: 40px;
    }
    .right-panel-body {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      padding: 0 20px;
      overflow-y: auto;
    }
    .right-panel-footer {
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
      align-items: center;
      border-top: 1px solid $gray15;
      min-height: 70px;
    }
  }

  .right-panel-collapse-button {
    position: absolute;
    top: 160px;
    left: 0;

    height: 60px;

    border: none;
    background-color: transparent;
    line-height: 28px;

    &:hover, &:focus, &:active{
      background-color: transparent;
    }

    .eui-icon {
      line-height: 28px;
      margin: 3px!important;
      background-color: white;
      box-shadow: 0 1px 5px 0 rgba($black,0.29);
      height: 28px;
      width: 28px;
      border-radius: 100%;
    }
  }

  &.is-collapsed {
    transition: right 0.2s linear;
    right: -330px;
    overflow: hidden;
    padding-left: 80px;
    .right-panel-header, .right-panel-footer{
      border: none;
    }

    .right-panel-collapse-button {
      .eui-icon{
        margin: 2px!important;
        background-color: $purple80;
        color: white;
      }
    }
  }
}

@keyframes move-from-right-to-left {
  0% {
    right: -330px;
  }
  100% {
    right: 0;
  }
}
