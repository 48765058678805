@import "src/scss/includes";

.protocol-setup-epoch {
 &.incorrect-epoch-warning {
    box-shadow: 0 4px 8px 0 $red70;
  }

  .button-encounter {
    font-size: 12px;
    background-color: $white;
    border: 2px solid;
    border-radius: 12px;
    height: 30px;
    width: 106px;
  }

  .enc {
    color: $blue100;
    border-color: $blue100;
  }
}