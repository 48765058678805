@import "src/scss/includes";

.date-template {
  display: flex;
  flex-grow: 1;
  .eui-date-picker-manual {
    display: flex;
    flex-grow: 1;
    .eui-input{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  .date-icon {
    color: $gray60;
    padding-top: 2px;
    padding-right: 5px;
    font-size: 22px;
  }

}