@import "src/scss/includes";

.eds-modal-box.all-progress-note-modal {
  .eds-modal-box-container {
    .eds-modal-box-header {
      padding: 5px 5px 0 5px;
      justify-content: flex-end;
    }
    .notes-info {
      padding: 0 40px;
      .notes-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .eds-modal-box-body {
      padding: 0 40px 30px 40px;
      min-height: 100px;
      overflow: auto;
      background-color: $white;
      .eds-loader {
        margin: 20px auto;
      }
      .apn-list-no-data {
        text-align: center;
        padding: 20px 0;
      }
    }
  }
}
