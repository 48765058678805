@import "src/scss/includes";
table.encounter-epoch-table-template {
  background-color: $white;
  width: 100%;
  display: block;
  border-collapse: separate;
  border-spacing: 0;
  overflow-y: auto;
  border-top: 1px solid $gray10;
  border-left: 1px solid $gray10;

  th, td {
    border-right: 1px solid $gray10;
    border-bottom: 1px solid $gray10;
  }

  thead {
    border: 1px solid $gray10;
  }

  tbody {
    > tr {
      td {
        height: 45px;
      }
    }
  }

  .eett-grey-cell {
    background-color: $gray2;
  }

  .eett-right-align {
    text-align: right;
    padding-right: 5px;
    padding-left: 5px;
  }

  .eett-header {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .eett-sticky-column {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .eett-sticky-main-header {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 3;
    padding-right: 10px;
    padding-left: 20px;
  }

  .eett-sticky-row {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .eett-sticky-encounter {
    position: sticky;
    top: 38px;
    z-index: 2;
  }
  .eett-middle-align {
    text-align: center;
  }

  .eett-epoch-cell {
    max-height: 38px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .eds-unexpected-encounter-column {
    display: block;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
}
