.eds-modal-box.add-custom-item-modal {
  .eds-modal-box-body {
    display: flex;
    flex-direction: column;
    height: 550px;
    overflow-y: auto;
    .new-option-list {
      display: flex;
      align-items: center;
      .new-option-input {
        width: 400px;
        margin: 0 30px;
      }
    }
    .field-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .custom-item-dropdown {
        width: 400px;
        margin: 0 30px;
      }
    }
    .label,.new-option-label {
      font-size: 17px;
      min-width: 220px;
    }
    .final-option-list {
      margin-bottom: 20px;
    }
  }
}
