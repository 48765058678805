@import "src/scss/includes";

.eds-icf-widget {
  padding-bottom: 30px;
  .icfw-header {
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    padding: 10px 24px;
  }
  .icfw-item {
    padding: 0 24px 0 15px;
    .icf-completed-link {
      font-size: 14px;
      color: $gray85;
      padding: 5px 10px;
      display: block;
      &:hover {
        background-color: $gray10;
        border-radius: 3px;
      }
    }
    a {
      &:hover {
        color: $purple80;
        cursor: pointer;
      }
    }
  }
}
