@use 'sass:math';
@import "src/scss/includes";
.protocol-encounter-setup-page {
  display: flex;

  .encounter_elements {
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
  }
}
.encounter-setup-table-wrap {
  display: flex;
  max-height: calc(100vh - 222px);
  overflow: auto;

  table {
    border-collapse: collapse;

    td.selected-ig.align-middle.text-center.optional-ig {
      background-color: $yellow100;
    }

    .selected-ig {
      background-color: $green80
    }

    .white-text {
      color: $white;
    }

    td.selected-ig.align-middle.text-center.optional-ig {
      background-color: $yellow100;
    }

    .enc_tbl_padding {
      padding: 5px !important;
    }

    td.selected-ig.align-middle.text-center {
      position: relative;
      overflow: hidden;
    }

    .selected-ig.align-middle.text-center:hover > .optional-ig-triangle-toggle {
      position: absolute;
      $size: 35px;
      top: math.div(-$size, 2);
      right: math.div(-$size, 2);
      width: $size;
      height: $size;
      transform: rotate(45deg);
      background-color:  lighten($green80, 20%);
    }

    .selected-ig.align-middle.text-center.optional-ig:hover > .optional-ig-triangle-toggle {
      background-color: lighten($yellow100, 20%);
    }

    .move_element {
      cursor: move;
    }

    .encounter-setup-table {
      tr > td {
        width: 50px
      }
    }

    .over {
      border: 2px dashed $blue100;
      border-collapse: collapse;
    }


    .draggable-component {
      cursor: grab;
    }
  }

  .tooltipOver {
    max-width:300px;
    word-wrap: break-word;
  }
}

.situational-enc-section {
  display: flex;
  justify-content: flex-end;
  padding: 0 25px 7px 25px;
}

.elementtbl {

  .edit-btn-group {
    padding-left:20px !important;
  }
}
