.groups-setup-row-title-cell-sidebar-item-group-menu {
  position: relative;
  .groups-setup-row-title-cell-sidebar-item-group-menu-items {
    display: none;
    position: absolute;
    left: 100%;
    background-color: #FFFFFF;
    top: 50%;
    margin-top: -14px;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    overflow: hidden;

    .groups-setup-row-title-cell-sidebar-item-group-menu-item {
      cursor: pointer;
      white-space: nowrap;
      line-height: 28px;
      font-size: 12px;
      color: #575757;
      display: flex;
      padding: 0 5px;
      .eui-icon {
        font-size: 18px;
        margin-right: 3px;
      }
      &:hover {
        background-color: #F5F5F5;
      }
    }
  }
  &:hover {
    .groups-setup-row-title-cell-sidebar-item-group-menu-items {
      display: flex;
    }
  }
}
