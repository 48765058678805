@import "src/scss/includes";

.eds-toggle-select {

  button.eds-toggle-select-button {
    background-color: transparent;
    height: 34px;
    border: 0 none;
    padding: 5px 15px;
    transition: all .2s ease 0s;
    cursor: pointer;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: $gray70;
    margin: 0 !important;

    &:hover {
      background-color: $gray2;
      color: $purple80;
    }

    &:focus {
      outline: none;
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  button.eds-toggle-select-button.activated {
    background: $gray5;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      color: $gray70;
    }
  }

  button.eds-toggle-select-button.disabled {
    color: $gray50;
    background: $gray2;
    border-radius: 4px;
  }

  button.eds-toggle-select-button.unresponsive {
    color: $gray50;
    pointer-events: none;
    &:hover {
    }
  }

}
