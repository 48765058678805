@import "src/scss/includes";

.study-enrolled-patient-journey{
  .epoch-list-widget-container{
    display: flex;
    flex-direction: row;
    min-height: 100px;
    .epoch-list-widget{
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      width: 50%;
      padding: 10px;
      .epoch-list-widget-element{
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:hover{
          background-color: $gray7;
        }
        &.highlight{
          background-color: rgb(210, 237, 252);
        }
      }
    }
    .encounters-list-widget-container{
      display: flex;
      flex-direction: column;
      width: 50%;

      .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
      }
    }

    .encounters-list-widget{
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      width: 100%;
      padding: 10px;
      .encounters-list-widget-element{
        padding: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid $gray15;
        border-bottom: none;

        &:first-child{
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
        }
        &:last-child{
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          border: 1px solid $gray15;
        }
        &:hover{
          background-color: $gray7;
        }
      }
    }
  }

  .eds-select-type-chips{
    max-width: 100%;
    width: fit-content;
  }

}


