@import "src/scss/includes";
.eds-encounter-details-header {
  width: 100%;
  display: flex;
  flex-direction: column;

  >div{
    display: flex;
    justify-content: space-between;
    h5{
      line-height: 38px;
    }
  }

  .edh-study-manager-select {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 60px;
    line-height: 18px;

    .eds-select {
      min-width: 260px;

    }

  }

  .edh-edc-visit-date-select{
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 60px;
    line-height: 18px;
  }
  .eds-icon-btn {
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .r-ss-wrap{
    border-radius: 0;
  }
  .encounter-modification-dropdown-button {
    background-color: unset;
    border: none;
  }
}

@media (max-width:985px){
  .eds-encounter-details-header {
    .edc-visit-date input{
        width: 120px;
    }
  }
}

@media (max-width:868px){
  .eds-encounter-details-header {
    .eds-select{
      min-width: 120px!important;
    }
  }
}

@media (max-width:800px){
  .eds-encounter-details-header {
    .d-flex{
      flex-wrap: wrap;
    }
  }
}