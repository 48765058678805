@import "src/scss/includes";

.client-section {
  padding: 10px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .tabs-section {
    display: flex;
    justify-content: space-between;
  }
  .filter-section {
    display: flex;
    gap: 6px;
    margin-top: 24px;
    flex-wrap: wrap;
    .filter-item {
      padding: 3px 8px;
      width: 26px;
      height: 26px;
      font-family: $ffRoboto;
      font-size: 14px;
      line-height: 20px;
      color: $gray85;
      cursor: pointer;
      text-align: center;
      &.selected {
        border-radius: 100px;
        background-color: $gray10;
      }
    }
  }
  .tab-content {
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
    margin-top: 25px;
  }
}