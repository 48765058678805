@import "src/scss/includes";

.comment-section {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $gray15;
  padding-bottom: 15px;
  margin-bottom: 16px;
  .current-comment {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    position: relative;
    .item-text {
      margin-top: 9px;
    }
    .edit-comment-icon {
      color: $darkBlue100;
      font-size: 13px;
      font-weight: 600;
      position: absolute;
      top:0;
      right:0;
      cursor: pointer;
    }
  }
  .prev-comment-item {
    display: flex;
    margin-top: 16px;
    .prev-comment-icon {
      font-size: 26px;
      color: $gray70;
      margin-right: 11px;

    }
  }
}
