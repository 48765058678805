@import "src/scss/includes";
.custom-field-config{
  position: relative;
  height: 25px;

  .info{
    position: absolute;
    z-index: 1;
    background-color: $white;
    padding: 10px;
    border: 1px solid $gray10;
    box-shadow: 0 0 34px rgba($black, 0.2);
    width: 200px;
    right: calc( 100% + 16px);
    border-radius: 4px;
    box-sizing: border-box;
  }
  .edit-conditional-configuration-icon {
    margin: 6px 4px 0 0;
    font-size: 20px;
  }
}

.editAns{
  font-size: 15px !important;
  top: 5px !important;
}
