@import "src/scss/includes";
.erc-element {

  .erc-top-item-group{
    .erc-top-item-group-history {
      padding: 0 0 0 10px;
      height: 26px;
      .brand-link {
        color: #2465C6;
        cursor: pointer;
        text-decoration: none;
        font-style: inherit;
        font-weight: 600;
        line-height: 26px;
        > svg {
          margin-right: 7px;
        }
      }
    }

    .rie-not-performed {
      box-shadow: none;
      border-top: 1px solid $gray5;
      font-weight: 600;
      padding: 15px;
      > span {
        color: $purple75;
      }
    }
  }
}
