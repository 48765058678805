.rc-collapse.eds-patient-travel-preferences {
  margin-top: 10px;
  .rc-collapse-item {
    .rc-collapse-header {
      border-bottom: 0 none;
      min-height: 48px;
    }
    .rc-collapse-content {
      border-top: 1px solid #E7E7E7;
      .rc-collapse-content-box {
        padding: 15px 20px;
        > .eds-loader {
          margin: 25px auto;
        }
      }
    }
  }
}
