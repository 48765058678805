.patient-content-confirmation {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  .eui-btn-group{
    padding-top: 20px;
    button{
      min-width: 80px;
    }
  }
}
