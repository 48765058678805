.eds-modal-box.patient-portal-invitation-modal{
  .eds-modal-box-container {
    min-height: 260px;
    display: flex;
    flex-direction: column;
    .eds-modal-box-body {
      flex: 1;
    }
  }
}
