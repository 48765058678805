@import "src/scss/includes";

.widget-list-container{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;

  .widget-list-el{
    display: flex;
    padding: 10px;
    border: 1px solid $gray15;
    border-bottom: none;
    justify-content: space-between;
    background-color: white;
    flex-direction: row;
    width: 100%;

    &:first-child{
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
    }

    &:last-child{
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      border: 1px solid $gray15;
    }

    &:hover{
      background-color: $gray7;
      cursor: pointer;
    }
    &.highlight{
      background-color: rgb(210, 237, 252);
      border-color: transparent;
    }
    .right{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
    }
    .header{
      color: $gray85;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .icon-text{
      display: flex;
      flex-direction: row;
      font-size: 12px;
      margin-right: 10px;
      line-height: 28px;
      width: fit-content;
      border-radius: 40px;
      padding: 2px 10px 2px 0px;
      .eui-icon{
        font-size: 22px;
        margin-right: 5px;
        color: $gray60;
      }
    }
  }
}
