@import "src/scss/includes";

.eds-logs-widget {
  padding-bottom: 30px;
  .elw-header {
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    padding: 10px 24px;
  }
  .elw-list {
    padding: 0 24px 0 15px;
    .elw-list-no-data {
      text-align: center;
      padding: 24px;
      color: rgba($gray85, 0.4);
      background-color: rgba($gray85, 0.04);
      border-radius: 5px;
      margin: 0 8px;
    }
    .elw-list-item {
      padding: 5px 10px;
      transition: all 0.2s ease 0s;
      font-size: 14px;
      display: block;
      color: $gray85;
      cursor: default;
      .elw-list-item-count {
        font-weight: 640;
        text-decoration: underline;
        color: $blue100;
      }
      &:hover {
        background-color: $gray10;
        border-radius: 3px;
      }
      &.disabled {
        color: $gray30;
        &:hover {
          background: transparent;
        }
      }
    }
    a {
      &:hover {
        color: $purple80;
        cursor: pointer;
      }
    }
    .eds-loader {
      margin: 40px auto;
    }
  }
}
