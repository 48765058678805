.revenue-net-revenue{
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 11px 19px;
  align-items: center;
  .revenue-net-revenue-calculate{
    margin-left: 0;
  }
  .revenue-amount{
    display: flex;
    >span {
      padding-right: 5px;
    }
  }
}
