@use 'sass:math';
@import "src/scss/includes";
.eds-fv-image-viewer {
  height: 100%;
  position: relative;
  .eds-fv-image-viewer-zoom-control {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: math.div(-385px, 2);
    z-index: 1;
    display: flex;
    opacity: 0.5;
    transition: all .2s ease 0s;
    button {
      background-color: rgba($gray85, .9);
      color: rgba($white, .5);
      border: 0 none;
      padding: 5px 7px;
      height: 30px;
      line-height: 20px;
      display: block;
      width: 55px;
      text-align: center;
      outline: none;
      &:first-child {
        border-radius: 15px 0 0 15px;
      }
      &:last-child {
        border-radius: 0 15px 15px 0;
      }
      &.active {
        color: rgba($white, 1);
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  .eds-fv-image-viewer-scrollable {
    height: 100%;
    position: relative;
    overflow: auto;
    padding: 30px;
    .eds-fv-image-viewer-container {
      position: relative;
      margin: 0 auto;
      box-shadow: 0 19px 38px rgba($black,0.30), 0 15px 12px rgba($black,0.22);
      opacity: 0;
      transition: all .2s ease 0s;
      img {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 100%;
        height: auto;
      }
      &.eds-fv-image-viewer-container-visible {
        opacity: 1;
      }
    }
  }

}
