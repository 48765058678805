@import "src/scss/includes";

.expense-fixed-info-item {
  margin-bottom: 12px;
  .item-label {
    font-size: 13px;
    color: $gray70;
  }
  .item-text {
    font-size: 15px;
    font-weight: 600;
    color: $gray85
  }
}
