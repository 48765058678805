@import "src/scss/includes";
.eds-unfilled-row {
  margin: 0 0 7px 0;
  display: flex;
  justify-content: space-between;

  .eds-unfilled-row-meta-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 23px;

    .eds-unfilled-row-name, .eds-unfilled-row-name-for-all {
      width: 220px;
    }

    .eds-unfilled-row-action{
      margin-right: 41px;
      flex-wrap: nowrap;
    }

    .eds-unfilled-row-name {
      color: $gray85;
      font-size: 16px;
      line-height: 25px;
    }

    .eds-unfilled-row-name-for-all {
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
    }
  }

  .eds-unfilled-row-reason{
    width: 328px;
  }

}
