.date-picker-container {
  flex-direction:row;
  display: flex;
  .label {
    height: 21px;
    font-size: 14px;
    margin-right: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .date-select-button {
    margin-top: -5px;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
}