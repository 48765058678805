.erv-review-content {
  .erc-element {
    .erc-top-item-group {
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      line-height: 34px;
    }
  }
}
