@import "src/scss/includes";

@mixin create-invoice-input {
  .eui-input-face {
    border: none;
    border-bottom: 1px solid $gray70;
    background-color: white !important;
  }
  .eui-input-prefix {
    color: $black;
    padding-right: 2px;
  }
}

.tooltip-bank-info.rc-tooltip {
  font-family: $ffRoboto;
  z-index: 1130;
  .rc-tooltip-arrow {
    border-bottom-color: rgba(97, 97, 97, 0.90);
  }
  .rc-tooltip-inner {
    background: rgba(97, 97, 97, 0.90);
    color: $white;
  }
}

.create-invoice-full-screen {
  font-family: $ffRoboto;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 64px);
  width: 100%;
  .invoice-setup {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 60%;
    min-width: 686px;
    height: calc(100vh - 64px);
    overflow-y: hidden;
    .panel-header {
      margin: 18px 25px;
      display: flex;
      align-items: baseline;
      gap: 24px;
      .ful-screen-modal-name {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
      }
      .create-invoice-info {
        display: flex;
        color: $gray70;
        font-size: 12px;
        font-weight: 400;
        line-height: 25px;
        .eds-amount {
          margin: 0 3px;
        }
      }
      .create-invoice-bank-info {
        color: #2E7D32;  //update color
        display: flex;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        align-items: baseline;
        gap: 5px;
        margin-left: auto;
        svg {
          position: relative;
          top: 2px;
        }
      }
    }
    .panel-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .general-header-wrapper {
        margin: 0;
        >div {
          margin-right: 24px;
          margin-bottom: 16px;
        }
        .create-invoice-dropdown {
          .r-ss-trigger {
            border: none;
            border-bottom: 1px solid $gray70;
            &:focus {
              background-color: $white;
            }
          }
          .r-ss-selected-label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
          }
          .r-ss-expanded {
            .r-ss-trigger {
              background-color: $white;
            }
          }
          &.terms-dropdown {
            width: 122px !important;
          }
        }
        .create-invoice-input.eui-input {
          @include create-invoice-input
        }
      }

      .chip-section {
        padding: 0 20px 0 25px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 16px;
        .invoice-chip {
          font-size: 13px;
        }
      }
      .events-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        width: 100%;
        padding: 25px 20px 25px 25px;
        overflow: hidden;
        .amount-calculation {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: $gray85;
          font-family: $ffRoboto;
          margin-top: 18px;
          > div {
            display: flex;
            align-items: baseline;
            margin-bottom: 4px;
            .eds-amount {
              color: $gray85;
              padding: 0 11px;
              width: 100px;
              text-align: end;
            }
            &:last-child {
              color: $purple80;
              font-weight: 500;
              margin-top: 16px;
              .eds-amount {
                color: $purple80;
              }
            }
            &:first-child {
              font-weight: 500;
            }
          }
        }
      }
      .comment-section {
        width: 100%;
        padding: 0 33px 21px 26px;
        .create-invoice-input.eui-input {
          @include create-invoice-input;
        }
      }
    }
  }

  .pdf-preview-section {
    position: relative;
    overflow-y: auto;
    height: auto;
    display: flex;
    min-width: 675px;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    background-color: $gray2;
    box-shadow: inset 12px 0 15px -13px rgba($black, 0.15);
    padding-bottom: 10px;
    .pdf-preview-header {
      .eui-btn {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
    .action-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      position: absolute;
      top: 50%;
      margin-top: -142px;
      right: 18px;
      width: 56px;
      height: 284px;
    }
    .tab-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .eds-loader {
        width: 592px;
        height: 766px;
      }
    }
  }
}
