@import "src/scss/includes";

.eds-breadcrumb-navigation {
  padding: 12px 17px 12px 25px;
  display: flex;
  font-size: 14px;
  height: 36px;
  background: $gray2;
  .slash {
    color: $gray30;
  }
  > a, span {
    padding: 0 4px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  > a {
    color:  $purple80;
    &:hover {
      text-decoration: none;
      color:  $black;
    }
  }
  span {
    font-weight: 350;
    color: $gray60;
  }
}
