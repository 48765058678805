@import "src/scss/includes";
.preview-table-container{
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex: 1 1 100%;
  .encounter-preview {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .protocol-preview {
      display: flex;
      overflow: auto;

      .table-wrap {
        overflow: auto;
        display: inline-flex;

        .encounter_elements {
          max-width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 0;
          cursor: pointer;
          color: $blue100;
        }

        .selected {
          background-color: $green80;
        }

        .selected-optional {
          background-color: $yellow100;
        }
        .white-text {
          color: $white;
        }
      }
    }
  }
}
.lower-then-full-page {
  max-height: calc(100vh - 71px);
}
