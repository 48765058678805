@import "src/scss/includes";

.tab-content-invoice-history {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  padding: 16px 26px;
  gap: 16px;
  .invoice-history-header {
    align-self: start;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
  .invoice-card-header {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .invoice-card-status {
      display: flex;
      align-items: center;
      .eds-amount {
        font-size: 16px;
        margin-left: 8px;
        color: $gray85;
        line-height: 24px;
      }
    }
  }
  .email-template-for-generate-pdf {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    visibility: hidden;
    overflow: hidden;
  }
  .attachment-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 28px;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
    cursor: pointer;
    .attachment-filename {
      color: $darkBlue100;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}