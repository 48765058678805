@import "src/scss/includes";
.appointment-component {
  height: 100%;
  display: flex;
  flex-direction: column;

  .appointment-view-header {
    padding: 15px 25px 15px 25px;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    position: absolute;
    border-bottom: 1px solid $gray15;
  }

  .lightgrey{
    color: $gray70;
  }
  .grey-text{
    color: $gray85;
  }

  .time-zone-details-content{
    margin: 0 0 15px 0;
    padding: 15px;
    border-radius: 5px;
    background-color: $yellow10;
    .time-zone-details-view-field {
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      .primary {
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
      }
    }

    .normal {
      padding-bottom: 10px;
    }
    .change-time-zone-link {
      cursor: pointer;
      padding-top: 10px;
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      color: $purple75;
    }
  }

  .appointment-cancellation-content{
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    background-color: $red4;
    margin-right: -15px;
    margin-left: -15px;
    .appointment-view-field{
      padding-bottom: 10px;
    }
  }
  .appointment-view-field {
    display: flex;
    flex-direction: column;
  }


  .appointment-view-header, .appointment-view-field{
    .normal {
      font-size: 15px;
      line-height: 16px;
    }
    .primary {
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
    }
    .label {
      font-size: 13px;
      line-height: 22px;
    }
  }
  .participant-field{
    padding: 7px 10px 10px 10px;
    background-color: $gray5;
    border-radius: 4px;
    .label{
      color: $gray60;
      line-height: 12px;
      font-size: 12px;
    }
  }

  .appointment-view-content {
    overflow: auto;
    padding: 15px 25px 15px 25px;
    flex-grow: 1;
    margin-top: 60px;

    &.with-buttons{
      margin-bottom: 60px;
    }
    .canceled-event{
      &:before{
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background: $gray2;
      }
    }

    .reviewers-container {
      >.label{
        margin-bottom: 15px;
      }
    }
  }
}

