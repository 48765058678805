.travel-section {
  .MuiButtonGroup-root {
    padding: 20px 20px 0 20px;
  }

  .MuiDataGrid-root {
    height: 600px;
    padding: 20px 40px 0 40px;
    border: none;

    .MuiDataGrid-row {
      cursor: pointer;
    }
  }
}
