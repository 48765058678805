@import "src/scss/includes";
.multiple-upload-container {
  .drag-zone-outer {
    height: 80px;
    padding: 12px;
    background-color: $gray5;
    border-radius: 4px;
    color: $gray30;

    .drag-zone-inner {
      display: flex;
      justify-content: left;
      align-items: center;
      border: 1px dashed $gray30;
      box-sizing: border-box;
      border-radius: 4px;
      height: 56px;
      color: $gray70;
      padding-left: 20px;
      padding-right: 5px;
      cursor: pointer;

      i {
        margin-right: 5px;
      }
    }
  }
  .list-group {
    .mft-list-group-item-wrap {
      position: relative;
      display: flex;
      .mft-file-upload-toggle-block {
        position: absolute;
        left: 100%;
        display: flex;
        margin-top: 8px;
        line-height: 38px;
        width: 700px;
        .eui-toggle {
          margin-top: 8px;
          margin-left: 10px;
          margin-right: 10px;

          .eui-toggle-label {
            white-space: nowrap;
          }
        }

        .mft-certify-copy-label {
          width: max-content;
        }

        .eui-checkbox.confirm-certify-copy-checkbox {
          margin-top: 8px;
          &.text-color-red {
            span {
              color: $elligoRed;
            }
          }
        }
      }
     }
    .list-group-item {
      flex: 1;
      max-height: 38px;
      height: 38px;
      padding-left: 2px;
      padding-right: 50px;
      color: $gray60;
      overflow: hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
      }
      .mft-file-upload-name {
        padding-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .close-icon {
        position: absolute!important;
        top: 9px;
        right: 10px;
        font-size: 16px;
        cursor: pointer;
      }

      .view-icon {
        position: absolute!important;
        top: 9px;
        right: 30px;
        font-size: 16px;
        cursor: pointer;
      }

      .file-status-icon{
        font-size: 28px;
      }
    }
  }
};
