@import "src/scss/includes";

.groups-setup-encounter-header-cell-header-epoch-encounter-board {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background-color: $gray10;
  text-align: center;

  margin: 8.5px auto 0 auto;

  box-shadow: 6px -6px 0px 0px black;
  &.groups-setup-encounter-header-cell-header-epoch-encounter-board-non-protocol {
    box-shadow: 6px -6px 0px 0px #2D9CDB;
  }
}
