@import "src/scss/includes";

.budget-amount-input-block {
  .amount-type-label {
    font-weight: normal;
    margin-bottom: 8px;
  }

  .amount-input-wrapper {
    display: flex;

    .budget-amount-input {
      width: 100%;
      height: 34px;
      border: 1px solid #ced4da;
      padding-left: 12px;
      color: #495057;

      &:focus {
        z-index: 3;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
        transition: box-shadow 0.2s ease;
        outline: 1px $blue100;
      }

      &.required-amount-input {
        border-left: 3px solid #F34848;
      }
    }

    .input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
