@import "src/scss/includes";

.eds-modal-box.change-protocol-encounter-ordering-modal {
  overflow: hidden;

  .eds-modal-box-container {
    max-height: calc(100vh - 40px);
    height: 700px;
    display: grid;
    grid-template-rows: 70px auto 90px;

    .eds-modal-box-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;

      > .MuiPaper-root {
        display: grid;
        grid-template-rows: 60px auto;

        .cpo-paper-header {
          padding: 15px 20px;
          box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
          line-height: 30px;

          .MuiSvgIcon-root, .MuiTypography-root {
            vertical-align: middle;
          }
        }

        .cpo-droppable-list {
          height: 100%;
          overflow: auto;
          position: relative;

          > .cpo-droppable-list-container {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
          }
        }
      }
    }
  }
}

div.cpo-droppable-list-item {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border: 1px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.08);

  p {
    div.inner-item-container {
      display: flex;
      padding-top: 20px;

      p.encounter-name {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
        white-space: nowrap;
      }
    }
  }

  .cpo-encounter-board {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-color: $gray10;
    text-align: center;
    margin-right: 26px;

    display: inline-block;
    line-height: 24px;

    box-shadow: 6px -6px 0px 0px black;

    &.cpo-encounter-board-non-protocol {
      box-shadow: 6px -6px 0px 0px #2D9CDB;
    }
  }

  &.cpo-droppable-list-item-dragging {
    background-color: #f5f5f5;
    border-color: rgba(0, 0, 0, 0.08);
  }
}
