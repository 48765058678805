@use 'sass:math';
@import "src/scss/includes";


.eds-select {
  &.eds-select-disabled {
    .r-ss-selection-clear {
      cursor: none;
      pointer-events: none;
    }
  }
  &.eds-select-type-default {
    min-width: 200px;
    position: relative;
    text-align: left;

    &.eds-select-with-grouping {
      .r-ss-dropdown-option {
        padding-left: 28px !important;
        &:focus {
          background-color: $purple20;
        }
      }
    }
    > .r-ss-wrap {
      border-radius: 0;
      > .r-ss-trigger {
        background-color: $white;
        border-color: $gray10;
        border-style: solid;
        border-width: 1px;
        height: 56px;
        padding: 22px 26px 14px 16px;
        line-height: 18px;
        border-radius: 5px 5px 0 0;
        font-size: 15px;

        &:focus {
          background-color: $purple20;
        }

        .r-ss-selected-label {
          padding-right: 0;
        }

        .carat {
          right: 16px;
          bottom: 16px !important;
          &.up {
            bottom: 19px !important;
          }
        }

        .r-ss-selection-clear {
          top: 20px;
        }
        &.r-ss-disabled {
          background-color: $gray5 !important;
          border-color: $gray10;
          color: $gray50;
        }
      }

      &.r-ss-expanded {
        z-index: 102;
        > .r-ss-trigger {
          border-bottom-color: $purple75;
          outline: 0 none;
          background-color: $purple20;
          border-bottom-width: 2px;
          padding-bottom: 13px;
        }
        + label {
          color: $purple75;
          z-index: 103;
        }
      }
    }
    > label {
      position: absolute;
      line-height: 18px;
      font-size: 15px;
      top: 23px;
      left: 16px;
      transition: top .2s, font-size .2s;
      cursor: text;
      z-index: 101;
    }
    > .eds-select-validation-message {
      line-height: 18px;
      font-size: 12px;
      color: $red100;
      min-height: 23px;
      padding: 5px 16px 0 16px;
    }
    &.eds-select-not-empty {
      > label {
        font-size: 12px;
        line-height: 16px;
        top: 6px;
        margin-top: 0;
        color: $gray60;
      }
    }
    &.eds-select-clearable {
      > .r-ss-wrap {
        > .r-ss-trigger {
          padding: 22px 52px 14px 16px;
          >span{
            text-overflow: ellipsis;
            overflow: hidden;
            width: auto;
            display: block;
            white-space: nowrap;
          }
        }
      }
    }
    &.eds-select-not-valid {
      > label {
        color: $red100;
      }
      > .r-ss-wrap:not(.r-ss-expanded) > .r-ss-trigger {
        border-bottom-color: $red100;
        border-bottom-width: 2px;
        padding-bottom: 13px;
      }
    }
    &.eds-select-required {
      .r-ss-trigger {
        border-bottom: 1px solid $purple75;
      }
    }

    label {
      pointer-events: none;
    }
  }
  &.eds-select-type-chips {
    max-width: 200px;
    height: fit-content;

    .r-ss-wrap {
      min-width: fit-content;
      .r-ss-trigger {
        border: 1px solid $gray15;
        height: 25px;
        line-height: 19px;
        border-radius: math.div(25px, 2);
        color: $gray85;
        font-size: 14px;
        padding-right: 10px !important;
        .count {
          top: 1.5px;
          right: 30px;
        }
        .r-ss-selection-clear {
          opacity: 0.65;
          width: 27px;
          height: 23px;
          border-radius: math.div(23px, 2);
          top: 0;
          right: 0;
          line-height: 23px;
          > span {
            width: 100%;
            height: 100%;
            display: inline-block;
            margin: 0;
            &:before {
              font-family: $ffMaterialIcons;
              font-weight: 600;
              font-style: normal;
              content: "clear";
              font-size: 12px;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              line-height: 24px;
              text-align: center;
            }
          }
        }
        .carat {
          display: none;
        }
      }
      &.r-ss-expanded {
        .r-ss-trigger {
          background: $gray10;
          color: $purple80;
          border-color: $gray10;
        }
      }
    }

    .r-ss-dropdown {
      min-width: 250px;
    }

    &.eds-select-clearable {
      .r-ss-wrap {
        .r-ss-trigger {
          padding-right: 28px !important;
          &.r-ss-placeholder {
            padding-right: 10px !important;
          }
        }
      }
    }
    &.eds-select-not-empty {
      .r-ss-wrap {
        .r-ss-trigger {
          background: $gray10;
          border-color: $gray10;
        }
      }
    }
    &:hover {
      .r-ss-wrap {
        .r-ss-trigger {
          background: $gray10;
          color: $purple80;
          border-color: $gray10;
        }
      }
    }
    &.eds-select-disabled {
      .r-ss-wrap {
        .r-ss-trigger {
          background: transparent !important;
          border-color: $gray15;
          color: $gray30;
          cursor: not-allowed;
        }
      }
    }
    &.eds-select-multiselect {
      &.eds-select-clearable {
        .r-ss-wrap {
          .r-ss-trigger {
            padding-right: 58px !important;
            &.r-ss-placeholder {
              padding-right: 10px !important;
            }
          }
        }
      }
    }
  }
}
