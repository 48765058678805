@import "src/scss/includes";
.eds-study-selector {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .patient-status {
    font-size: 12px;
    color: $gray50;
  }
}
