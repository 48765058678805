@import "src/scss/includes";
.rbc-day-slot{
  .rbc-event{
    border-color: $white;
    z-index: 2;
    &.rbc-event-canceled {
      z-index: 1;
      text-decoration: line-through;
      line-height: normal;
      color: $gray60;
      background-color: $white;
    }
  }
  .rbc-event-label {
    display: none;
  }
}

.rbc-event-short {
  .patient-name {
    a {
      color:$gray85
    }
  }
}
.study-name-container  {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90% !important;
}
.event-tooltip {
  text-align: center;
}