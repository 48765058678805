@import "src/scss/includes";
.eds-revenue {
 display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
  height: calc(100vh - 150px);
  .revenue-section {
    background: $white;
    border: 1px solid $gray10;
    box-sizing: border-box;
    margin: 0 25px 8px 25px;
  }
}
