@import "src/scss/includes";
.patient-status-change-edit{
 display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  h4{
    font-size: 20px;
  }
  .main-area{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;

    .rdtPicker{
      width: 100%;
      top: 62px;
      .rdtActive{
        background-color: $purple80;
        border-radius: 4px;
      }
      .rdtDay{
        &:hover{
          border-radius: 4px;
        }
      }
      .rdtToday{
        &:before{
          border-bottom-color: $purple80;
        }
      }
    }

    .position-icons{
      button{
        padding-left: 0;
        min-width: 130px;
        text-align: left;
        display: flex;
        .material-icons{
          font-size: 28px;
          line-height: 20px!important;
        }
      }
    }

  }
  .action-buttons{
    border-top: 1px solid $gray10;
    button{
      padding: 9px 26px;;
    }
  }
}
