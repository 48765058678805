.conditional-logic-setup-modal {
  .MuiTableRow-root {
    &.readonly-row {
      background-color: #7d35590d;
    }
    .eds-select.eds-select-multiselect {
      max-width: 218px;
      .r-ss-wrap .r-ss-trigger {
        height: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;

        .count {
          top: 10px;
        }
        .r-ss-selection-clear {
          top: 10px;
        }
        .carat {
          bottom: 10px !important;
        }
      }
    }
  }
}