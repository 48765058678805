@import 'src/scss/includes';

.informed-consent-setup-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .informed-consent-setup-header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: $purple80;
    p {
      margin-bottom: 10px;
      font-weight: bold;
    }
    span {
      color: $blue100;
      font-weight: normal;
    }
  }

  .informed-consent-setup-columns {
    display: flex;
    flex-direction: column;

    .informed-consent-setup-column {
      width: 250px;
      .informed-consent-setup-column-header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 14px;
        line-height: 150%;
      }
      .informed-consent-setup-column-header-text {
        font-weight: bold;
        align-self: center;
        padding-top: 10px;
      }
      .add-informed-consent-setup-item {
        grid-column: 3;
        font-weight: normal;
        color: $purple80;
        cursor: pointer;
        align-self: center;
      }

      .edit-informed-consent-setup-item {
        display: grid;
        grid-template-columns: 126px 1fr;
        grid-template-rows: 10px 20px 10px;
        .edit-informed-consent-setup-item-text {
          grid-row: 2;
        }
        .edit-informed-consent-setup-item-button {
          grid-column: 2;
          grid-row: 2;
          align-self: center;
        }
      }
    }
  }
}
