@import "src/scss/includes";

.icf-section{
  border: 1px solid $gray15;
  margin-bottom: 10px;


  .icf-section-header{
    background-color: $gray7;
    color: $purple80;
    padding: 5px;
    font-size: 1rem;
    font-weight: 500;
  }

  .icf-field{
    display: flex;
    flex-direction: row;
    background-color: $blue10;
    align-items: center;
    padding: 5px;
    padding-left: 25px;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
    .icf-field-label{
      min-width: 50%;
      //display: flex;
    }
    .eds-select{
      min-width: 300px;
      max-width: 300px;
    }
    .eui-date-picker-manual{
      min-width: 274px;
      max-width: 274px;
    }
    .time-template {
      min-width: 300px;
    }
    .multiple-upload-container{
      min-width: 300px;
    }
  }
}