.invoice-reconcile {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 125px);
  .page-info-header {
    .reconcile-payments-header {
      display: flex;
      align-items: baseline;
      gap: 20px;
      margin: 21px 0 5px 0;
      >div {
        margin-bottom:0;
      }
    }
  }
  .multiple-apply-section {
    display: flex;
    align-items: center;
    padding: 0 35px 0 25px;
    gap: 20px;
    margin-bottom: 12px;
  }
}
