@import "src/scss/includes";
.eds-toggle-switch {
  display: inline-flex;
  align-items: center;
  > div {
    &.eds-toggle-switch-right-label, &.eds-toggle-switch-left-label, &.eds-toggle-switch-primary-label {
      padding: 0 8px;
      line-height: 23px;
    }
    &.eds-toggle-switch-control {
      white-space: nowrap;
      font-size: 0;
      border: 1px $purple30 solid;
      position: relative;
      display: flex;
      padding: 1px 0;
      input[type="radio"] {
        display: block;
        width: 23px;
        height: 19px;
        opacity: 0;
        cursor: pointer;
        &:nth-child(1) {
          &:checked ~ span {
            left: 0;
          }
        }
        &:nth-child(2) {
          &:checked ~ span {
            left: 23px;
            background: $gray15;
            border: 1px solid $gray30;
          }
        }
        &:nth-child(3) {
          &:checked ~ span {
            left: 46px;
          }
        }
      }

      span {
        display: block;
        position: absolute;
        top: 1px;
        bottom: 1px;
        width: 23px;
        transition: all .1s ease 0s;
        background-color: $purple80;
      }

    }
  }
  &.eds-toggle-switch-disabled {
    > div {
      &.eds-toggle-switch-control {
        opacity: 0.5;
        cursor: not-allowed;
        input[type="radio"] {
          cursor: not-allowed;
        }
      }
    }
  }
}

