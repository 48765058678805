@import "src/scss/includes";

$hint-marks: (
  "read": #71C108,
  "edit": #31E1B2,
  "sign": #30B077,
  "deny": $red70
);

.protocol-groups-setup {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin: 0 15px;
  height: calc(100vh - 225px);
  background-color: $white;
  padding: 16px 16px 0 16px;
  border: 1px solid $gray10;

  .protocol-groups-hints {
    display: flex;
    padding-bottom: 11px;
    border-bottom: 2px #E7E7E7 dotted;
    align-items: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: $gray60;


    .protocol-groups-hint {
      display: inline-flex;
      align-items: center;
      padding-left: 15px;

      &:first-child {
        padding: 0;
      }

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        margin-right: 10px;
        border-radius: 4px;
      }

      @each $hint-marks, $mark-color in $hint-marks {
        &.protocol-groups-hint-#{$hint-marks}:before {
          background-color: $mark-color;
        }
      }
    }
  }

  .protocol-groups-content {
    display: flex;
    overflow: hidden;
    padding: 20px 0 0 10px;
    position: relative;
    flex: 1;

    .no-groups-warning {
      color: $gray50;
    }
  }
}
