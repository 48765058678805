@import "src/scss/includes";


.MUI-grid-invoice-table.MuiDataGrid-root {
  .cell-with-status {
    display: flex;
    align-items: center;
    >div {
      width: 73px;
    }
  }
  .cell-with-icon {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: end;
    .eui-icon{
      width: 15px;
      font-size: 22px;
      color: $gray60;
      cursor: pointer;
    }
    .eds-amount {
      width: 100%;
    }
  }
  .table-cell-tooltip {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
}
