@import "src/scss/includes";

.hrdivider {
  border-top: 1px dashed $gray15;
  clear: both;
}

.drag-clear {
  float: right;
  padding-top:5px;
  color: $blue100;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer !important;
}

.dragOptions {
  cursor: move;
}

.emptyOptions {
  text-align: center;
  color:$gray50;
}

.fw500 {
  font-weight: 500;
}

.custAdd {
  font-size: 21px !important;
}

#finalOptContainer .over {
  border: 1px dashed $blue100 !important;
  box-shadow: 0px 0px 2px 2px $gray15 !important;
}

#finalOptContainer {
  max-height: 200px;
  overflow-y: auto;
  overscroll-behavior-y: inherit;
}