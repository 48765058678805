@import "src/scss/includes";

.item-groups-encounters-table-sidebar {
  position: absolute;
  top: 0;
  left: -300px;
  right: 0;
  bottom: 0;

  .item-groups-encounters-table-sidebar-item-group {
    height: 45px;
    width: 300px;

    background-color: $gray2;
    border-right: 1px solid $gray10;
    border-bottom: 1px solid $gray10;

    position: sticky;
    left: -300px;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 5px;
  }
}
