.patient-profile-subheader {
  + .patient-profile {
    height: calc(100vh - 54px - 34px - 37px - 83px - 65px);
    margin-top: 0;
  }
}
.patient-profile {
  display: flex;
  height: calc(100vh - 54px - 34px - 37px - 83px);
  width: 100%;
  margin-top: -15px;

  .patient-profile-sidebar {
    flex: 0 0 355px;
    height: 100%;
    border-right: 1px solid #ccc;
    background-color: #f6edf2;
  }

  .patient-profile-content {
    flex: 1;
    overflow: auto;
    padding: 20px 25px;
  }
}
