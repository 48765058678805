.site-finance-page {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
  height: calc(100vh - 125px);
  .page-info-header {
    margin-bottom: 0;
  }
}
