.eds-btn-group {
  font-size: 0;
  .eds-btn, .eds-icon-btn, .eds-btn-with-icon {
    margin: 0 3px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
