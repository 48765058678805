@import "src/scss/includes";

.Mui-invoice-table-footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 12px;
  line-height: 26px;
  color: $gray60;
  padding-right: 24px;
}