@import "src/scss/includes";

.text-editor-container {
  max-width: 600px;
  color: $black;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  &.regular-item-group {
    margin: 5px auto 10px auto;
    &.create {
      border-left: 3px solid $elligoRed;
    }
  }
  &.new-table-item-group {
    width: 400px;
    &.create {
      margin: 0 30px;
    }
  }
  .text-size-counter {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
    color: $gray50;

  }
}

.text-editor-inner {
  background: $white;
  position: relative;
}

.text-editor-input {
  height: 150px;
  overflow: auto;
  resize: none;
  font-size: 15px;
  position: relative;
  tab-size: 1;
  padding: 10px 8px;
  outline: none;
}

.text-editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.text-editor-text-bold {
  font-weight: bold;
}

.text-editor-text-italic {
  font-style: italic;
}

.text-editor-text-underline {
  text-decoration: underline;
}

.text-editor-text-strikethrough {
  text-decoration: line-through;
}

.text-editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.text-editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.text-editor-paragraph:last-child {
  margin-bottom: 0;
}

.text-editor-list-ol {
  padding: 0;
  margin: 0 0 0 16px;
}

.text-editor-list-ul {
  padding: 0;
  margin: 0 0 0 16px;
}

.text-editor-listitem {
  margin: 3px 32px 8px 32px;
}

.text-editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.text-toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1px;
  background: $gray2;
  padding: 4px 15px 3px 4px;
  vertical-align: middle;
  gap: 10px;
  border: 1px solid $gray15;
}

.text-toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  vertical-align: middle;
  margin-right: -12px;
}

.text-toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.text-toolbar button.toolbar-item.active {
  background-color: rgb(177 180 185 / 30%);
}

.text-toolbar button.toolbar-item.active i {
  opacity: 1;
}

.text-toolbar .toolbar-item:hover:not([disabled]) {
  background-color: $gray10;
}

.text-toolbar .divider {
  flex-grow: 1;
  margin-right: 4px;
}

.text-toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.text-toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}
