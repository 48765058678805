

.add-budget-event-container {
  display: flex;
  position: relative;
  .budget-event-selector {
    position: absolute;
    z-index: 2;
    bottom: -60px;
    left: -255px;
  }
}