@import "../../../../../../scss/includes";

.eds-site-bill-filter-layout {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .general-header-wrapper {
    border: none;
    padding: 0;
    margin: 0;
  }


  .ess-site-bill-status-filter-layout {
    display: flex;
    flex-wrap: wrap;
    .eds-site-bill-status-filter-checkbox {
      display: flex;
      margin-left: 25px;
      span {
        padding-left: 10px;
      }
    }
    .site-bill-radio {
      flex-wrap: wrap;
      padding-left: 61px;
      display: flex;
    }
  }
}
