.milestone-date-input {
  position: relative;
  .clear-date-button {
    background-color: transparent;
    position: absolute;
    right: 0;
    border: none;
    height: 34px;
    padding-top: 5px;
    top: 29px;
    z-index: 1;
  }
  .actual-checkbox {
    position: absolute;
    top: 27px;
    right: 26px;
    z-index: 1;
  }
  .not-applicable-checkbox {
    position: absolute;
    top: 27px;
    right: 106px;
    z-index: 1;
  }
}
