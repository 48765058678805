@import "src/scss/includes";

.eds-select {

  &.eds-select-multiselect {
    li[role="separator"]{
      display: block;
      font-size: 0 !important;
      height: 1px;
      padding: 0;
      border-top: none;
      border-bottom-color: $gray15 !important;
    }
    .r-ss-wrap {
      .r-ss-trigger {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        min-width: 200px;
        padding-right: 92px !important;
      }
      .placeholder {
        color: $gray85;
      }

      .r-ss-dropdown {
        .r-ss-dropdown-options {
          max-height: 205px;
          .r-ss-dropdown-option {
            padding-left: 0 !important;
            display: flex;
            .eui-checkbox{
              margin-left: 15px;
            }
            i {
              //for No Results Available specifically
              padding-left: 10px;
            }

            &:focus {
              border-left: none;
              border-right: none;
              border-top: none;
              background-color: $purple20;
            }

            &:before {
              display: none !important;
            }

            &.r-ss-selected {
              background: none;
              font-weight: normal;
              border: none !important;
              &:focus {
                background-color: $purple20;
              }
            }
          }
        }
      }

      .count {
        background-color: $purple80;
        color: $white;
        padding: 6px;
        display: inline-block;
        float: left;
        border-radius: 20px;
        line-height: 8px;
        position: absolute;
        right: 52px;
        top: 20px;
        min-width: 20px;
      }
    }
  }
}
