@import "src/scss/includes";

.floating-panel {
  z-index: 1112;
  position: fixed;
  right: 0;
  width: 350px;
  height: calc(100% - 54px);
  background-color: $white;
  top: 54px;
  box-shadow: 0 12px 12px 7px rgba($black, 0.15);
  .floating-panel-container{
    position: relative;
    width: 100%;
    height: 100%;
    .close-floating-panel {
      position: absolute;
      top: 8px;
      right: 18px;
    }
  }
}

.floating-panel-show{
  animation: move-from-right 0.0s;
  right: 0;
}

.floating-panel-hide{
  animation: move-to-right 0.5s;
  right: -430px;
}
