.eds-toggle-switch.radio-template-toggle-switch {
  justify-content: center;
  > div {
    &.eds-toggle-switch-right-label, &.eds-toggle-switch-left-label, &.eds-toggle-switch-primary-label {
      display: flex;
      flex: 1 1;
    }

    &.eds-toggle-switch-right-label {
      justify-content: flex-start;
      text-align: left;
      max-width: 140px;
    }

    &.eds-toggle-switch-left-label {
      justify-content: flex-end;
      text-align: right;
      max-width: 140px;
    }
  }
}
