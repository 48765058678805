.styled-text-view-wrapper {
  padding: 5px 5px 5px 5px;
  width: 335px;
}

.styled-text-view-wrapper.new-table-item-group {
  padding: 5px 5px 5px 5px;
  min-width: 335px;
}

.styled-text-view-wrapper.condition-field {
  padding: 5px 5px 5px 5px;
  width: auto;
}
