@import "src/scss/includes";

.eds-textarea {
  position: relative;

  > label {
    display: block;
    margin-bottom: 0;
    overflow: auto;
    position: relative;

    > textarea {
      display: block;
      width: 100%;
      font-size: 15px;
      color: $gray85;
      margin: 23px 0 15px 0;
      padding: 0 16px;
      line-height: 18px;
      position: relative;
      z-index: 1;
      outline: none;
      border-radius: 0;
      border: 0 none;
      background-color: transparent;

      &:placeholder-shown {
        + span {
          font-size: 12px;
          line-height: 16px;
          top: 6px;
          margin-top: 0;
        }
      }

      &:disabled {
        background-color: $gray2;
        border-color: $gray10;
        color: $gray50;
        + span {
          color: $gray50;
        }
      }

      &:focus {
        + .eds-textarea-outer {
          background-color: $gray5;
          border-bottom-color: $purple75;
          outline: 0 none;
          border-bottom-width: 2px;
          padding-bottom: 13px;
        }
        ~ span {
          font-size: 12px;
          line-height: 16px;
          top: 6px;
          margin-top: 0;
          color: $purple75;
        }
      }
    }

    > .eds-textarea-outer {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 5px 5px 0 0;

      background-color: $white;
      border-color: $gray10;
      border-style: solid;
      border-width: 1px 1px 1px 1px;

      &.eds-textarea-underlined {
        border-bottom-color: $purple75;
      }
    }

    > span {
      position: absolute;
      line-height: 18px;
      font-size: 15px;
      top: 23px;
      left: 16px;
      transition: top .2s, font-size .2s;
      cursor: text;
    }
  }

  > .eds-textarea-validation-message {
    line-height: 18px;
    font-size: 12px;
    color: $red100;
    min-height: 23px;
    padding: 5px 16px 0 16px;
  }

  > .eds-textarea-count{
    font-size: 12px;
    position: absolute;
    float: right;
    right: 14px;
    margin-top: -18px;
    color: $gray70;
  }

  &.eds-textarea-not-empty {
    > label {
      > span {
        font-size: 12px;
        line-height: 16px;
        top: 6px;
        margin-top: 0;
        color: $gray60;
      }
    }
  }

  &.eds-textarea-clear-all {
    > label {
      > textarea {
        padding: 0 37px 0 16px;
      }
      > .clear-all{
        position: absolute;
        right: 17px;
        top: 23px;
        font-size: 20px;
        z-index: 2;
        &:focus {
          outline: none;
        }
      }
    }
  }

  &.eds-textarea-not-valid {
    > label {
      > textarea:not(:focus) {
        + .eds-textarea-outer {
          background-color: $gray5;
          border-bottom-color: $red100;
          border-bottom-width: 2px;
          padding-bottom: 13px;
        }
      }
      > span {
        color: $red100;
      }
    }
  }
}
