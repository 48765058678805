@import "src/scss/includes";

.eds-tasks-container {
  padding-left: 15px;
  height: calc(100% - 54px);
  position: fixed;
  right: 0;
  z-index: 1112;
  top: 54px;

  width: 365px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: right 0.2s linear;

  .eui-btn.eui-btn-icon-only.eds-tasks-container-show-hide {
    position: absolute;
    border: none;
    height: 60px;
    background-color: transparent;
    line-height: 28px;
    top: 160px;
    left: 0;
    .eui-icon{
      line-height: 28px;
      margin: 3px;
      font-size: 22px;
      background-color: white;
      -webkit-box-shadow: 0 1px 5px 0 rgba($black,0.29);
      box-shadow: 0 1px 5px 0 rgba($black,0.29);
      height: 28px;
      width: 28px;
      border-radius: 100px;
    }
    &:hover, &:focus, &:active{
      background-color: transparent;
    }
  }

  &.eds-tasks-container-hidden {
    transition: right 0.2s linear;
    right: -330px;
    overflow: hidden;
    padding-left: 80px;
  }
}
