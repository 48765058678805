@import '../MappingSetupModal/MappingSetupModal';

.mapping-template-modal {
  .eds-modal-box-body {
    display: flex;
    flex-direction: column;
    height: 74vh;
    overflow-y: auto;
    .filter-wrapper {
      display: flex;
      flex-wrap: wrap;
      @include setupItemMapping;
      .mapping-input {
        .r-ss-dropdown-option {
          font-size: 16px;
        }
      }
    }
    .group-header {
      margin-top: 20px;
      font-weight: 600;
      font-size: 17px;
    }
  }

}