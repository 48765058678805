@import "src/scss/includes";

.contact-attempt-days-remaining {
  display: flex;
  align-items: center;

  > div {
    width: 75px;
  }
}

