$purple100: #450C29;
$purple80: #691E44;
$purple75: #7d3559;
$purple50: #AF728F;
$purple30: #cfb9c7;
$purple20: #f6ecf1;

$green100: #27AE60;
$green80: #6FCF97;
$green10: #EDFFF4;

$orange100: #F2994A;

$yellow100: #FFC738;
$yellow10: #FFFBDC;

$darkBlue100: #2465C6;
$blue100: #2D9CDB;
$blue50: #8CC7F2;
$blue10: #F2F7FF;

$elligoRed: #EE303C;
$red100: #F34848;
$red70: #EC6363;
$red30: #FF9A9A;
$red10: #FFF1EF;
$red4: #FFE7E0;

$black: #000000;
$gray88: #E0E0E0;
$gray85: #323232;
$gray70: #575757;
$gray60: #6E6E6E;
$gray50: #828282;
$gray30: #B7B7B7;
$gray15: #DBDBDB;
$gray10: #E7E7E7;
$gray7: #ECECEC;
$gray5: #F3F3F3;
$gray2: #FAFAFA;
$white: #FFFFFF;

$aero80: #B3BBCA;
$aero40: #DFE5EF;
