@import "src/scss/includes";

.protocol-groups-setup-preview {
  .protocol-groups-setup-preview-wrapper {
    max-height: calc(100vh - 71px);
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    .protocol-groups-setup-preview-wrapper-header {
      padding-bottom: 8px;
      margin-bottom: 16px;
      width: 100%;
      color: $purple80;
      border-bottom: 2px $gray10 dotted;
    }
    .protocol-groups-setup-preview-content {
      display: flex;
      overflow: hidden;
      padding: 20px 0 0 10px;
      position: relative;
      flex: 1;
    }
  }
}
