.eds-modal-box.update-success-metrics-modal {
  .form-group {
    padding-left: 30px;
    padding-right: 30px;
    .input-group {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 80%;
      >input {
        padding-right: 100px;
      }
      .lock-check-box {
        margin-left: -90px;
        z-index: 1000;
      }
    }
    .metric-name {
      font-weight: bold;
    }
  }
}
