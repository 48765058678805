@use 'sass:math';
@import "src/scss/includes";

.eui-image-viewer {
  width: 100%;
  height: 100%;
  position: relative;
  .react-transform-wrapper {
    width: 100%;
    height: 100%;
    .react-transform-component {
      position: relative;
      display: block;
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center;

      }
    }
  }
  .eui-image-viewer-zoom-control {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: math.div(-385px, 2);
    z-index: 1;
    display: flex;
    opacity: 0.5;
    transition: all .2s ease 0s;
    button {
      background-color: rgba($gray85, .9);
      color: rgba($white, .5);
      border: 0 none;
      padding: 5px 7px;
      height: 30px;
      line-height: 20px;
      display: block;
      width: 55px;
      text-align: center;
      outline: none;
      &:first-child {
        border-radius: 15px 0 0 15px;
      }
      &:last-child {
        border-radius: 0 15px 15px 0;
      }
      &.active {
        color: rgba($white, 1);
      }
    }
    &:hover {
      opacity: 1;
    }
  }
}

