@import "src/scss/includes";
.eds-modal-box.element-history-log-modal{
  .eds-modal-box-container {
    width: 100%;
    max-width: 1200px;

    .history-log-cell{
      text-align: left;
      justify-content: left;
    }

    .font-italic{
      font-weight: 100;
    }

    .history-modal {
      border: transparent;
    }

    .eds-review-history-changed-cell {
      background: $yellow10;
    }

    .rt-tr {
      &:hover {
        .eds-review-history-changed-cell {
          background-color: inherit;
        }
      }
    }
  }
}
