@import "src/scss/includes";

.eui-file-upload-preview {
  .eui-file-upload-preview-empty {
    color: $gray70;
    font-size: 18px;
    text-align: center;
    margin-top: 30%;
  }
}
