@import "src/scss/includes";

.adjusted-ledger-events-history-table {
  border-bottom: 1px solid $gray10;
  box-sizing: border-box;
  background: $white;
  display: flex;
  flex-direction: column;

  .revenue-date-edited {
    color: $purple80;
    position: relative;
    font-weight: 600;
    &:after {
      content: ' ';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $purple80;
      position: absolute;
      right: -5px;
      top: -2px;
    }
  }

  .table-header {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: $gray85;
    padding: 16px 24px;
  }

  .eds-finance-table {
    .ReactTable.ledger-events-history-table {
      flex: 1 0;
      max-height: 500px;

      .rt-table {
        .center-leveling {
          display: flex;
          justify-content: center
        }
        .end-leveling {
          display: flex;
          justify-content: flex-end;
        }

        .display-flex {
          display: flex;
        }

        .elipsis-overflow {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .let-event-id {
          margin-left: 24px;
        }
      }
    }
  }
}
