@import "src/scss/includes";

.task-ssu-multi-select-study{
  .label{
    font-size: 12px;
    min-height: 17px;
    color: $gray70;
    font-weight: normal!important;
  }
  .eui-input-selectors-without-label{
    margin-top: 17px;
  }
}