.patient-profile-medications-table-cell-date{
  .date-template{
    i{
      display: none;
    }
    .eui-date-picker-manual{
      .eui-input{
        label{
          .eui-input-face{
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}
