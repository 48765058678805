@import "src/scss/includes";

.pd-ssu-filter{
  display: flex;
  align-items: flex-end;
  .eds-btn{
    height: 38px;
    margin-top: 27px;
  }
}


