@import "src/scss/includes";

.table-item-group-results {
  display: grid;
  width: fit-content;
  z-index: 1;
  min-width: 100%;
  position: relative;

  > div {
    border: 1px solid $gray10;
    padding: 8px 16px;
    display: flex;

    .table-item-group-results-field {
      display: flex;
      justify-content: center;
      .table-item-group-results-field-group {
        display: flex;
        align-items: center;
        justify-content: center;
        .select-field, .text-box-field, .free-text-long-field, .multi-select-field, .file-upload-field {
          width: 375px;
        }
        .toggle-field {
          width: auto;
           .eds-toggle-switch .eds-toggle-switch-left-label, .eds-toggle-switch .eds-toggle-switch-right-label {
             width: auto;
           }
        }
        .check-box-field, .notice-field, .text-block-field {
          display: flex;
          width: max-content;
          min-width: 100px;
          max-width: 375px;
        }
        .date-field {
          width: 195px;
        }
        .time-field {
          width: 135px;
        };

      }
    }

    &.table-item-group-results-y {
      position: sticky;
      left: 0;
      z-index: 100;
      background-color: $white;
      > div {
        min-width: 200px;
      }
    }

    &.table-item-group-results-name {
      font-weight: 600;
      font-size: 14px;
      color: $purple80;
      white-space: nowrap;
      &.required-field {
        .table-item-group-results-y-name:before, .table-item-group-results-x-name:before {
          content: "*";
          font-weight: bold;
          color: $red100;
        }
      }
    }
  }
}
.table-item-group-results-x, .table-item-group-results-x-name, .table-item-group-results-y-name {
  display: flex;
  align-items: center;
}

