@import "src/scss/includes";
.eds-modal-box.history-of-revenue-dates-modal {
  .eds-modal-box-container {
    .eds-modal-box-body {
      padding: 0;
      font-size: 14px;
    }
    .revenue-date-history-info {
      display: flex;
      background-color: $white;
      border-top: 1px solid $gray5;
      border-bottom: 1px solid $gray5;
      padding: 0 30px;
      align-items: baseline;
      > h4 {
        font-size: 16px;
        font-weight: 600;
        color: $black;
        margin: 0;
        padding-right: 45px;
      }
      > div {
        color: rgba($black, 0.5);
        padding: 15px 10px;
        line-height: 24px;
        > span {
          color: $gray85;
        }
      }
    }
    .revenue-date-history {
      > h4 {
        font-size: 16px;
        font-weight: 600;
        color: $black;
        margin: 0;
        background-color: $white;
        padding: 15px 30px 0;
      }
    }
    .revenue-date-history-table-wrapper {
      height: 300px;
      overflow: auto;
      position: relative;
      padding-bottom: 40px;
    }
    .revenue-date-history-table {
      display: table;
      width: 100%;
      > div {
        display: table-row;
        > div {
          display: table-cell;
          background-color: $white;
          padding: 15px 35px;
          border-bottom: 1px solid $gray5;
          line-height: 21px;
          white-space: nowrap;
          &:last-child {
            width: 100%;
          }
        }
        &:first-child {
          color: rgba($black, 0.5);
          > div {
            position: sticky;
            top: 0;
            font-size: 13px;
            font-weight: 600;
            line-height: 16px;
            padding-bottom: 2px;
          }
        }
        &.highlighted {
          > div {
            background-color: $purple20;
          }
        }
      }
    }
    .revenue-date-history-form {
      background-color: $white;
      display: flex;
      border-top: 1px solid $gray10;
      border-bottom: 1px solid $gray10;
      padding: 0 25px;
      > div {
        padding-top: 21px;
        &:first-child {
          padding-right: 15px;
        }
        &:last-child {
          flex: 1;
        }
      }
    }
  }
}
