.general-header-wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 1rem;
  margin: 0.5rem 0;
  > div, > button {
    margin-bottom: 15px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  > .eui-btn-priority-medium {
    margin-left: auto;
  }

  .eds-select, .eui-input, .eds-filters-unit, .eui-date-picker, .eui-file-template, .eui-search-input, .apply-btn, .month-selector, .month-select {

    &:not(.eds-select-type-chips) {
      max-width: 400px;
      width: 100% !important;
      min-width: 150px;
    }

    > div:not(.file-layout) {
      width: 100% !important;
    }
    .rdtPicker {
      width: 298px !important;
    }
    .r-ss-wrap {
      min-width: inherit;
      .r-ss-trigger {
        min-width: inherit;
        width: inherit;
      }
      .r-ss-selected {
        border-bottom: 1px solid $gray15 !important;
        background-color: unset !important;
      }
    }
  }

  .apply-btn {
    width: fit-content;
  }

  .refresh-btn {
    margin-left: unset !important;
    min-width: 56px;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1046px){
  .sm-worklist-page {
    .general-header-wrapper {
      padding: 0.75rem 0.75rem 0 0.75rem;
      .eui-btn-priority-high {
        padding: 0 4px;
        margin-right: 5px !important;
      }
    }
  }
}
