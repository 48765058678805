@import "src/scss/includes";
@import "src/components/pages/patient-source/shared/Worklist/ReactTableHoc/ReactTableHoc";

.filter-dropdown {
  position: absolute;
  left: 0;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 1px 3px 0 #00000026, 0 2px 9px 0 #00000026;
  height: 500px;
  background: $white;
  font-weight: normal;
  width: 300px;
  &.placed-on-bottom {
    top: 100%;
  }
  &.placed-on-top {
    bottom: 100%;
    z-index: 1000;
  }
  .count {
    background-color: $purple80;
    color: $white;
    padding: 6px;
    display: inline-block;
    border-radius: 20px;
    line-height: 8px;
    min-height: 20px;
    &.main {
      margin-top:5px;
      margin-bottom: 5px;
    }
  }
  .no-results-message {
    text-align: center;
    font-weight: 600;
    font-style: italic;
    margin-top: 20px
  }

  .select-all-option {
    height: 56px;
    padding: 20px 15px;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid $gray10;
    background: $white;
  }
  .eui-search-input {
    width: 100% !important;
    font-size: 14px;
    .eui-input-face{
      border-left: none;
      border-right: none;
      border-top: none;
      width: 100%;
    }
    .eui-input-icons-after {
      .clear-button {
        font-size: 22px;
        color: $gray60;
      }
    }
  }

  .options-container {
    max-height: 300px;
    overflow-y: scroll;
    background: $white;
    .filter-collapse {
      border-right: none;
      border-left: none;
      border-top: none;
      width: 100%;

      .rc-collapse-item {
        border-right: none;
        border-top: none;
        border-bottom: none;
        background: none;
        width: 100%;

        .rc-collapse-header {
          padding-right: 50px;
          font-weight: bold;
          border-bottom: none;
          display: flex;
          min-height: 56px;
          flex-grow: 1;
          max-width: 380px;
          .panel-title {
            margin-right: 10px;
            @include two-line-text;
          }
        }

        .rc-collapse-content {
          padding-left: 40px;
          padding-bottom: 10px;

          .rc-collapse-content-box {
            display: flex;
            flex-direction: column;

            .selected-group {
              display: flex;
              align-items: center ;
              margin: 5px 0;

              .selected-checkbox {
                min-height: 100%;
              }

              .user-info {

                .label {
                  font-size: 12px;
                  line-height: 20px;
                  color: $gray50;
                }

                .name {
                  margin: 5px 0;
                  font-size: 14px;
                  line-height: 21px;
                }

                .truncated {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  white-space: normal;
                }

              }

            }

            .eui-input-selectors{
              min-height: 48px;
              display: flex;
              align-items: center;

              span{
                @include two-line-text;
              }
            }
          }
        }
      }
    }
  }

  .filter-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 60px;
    background: $white;

    > button {
      font-size: 14px;
    }
  }
}
