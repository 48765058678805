@import "src/scss/includes";

.protocol-setup-page {
  padding: 0;
  height: calc(100vh - 54px - 36px - 96px - 37px - 16px);
  min-height: 695px;
  display: flex;
  flex-direction: column;
  .protocol-setup-header {
    background-color: $gray5;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    .epochicon {
      height: 42px;
      width: 99px;
      padding-top: 11px;
      font-weight: 700;
      padding-left: 18px;
      border-radius: 4px;
      text-align: center;
    }
  }
  #canvas {
    flex: 1 1;
    display: block;
    .jtk-endpoint {
      z-index: 1;
    }
  }
}

.start_window {
  background-color: white;
  border: 1px solid $gray15 !important;
  border-left: 0 none !important;
  text-align: center;
  z-index: 24;
  cursor: pointer;
  position: absolute;
  color: black;
  padding: 0.5em;
  width: 60px;
  height: 60px;
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  padding-top: 18px;
  left: 0;
  border-radius: 0px;
}
.end_window {
  background-color: white;
  border: 1px solid $gray15 !important;
  border-right: 0 none !important;
  text-align: center;
  z-index: 24;
  cursor: pointer;
  position: absolute;
  color: black;
  padding: 0.5em;
  width: 60px;
  height: 60px;
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  padding-top: 18px;
  right: 0;
  float: right;
  border-radius: 0px;
}
.protocol-epoch {
  background-color: white;
  text-align: center;
  z-index: 24;
  cursor: pointer;
  -moz-border-radius: 0.1em;
  border-radius: 0px !important;
  position: absolute;
  color: black;
  padding: 0.5em;
  display: block;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba($black, 0.2);
  width: 270px;
}

.protocol-setup-btn {
  border: none;
}
button.protocol-setup-btn {
  background: $white;
}
.draggable-epoch {
  width: 150px;
  border: 1px solid $purple80;
  text-align: center;
  background: $white;
}
.epoch_margin_left {
  margin-left: 7px;
}

.protocol_study_color {
  color: $gray85 !important;
  font-size: 14px;
}
.edit_encounter_icon {
  font-size: 11px;
  position: absolute !important;
  left: -10px;
  top: 21px !important;
}
.addiconnline {
  clear: both;
  justify-content: space-between;
  display: flex;
}
.protosetaddbtn {
  background: $blue100 !important;
  border: none;
  padding: 6px;
  border-radius: 13px;
  color: $white;
}

.enaddbtn_new {
  width: 25px;
  margin: 0px auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px $blue100 solid !important;
  background-color: $blue10 !important;
  color: $blue100;
  cursor: pointer;
  outline: none;
}

.enc_del_btn {
  font-size: 12px;
  color: $white;
  background: none;
  border: none;
  padding-top: 0;
  margin-top: -4px;
  float: left;
  left: 5px;
  top: 0px;
  outline: none;
  cursor: pointer;
}
.enc_del_btn:active {
  border: none;
  outline: none;
}
.encedit {
  border: none;
  background: none;
  outline: none;
}

.bg-light-blue {
  background-color: $blue100;
}
