@import "src/scss/includes";

.eds-find-patient {
  position: relative;
  height: 47px;
  padding: 10px;
  margin-left: 2px;
  z-index: 102;
  font-family: $ffRoboto;
  &:hover {
    background: var(--environment-extra-color-1, $purple75);
  }
  .eds-find-patient-btn {
    min-width: 20px;
    height: 27px;
    padding: 3px 16px 3px 30px;
    border-radius: 3px;
    background-color: var(--environment-extra-color-2, $purple100);
    border: none;
    color: rgba($white, 0.58);
    line-height: 21px;
    cursor: pointer;
    user-select: none;
    .eui-icon {
      line-height: 26px;
      position: absolute;
      color: white;
      font-size: 20px;
      left: 15px;
      pointer-events: none;
      top: 50%;
      margin-top: -13px;
      z-index: auto;
    }
  }
}
