@import "src/scss/includes";

.new-task-container{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px 10px 10px 20px;
  height: 100%;

  .task-el{
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid $gray15;
    justify-content: space-between;
    background-color: white;
    position: relative;
    margin-left: 70px;
    flex-grow: 1;
    border-bottom: none;

    &.first{
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
    }

    &.last{
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      border: 1px solid $gray15;
    }

    &:hover{
      background-color: $gray7;
      cursor: pointer;
    }
    &.highlight{
      background-color: rgb(210, 237, 252);
      border-color: transparent;
    }

    .footer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      >div{
        display: flex;
        flex-direction: row;
      }
    }

    .icon-text{
      display: flex;
      flex-direction: row;
      font-size: 12px;
      margin-right: 10px;
      line-height: 28px;
      width: fit-content;
      border-radius: 40px;
      padding: 2px 10px 2px 0;
      min-width: 50px;
      white-space: nowrap;
      .eui-icon{
        font-size: 22px;
        margin-right: 5px;
        color: $gray60;
      }
    }
  }
  .no-data-message{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
