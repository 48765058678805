.previewTable-scroll {
  overflow: auto;
}
.investigator-field-icon {
  font-size: 20px;
  margin-right: 10px;
  line-height: 20px;
}

.cross-line {
  text-decoration: line-through;
}
