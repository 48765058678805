.pd-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .eui-btn {
    margin-right: 15px;
  }
}
.setup-protocol-table {
  .comments-column {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
