@import "src/scss/includes";

.eds-modal-box.pre-save-items-modal {
  .eds-modal-box-container {
    .eds-modal-box-body {
      .answer-difference {
        padding-left: 15px;
        padding-bottom: 10px;
        > div {
          b {
            width: 80px;
            display: inline-block;
            text-align: right;
          }
        }
      }
    }
  }
}
