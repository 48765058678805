@import "src/scss/includes";

.item-groups-encounters-table {
  width: 100%;
  overflow: auto;
  position: relative;
  padding-top: 76px;
  padding-left: 300px;
  border: 1px solid $gray10;
  .item-groups-encounters-table-cells-wrapper {
    position: relative;
    .item-groups-encounters-table-cell-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      border-right: 1px solid $gray10;
      border-bottom: 1px solid $gray10;
    }
  }
}
