@import "src/scss/includes";
.eds-comment-section {
  width: 100%;
  display: inline-block;
  .edit-comment-area {

    .comments-author {
    font-weight: bold;
    font-size: 13px;
    padding: 10px 0 5px 15px;
    color: $gray70;

    span {
      font-weight: 100;
      font-size: 13px;
      color: $gray70;
    }
  }
  .comment {
    background-color: $gray5;
    border-radius: 10px;
    padding: 15px;
    margin-right: 82px;
    margin-bottom: 23px;
  }
    .comments-area {
      display: flex;
      justify-content: space-between;

      .textarea-layout {
        flex: 1;
      }

      .button-layout {
        padding-bottom: 23px;
        display: flex;
        align-items: flex-end;
        margin-left: 20px;
      }
    }
  }
}
