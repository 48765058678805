@import "src/scss/includes";

.eds-calendar-big {
  .milestone-events-container {
    display: flex;
    flex-grow: 1;
    .milestone-event {
      display: inline-block;
      background-color: $blue100;
      min-height: 24px;
      border-radius: 3px;
      color: $white;
      line-height: 24px;
      cursor: pointer;

      &.selected {
        box-shadow: 0 6px 10px 0 rgba($black, 0.14), 0 1px 18px 0 rgba($black, 0.12), 0 3px 5px -1px rgba($black, 0.2);
      }
    }
  }

  .agenda-container {
    background-color: $gray2;
    height: 100%;
    overflow-y: scroll;
    z-index: 5;

    .agenda-event {
      display: flex;
      flex-direction: row;
      border-radius: 2px;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .event-header{
        display: flex;
        margin-bottom: 15px;
        flex-direction: row;
        .time {
          display: flex;
          flex-direction: column;
          width: 80px;
          min-width: 80px;
          color: $black;
          line-height: 26px;
          .day {
            padding: 0 5px;
            border-radius: 5px;
            height: fit-content;
            width: fit-content;
            font-size: 16px;
            &.today {
              background-color: $purple80;
              color: $white;
              margin-right: 5px;
            }
          }
          &.today {
            color: $purple80;
            .day {
              background-color: $purple80;
              color: $white;
              margin-right: 5px;
            }
          }
        }
      }
      .events {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .agenda-event-desc {
          display: flex;
          flex-grow: 1;
          min-height: 50px;
          flex-direction: row;
          padding: 10px;
          margin-bottom: 15px;
          cursor: pointer;
          background-color: $white;
          border-radius: 4px;
          border: 1px solid $gray7;

          &.selected {
            border-color: $purple80;
            border-radius: 4px;
          }

          &:hover {
            background-color: $gray10;
            border-radius: 4px;
          }
          .time-range{
            min-width: 160px;
            display: flex;
            .text {
              &.canceled {
                text-decoration: line-through;
              }
            }
          }
          .details-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            .title {
              font-weight: 500;
              .canceled {
                text-decoration: line-through;
              }
            }
            .text {
              display: inline-block;
              line-height: 18px;
              width: 33%;
              max-width: 300px;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
              &:last-child{
                max-width: 1000px;
              }
            }
            .comments {
              color: $gray50;

              &.canceled {
                color: $purple80;
              }
            }
          }
        }

      }
    }
  }
}
