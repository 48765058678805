@import "src/scss/includes";

.study-manager-selector{
  .menu-button{
    width: 150px;
    .eui-btn-text{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .rc-menu{
    max-height: 250px;
    overflow-y: auto;
    margin-left: -40px;
  }
  .rc-menu__item{
    &.selected{
      background-color: $gray7;
    }
  }
}