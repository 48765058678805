@import "src/scss/includes";
.ledger-event-info{
  height: 50px;
  background: $white;
  border-bottom: 1px solid $gray10;
  width: 100%;
  display: flex;
  padding-left: 24px;
  align-items: center;
  .lei-header {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $gray85;
    padding-right: 53px;
  }
  .lei-key-to-value{
    font-size: 14px;
    padding-right: 20px;
    .lei-key{
      color: rgba($black, 0.8);
      opacity: 0.6;
      padding-right: 5px;
    }
    .lei-value{
      color: $gray85;
    }
  }
}
