@import "src/scss/includes";

.eds-protocol-setup-item-group-list {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 230px);
  background-color: $white;

  .ReactTable.eds-protocol-setup-item-groups-table {
    border: 1px solid $aero40 !important;
    .rt-table {
      .rt-tbody {
        overflow-y: scroll;
        .rt-tr:hover {
          cursor: pointer;
          .actions-layout {
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .actions-layout {
    display: none;

    .eye-icon {
      color: $gray60;

      &:hover {
        color: $purple80;
      }
    }
  }

  .search-input input{
    width: 280px;
  }
}
