@import "src/scss/includes";

.eds-patient-info-sidebar {

  .pis-ssu-patient-select {
    position: relative;
    padding: 15px 40px 0 15px;

    .patient-status {
      font-size: 12px;
      color: $gray50;
      float: right;
    }
    .eds-select-validation-message{
      display: none;
    }
  }
  .pis-status-indicator{
    padding: 17px 24px 17px 24px;
    display: flex;
    align-items: center;
    .basic-dot {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin: 0 4px 0 4px;
      border-radius: 100%;
      &.skipped {
        background-color: $orange100;
        &:after{
          content: "!";
          color: $white;
          font-size: 19px;
        }
      }
      &.failed {
        background-color: $red70;
        &:after {
          width: 8px;
          height: 8px;
          content: "";
          background-image: url("../../../../../../images/icons/history-failed.svg");
          color: $white;
          font-size: 19px;
        }
      }
    }

    a {
      color: $purple80;
      padding: 3px 6px;
      border-radius: 3px;

      &:hover {
        background-color: $gray10;
      }
    }
  }
}
