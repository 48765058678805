.edit-patient-primary-contact {
  display: flex;
  align-items: baseline;
  .edit-patient-primary-contact-field-group {
    margin-top: -3px;
    margin-bottom: -3px;
    display: flex;
    align-items: center;
    > input[type="text"] {
      height: 28px;
      width: 130px;
    }
    > .eds-icon-btn {
      margin-left: 5px;
    }
  }
}
