@import "src/scss/includes";

.eui-input.eui-input-legacy {
  > label{
    height: 36px;
     > input {
       font-size: 15px;
      margin: 8px -5px 7px -7px;
       ~ span.eui-input-face{
         border-radius: 0;
         border-color: $gray15;
       }
       &:required ~ span.eui-input-face {
         border-bottom-color: $gray10;
         border-left: 3px solid $red100;
       }
       &:focus ~ span.eui-input-face {
         background-color: $white;
       }
    }
  }
  .eui-input-validation-message{
    display: none;
  }
  .eui-input-icons-after {
    font-size: 19px;
    padding: 2px 0 0 0;
  }
}
