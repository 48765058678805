@import "src/scss/includes";

.worklist-selected-patients-menu {
  height: 36px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #616161;
  color: $white;
  font-family: $ffRoboto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  position: relative;
  &::after{
    content:'';
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    background-color: #616161;
    top: 14px;
    left: 52px;
    transform: rotate(45deg);
    z-index: -1;
  }
  div{
    .eui-btn {
      color: $white;
      &:active, &:focus, &:hover {
        background-color: transparent;
        color: $white;
        border-color: transparent;
      }
      &:disabled {
        color: rgba(255, 255, 255, 0.5)
      }
    }
  }
}

.menu-place-holder {
  height: 36px;
}