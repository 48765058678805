@import "src/scss/includes";

.study-budget-site .add-section {
  border: 1px solid $gray15;
  margin-top: 0.5rem;
  h5 {
    padding-left: 1rem;
    padding-top: 0.5rem;
    margin-bottom: 0;
  }
  .general-header-wrapper {
    border: none;
  }
}

.study-budget-site .add-section .filter-section {
  width: 100%;
  margin: 0;
}

.study-budget-site .add-section .effective-date-layout {
  width: 430px;
  position: absolute;
  top: 35px;
}

.study-budget-site .add-section .effective-date-layout span {
  float: left;
  position: relative;
}

.study-budget-site .add-section .effective-date-layout .add-button {
  float: left;
  margin-top: 29px;
  height: 35px;
  margin-left: 10px;
}

.study-budget-site .add-section .effective-date-layout input {
  height: 35px;
}

.study-budget-site .add-section .effective-date-layout .date-icon {
  position: absolute;
  color: $gray30;
  display: inline-block;
  font-size: 20px;
  padding-top: 36px;
  right: 10px;
}

.icon-cal {
  position: absolute;
  color: $gray30;
  display: inline-block;
  font-size: 20px;
  padding-top: 36px;
  right: 10px;
}

.study-budget-site .existing-budget-sites-section .filter-section {
  margin: 0;
}

.study-budget-site .existing-budgets {
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid $gray15;
  h5 {
    margin-bottom: 0.5rem;
  }
  .general-header-wrapper {
    border: none;
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .study-budget-site .add-section .effective-date-layout {
    left: 510px;
  }
}

@media (min-width: 1367px) {
  .study-budget-site .add-section .effective-date-layout {
    left: 570px;
  }
}
