@import "src/scss/includes";

.template-item {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  .already-exist {
    color: $orange100;
    font-size: 25px;
    cursor: pointer;
  }

  .preview-input {
    margin: 8px;
    min-width: 206px;
    max-width: 206px;
  }


}
.tooltip-note-value {
  .mapping-alert-value {
    span {
      font-size: 14px;
    }
    .alert-header {
      font-weight: bold;
      font-size: 16px;
      display: block;
      width: 100%;
      text-align: center;
    }
    .alert-value-name {
      font-weight: bold;
      color: $purple80;
      margin-left: 24px;
    }
  }
}