@import "src/scss/includes";
.eds-help-page{
    .row:after {
        display: table;
        clear: both;
    }
    .column2 {
        float: left;
        text-align: justify;
        width: 50%;
        padding-top:10px;
        padding-left: 40px;
        padding-right: 20px;
    }
    .column3 {
        float: left;
        text-align: justify;
        width: 33%;
        padding-top:10px;
        padding-left: 40px;
        padding-right: 20px;
    }
    .column4 {
        float: left;
        text-align: justify;
        width: 24%;
        padding-top:10px;
        padding-left: 40px;
        padding-right: 20px;
    }
    .bottomCenter{
        text-align: center;
    }
    .video {
        max-width: 95%;
        border: 1px solid $gray10;
    }
    .supportText{
        font-size: 1.1rem;
    }
    #helpContent{
        margin-top: .5rem!important;
        margin-bottom: .5rem!important;
    }
}
