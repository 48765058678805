.table-cell-answer-layout {

  .not-answered {
    font-style: italic;
    font-weight: 100;
  }

  .edited-answer-layout {
    display: inline-block;
  }
}

.table-cell-edited-answer-layout {
  min-width: 150px;
  display: flex;

  .not-answered {
    font-style: italic;
    font-weight: 100;
  }

  .edited-answer-layout {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
