@import "src/scss/includes";
.encounter-print-multiselect {
  .epm-header {
    position: relative;
    &:after {
      content: ' ';
      width: 140px;
      height: 1px;
      background-color: $gray10;
      display: block;
    }
  }
  .epm-option {
    margin: 24px 0;
    position: relative;
    line-height: 20px;
    cursor: pointer;
    user-select: none;
    .eui-input-selectors.eui-checkbox{
     margin: 0;
    }
  }
  .epm-group {
    .eui-form-group{
      label.eui-input-selectors{
        margin: 12px 0 12px 0;
      }
    }
    .epm-group-label {
      margin: 24px 0;
      font-weight: bold;
      font-size: 14px;
    }
    .epm-group-options {
      padding-left: 3px;
    }
  }
}
