@import "src/scss/includes";

.rc-menu--open{
  &.rc-menu--dir-bottom, &.rc-menu--dir-top, &.rc-menu--dir-left, &.rc-menu--dir-right {
    opacity: 1;
    animation: none;
    -webkit-animation: none;
  }
}
.rc-menu--closing{
  display: none!important;
  &.rc-menu--dir-bottom, &.rc-menu--dir-top, &.rc-menu--dir-left, &.rc-menu--dir-right {
    opacity: 0;
    animation: none;
    -webkit-animation: none;
  }
  .rc-menu__item{
    display: none!important;
  }
}

.rc-menu-container {
  .rc-menu {
    box-shadow: 0 0 20px rgba(99, 117, 139, 0.35);
    padding: 10px 0;
    min-width: 85px;
    font-size: 14px;
    line-height: 150%;
    background: $white;
    border-radius: 0;
    color: $black;

    .rc-menu__submenu > .rc-menu__item::after {
      content: 'arrow_right';
      font-family: $ffMaterialIcons;
      color: $gray60;
      font-size: 24px;
      left: 48px;
    }

    .rc-menu__item {
      padding: 9px 15px;

      &.rc-menu__item--hover {
        color: $purple80;
        background-color: $gray5;
      }

      &.rc-menu__item--disabled {
        color: $gray30;
      }
    }
  }

  .rc-menu--dir-right {
    margin-left: 10px;
  }

  .rc-menu--dir-bottom {
    margin-top: 10px;
  }

  .rc-menu--dir-top {
    margin-top: -10px;
  }

  .rc-menu--dir-left {
    margin-left: -10px;
  }

}
