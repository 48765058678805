@import "src/scss/includes";
@import "src/components/pages/setup/shared/ElementSetupNew/TableSetup/TableSettings/TableSettings";

.endpoint-configuration-page {
  .new-platform-wrapper {
    margin-top: 18px;
  }
  .current-platforms {
    @include listWrapper;
    margin-bottom: 22px;
    .platform-wrapper {
      @include listItemWrapper;
      &:hover{
        background-color: $gray5;
        border: 1px solid $gray10;
      }
      .delete-platform-icon {
        @include listDeleteIcon
      }
      .platform-name {
        @include listItemName
      }
    }
  }

}