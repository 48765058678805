@import "src/scss/includes";

.eds-finance-table {
  display: flex;
  flex: 1 1;
  overflow: auto;

  .ReactTable {
    border: none;

    .rt-table {
      .ft-center {
        display: flex;
        justify-content: center;
      }

      .ft-end-leveling {
        display: flex;
        justify-content: flex-end;
      }
      .ft-long-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .status-change-menu{
        margin-left: 20px;
        display: none;
      }
      .rc-menu-container{
        position: absolute;
        top: 0;
      }
      .rt-thead {
        border-bottom: none;
        .rt-tr {
          .rt-th {
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: rgba($black, 0.8);
            opacity: 0.6;
            letter-spacing: 0.02em;
            padding: 10px 20px 11px;
            border: 0 none;
            cursor: default;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }

      .rt-tbody {
        border: 1px solid $gray5;
        .rt-tr-group:hover {
          .status-change-menu {
            display: inline-flex;
          }
        }
        .rt-tr-group {
          flex: unset;
          height: 54px;
          min-height: 54px;
          border-bottom: 1px solid $gray5;
          border-top: 1px solid $gray5;
          .rt-tr {
            .ft-numbers.rt-td, .ft-numbers {
              color: $purple80;
              font-weight: 600;
            }
            .rt-td {
              font-size: 14px;
              line-height: 150%;
              color: $gray85;
              padding: 10px 20px;
              border: 0 none;
              align-items: center;
              display: flex;

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
