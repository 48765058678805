@import "src/scss/includes";
.payable-to-card {
  min-width: 300px;
  height: min-content;
  position: relative;
  overflow: visible;
  .card-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 143px;
    height: 124px;
    top: 50px;
    right: -100px;
    z-index: 2;
    background-color: white;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    >div {
      font-size: 16px;
      cursor: pointer;
      height: 36px;
      padding: 6px 16px;
      &.disabled {
        color: $gray30;
        cursor: initial;
      }
      &:hover {
        background-color: $gray5;
      }

    }
  }
  .payable-to-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 9px 16px;
    color: $gray60;
    .header {
      font-family: $ffRoboto;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
    >div {
      display: flex;
      align-items: center;
      .default-mark {
        font-size: 13px;
        line-height: 18px;
        padding: 3px 10px;
        color: $gray85;
        background-color: $gray10;
        border-radius: 100px;
      }
    }
  }
  .accordion-details {
    padding-bottom: 16px;
    box-shadow: none;
    border:none;

    &:before {
      height: 0;
      border: none;
      box-shadow: none;
    }
    .MuiAccordion-root {
      padding-bottom: 16px;
    }
    .MuiAccordionSummary-root {
      min-height: 24px;
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
    .MuiAccordionDetails-root {
      padding-bottom: 0;
    }
    .information {
      font-family: $ffRoboto;
      font-size: 14px;
      color: $gray60;
      line-height: 20px;
      &.email {
        color: $darkBlue100;
      }
    }
    .information-header {
      font-weight: 500;
      font-size: 14px;
    }
  }

}