@import "src/scss/includes";

.eds-calendar-custom-header{
  color: $gray60;

  .dateName {
    font-size: 10px;
  }

  .headerDate {
    min-height: 37px;
    line-height: 38px;
    font-size: 18px;
    padding: 5px;
    font-weight: bold;

    &.headerToday {
      background-color: $purple80;
      font-size: 18px;
      width: 37px;
      height: 37px;
      line-height: 38px;
      border-radius: 6px;
      color: $white;
      margin-right: 4px;
    }
  }
}
