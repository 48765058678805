.toggle-field {
  display: flex;
  justify-content: center;
  .eds-toggle-switch {
    .eds-toggle-switch-left-label, .eds-toggle-switch-right-label{
      display: flex;
      width: 100px;
      text-align: center;
    }
    .eds-toggle-switch-left-label {
      justify-content: end;
    }
    .eds-toggle-switch-right-label {
      justify-content: start;
    }
  }
}
