@import "src/scss/includes";

.budget-event-table {
  &.column-value {
    text-align: right;
  }

  .text-colour {
    color: $elligoRed
  }
}
