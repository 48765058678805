.eds-modal-box.patient-status-remove-modal {
  .eds-modal-box-container {

    .eds-modal-box-body {
      .change-status-confirmation {
        margin-bottom: 25px;

        span {
          display: block;
          size: 16px;
          line-height: 24px;
        }
        span.confirmation-text {
          margin-bottom: 5px;
        }
      }
    }

    .eds-modal-box-footer {
      .eui-btn-group {
        button {
          min-width: 132px;
        }
        button:last-child {
          margin-left: 18px;
        }
      }
    }
  }
}
