@import "src/scss/includes";

%investigator-widget-show {
  margin-top: 30px;
  float: right;
}

.worklist-widget  {
  min-width: 450px;
  max-width: 50%;
  max-height: fit-content;
  width: fit-content;
  .widget {
    padding: 32px;
    .total-encounter {
      display: flex;
      flex-direction: row;
      color: #6E6E6E;
      font-style: normal;
      .total-encounter-amount {
        font-style: normal;
        font-weight: 500;
        margin-right: 12px;
        line-height: 26px;
        font-size: 26px;
        color: #212121;
      }
    }
    .graphic-rows-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      font-weight: bold;
      flex-grow: 1;
      width: 100%;

      margin-top: 26px;
      .bar{
        height: 14px;
        min-width: 14px;
        border-radius: 10px;
        margin: 8px 6px 8px 0;
        cursor: pointer;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .details-container {
      margin-top: 42px;

      display: grid;
      grid-template-columns: repeat(3, minmax(auto, auto));
      grid-column-gap: 42px;
      grid-row-gap: 5px;
      >div {
        width: fit-content;
        padding: 12px;
        &.clickable {
          cursor: pointer;
        }

      }
      .currently-selected {
        background-color: $gray5;
        border-radius: 4px;
      }
      .details-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        &.clickable {
          color: $darkBlue100;
        }
      }
      .details-more {
        display: flex;
        flex-direction: row;
        margin-top: 6px;
        .circle {
          margin-top: 8px;
          width: 10px;
          height: 10px;
          border-radius: 3px;
          margin-right: 10px;
        }
        .number {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 26px;
          color: #212121;
        }
      }

    }
    .investigator-widget-show-complete {
      @extend %investigator-widget-show;
    }
    .investigator-widget-show-canceled {
      @extend %investigator-widget-show;
    }
  }
}

@media (min-width: 950px) {
  .total-encounter {
    font-weight: 400;
    font-size: 18px;
    .total-encounter-amount {
      font-size: 48px !important;
    }
  }
}
