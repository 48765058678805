@import "src/scss/includes";

.tooltip-missing-status {
  width: 285px;
  .rc-tooltip-content {
    .rc-tooltip-arrow {
      width: 10px;
      height: 10px;
      background: $white;
      border: none;
      transform: rotate(45deg);
      box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
    }
    .rc-tooltip-inner {
      div {
        margin: 5px;
        font-size: 14px;
      }
      button {
        margin: 20px 5px 5px 5px;
      }
    }
  }
}

.progress-item-wrapper {
  display: flex;
  min-width: 153px;
  flex-grow: 1;
  flex-direction: column;
  .basic-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    height: 24px;
    .basic-line {
      min-width: 80px;
      flex-grow: 1;
      height: 0;
      border-bottom: 1px solid $green100;
      &.first {
        display: none;
      }
      &.not_happened {
        border-bottom: 1px dashed $gray30;
      }
      &.skipped{
        border-bottom: 1px dashed $orange100;
      }
      &.skipped.optional {
        border-bottom: 1px dashed $gray30;
      }
      &.current {
        border-bottom: 1px solid $green100;
      }
      &.previous_skipped {
        border-bottom: 1px dashed $orange100;
      }
      &.previous_skipped.previous_optional {
        border-bottom: 1px dashed $gray30;
      }
      &.previous_optional{
        border-bottom: 1px dashed $gray30;
      }
      &.previous_optional.previous_happened {
        border-bottom: 1px solid $green100;
      }
      &.previous_optional.not_happened {
        border-bottom: 1px dashed $gray30;
      }
      &.previous_optional.skipped.not_happened.previous_happened{
        border-bottom: 1px dashed $orange100;
      }
      &.previous_optional.skipped.optional.not_happened {
        border-bottom: 1px dashed $gray30;
      }
      &.previous_failed, &.failed, &.previous_optional.previous_happened.failed {
        border-bottom: 1px dashed $red70;
      }
    }
    .basic-dot {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10px;
      height: 10px;
      margin: 0 24px 0 24px;
      border-radius: 100%;
      background-color: $green100;
      position: relative;
      &.not_happened {
        background-color: $gray30;
      }
      &.skipped {
        width: 24px;
        height: 24px;
        background-color: $orange100;
        cursor: pointer;
        &.optional {
          width: 10px;
          height: 10px;
          background-color: $gray30;
          cursor: auto;
          &:after{
            content: "";
          }
        }
        &:after{
          content: "!";
          color: $white;
          font-size: 19px;
        }
      }
      &.skipped.optional {
        width: 10px;
        height: 10px;
        cursor: auto;
        &:after{
          content: "";
        }
      }
      &.current {
        width: 24px;
        height: 24px;
        background-color: $green100;
        &:after{
          width: 10px;
          height: 9px;
          content: "";
          background-image: url("../../../../../../images/icons/history-current.svg") ;
          color: $white;
          font-size: 19px;
        }
      }
      &.failed {
        width: 24px;
        height: 24px;
        background-color: $red70;
        &:after{
          width: 8px;
          height: 8px;
          content: "";
          background-image: url("../../../../../../images/icons/history-failed.svg");
          color: $white;
          font-size: 19px;
        }
      }

    }
    .basic-text {
      width: 150px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 30px;
      span{
        &:first-child {
          font-size: 16px;
          line-height: 22px;
          color: $gray85;
        }
        &:last-child {
          font-size: 12px;
          line-height: 22px;
          color: $gray50;
        }
      }
      &.not_happened {
        span {
          &:first-child {
            color: $gray50;
          }
        }
      }
      &.skipped {
        top: 38px;
        span{
          &:first-child, &:last-child {
            color: $orange100;
          }
        }
      }
      &.skipped.optional {
        top: 30px;
        span {
          &:first-child {
            color: $gray50;
          }
          &:last-child {
            color: $gray50;
          }
        }
      }
      &.current {
        top: 38px;
        span {
          &:first-child {
            color: $gray85;
          }
        }
      }
    }
  }
}