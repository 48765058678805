@import 'src/scss/includes';
.task-worklist-filter {
  display: flex;
  flex-flow: wrap;
  > div,
  .apply-button {
    margin: 0 15px 0 0;
  }
  .eds-select {
    max-width: 200px;
  }
}

.task-worklist-table.ReactTable {
  .rt-tr {
    &.highlight {
      background-color: $purple20;
      &:hover {
        background-color: $purple20 !important;
      }
    }
  }
}

.status-edit-cell {
  padding-left: 3px !important;
  position: relative;
  .current-status-cell {
    padding-top: 3px;
    padding-left: 8px;
    font-size: 14px;
    top: 4px;
    position: absolute;
    width: 120px;
    line-height: 28px;
    &.active {
      outline: 1px solid $blue50;
      background-color: $white;
    }
  }
  button {
    float: right;
    &:focus {
      outline: none;
    }
  }

  .glyphicon-edit {
    margin-right: 2px;
  }
  .material-icons {
    font-size: 15px;
    margin-left: 4px;
    border: 1px solid;
    border-radius: 55px;
    height: 24px;
    width: 24px;
  }
}
.search-field-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;

  .task-worklist-search-icon {
    position: absolute;
    left: 15px;
    font-size: 22px;
    top: 8px;
  }
  .task-worklist-search-field {
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 50px;
    width: 416px;
    height: 50px;
    padding-left: 50px;
    font-size: 16px;
  }
  .task-worklist-search-field::placeholder {
    color: $black !important;
    font-size: 14px;
    opacity: 1;
  }
  .task-worklist-search-field:focus {
    outline: none !important;
    border: 2px solid $purple75;
    box-shadow: 0 0 5px $purple75;
  }
}
