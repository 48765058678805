@import "src/scss/includes";
.sub-element-modal-wrap {
  .error-msg-small {
    color:$red100;
    font-size: 0.6rem;
  }

  .popScroll {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .flable {
    border-bottom: 1px solid $gray10;
    padding-bottom: 15px;
  }

  .controllTerm{
    height: 100px;
    overflow: auto;
    width: 400px;
    border: 1px solid $gray15;
    padding:5px;
  }
}

