@import "src/scss/includes";

.milestone-events-for-week {
  width: 100%;
  position: absolute;
  z-index: 4;
  padding-left: 53px;
  padding-right: 2px;

  .milestone-events-container {
    width: 100%;
    position: relative;

    .day-container {
      width: 100%;
      position: absolute;
      z-index: 4;
      top: 42px;

      &.one-day {
        top: -12px;
      }
      .rc-menu-button{
        width: 100%;
        border-radius: 4px;
        border: 1px solid transparent;
        color: $white;
        background-color: $blue100;
        padding: 0 10px;
        height: 28px;
      }

      .rc-menu__item {
        padding-right: 20px;
        &.active{
          background-color: $gray7;
          color: $purple80;
        }
      }

      .event {
        width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: $blue100;
        border: 1px solid $white;
        border-radius: 4px;
        padding: 0 10px;
        color: $white;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.active {
          box-shadow: rgba($black, 0.14) 0 6px 10px 0, rgba($black, 0.12) 0 1px 18px 0, rgba($black, 0.2) 0 3px 5px -1px;
          border: 1px solid transparent;
          background-color: darken($blue100, 15%);
        }
      }

      .rc-menu-container {
        width: 100%;
      }

      .rc-menu-button {
        justify-content: space-between;
        display: flex;
        line-height: 27px;
        border: 1px solid $white;
        span{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        i{
          font-size: 22px;
        }
      }
      .rc-menu{
        margin-top: 1px;
        &.open{
          max-height: 400px;
          overflow-y: auto;
          width: max-content;
          max-width: 200px;
          display: block!important;
          opacity: 1;
          .rc-menu__item {
            display: block !important;
          }
        }

      }
    }
  }
}
.chrome {
  padding-right: 11px;
}