@use 'sass:math';
@import "src/scss/includes";

.eui-divider-horizontal {
  border-bottom: 1px solid $gray15;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}
.eui-divider-vertical {
  border-left: 1px solid $gray15;
  position: relative;
  top: math.div(.125em, -2);
  box-sizing: border-box;
  display: inline-block;
  height: 1.125em;
  margin: 0 8px;
  vertical-align: middle;
}
