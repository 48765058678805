@import "src/scss/includes";
.eds-loader {
  height: 50px;
  width: 190px;
  position: relative;

  .eds-loader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -35px;
    width: 50px;
    height: 10px;
    background: $white;
    border-radius: 5px;
    animation: load 1.8s ease-in-out infinite;

    &:before,
    &:after {
      position: absolute;
      display: block;
      content: '';
      animation: load 1.8s ease-in-out infinite;
      height: 10px;
      border-radius: 5px;
    }

    &:before {
      top: -20px;
      left: 10px;
      width: 40px;
      background: $red100;
    }

    &:after {
      top: 20px;
      width: 40px;
      background: $purple80;
    }
  }

  &.eds-loader-dark {
    .eds-loader-icon {
      background: $gray15;
    }
  }

  @keyframes load {
    0% {
      transform: translateX(40px);
    }
    50% {
      transform: translateX(-30px);
    }
    100% {
      transform: translateX(40px);
    }
  }
}
