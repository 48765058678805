@import "src/scss/includes";

.study-budget {
  flex-direction: column;
  display: flex;
  height: calc(100vh - 141px);
  overflow: hidden;

  .page-info-header {
    padding: 0 16px 16px 16px;
    .page-info {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }

  .add-section {
    border: 1px solid $gray15;
    margin: 0 0 5px 0;

    h5 {
      padding-left: 1rem;
      padding-top: 0.5rem;
      margin-bottom: 0;
    }

    .general-header-wrapper {
      border: none;
      padding: 0 20px;
    }
  }

  .study-budget .add-section .filter-section {
    margin: 0;
  }

  .study-budget .add-section .filter-section .filter-prefix {
    width: 130px;
  }

  .study-budget .add-section .filter-section .pt-3 {
    padding-top: 34px !important;
  }

  .study-budget .add-section .description {
    width: 350px;
    position: absolute;
    top: 35px;
    height: 48px;
  }

  .study-budget .add-section .description-cont {
    width: 430px;
    position: absolute;
    top: 35px;
  }

  .study-budget .add-section .description-cont span {
    float: left;
  }

  .study-budget .add-section .description-cont .add-button {
    float: left;
    margin-top: 29px;
    height: 35px;
    margin-left: 10px;
  }

  .study-budget .add-section .description-cont input {
    width: 350px;
    height: 35px;
  }
}

@media (min-width: 768px) {
  .study-budget .add-section .description-cont {
    left: 339px;
  }
}

@media (min-width: 1367px) {
  .study-budget .add-section .description-cont {
    left: 379px;
  }
}

.study-budget .add-section .description input {
  height: 36px;
}

.study-budget .existing-section .filter-section {
  margin: 0;
}

.study-budget .existing-section .filter-section .row .form-row {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.study-budget .existing-section .filter-section .filter-prefix {
  width: 130px;
}

.eui-input.description-width {
  input {
    width: 300px;
  }
}

.study-budget .existing-budgets {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem;
  margin: 16px;
  border: 1px solid $gray15;
  height: inherit;

  .general-header-wrapper {
    border: none;
    padding: 0;
    margin: 0;
  }

  .comments-column {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
