@import "src/scss/includes";
.erv-control {
  border-top: 2px solid #2465C6;
  background-color: #DCEAFF;
  flex: 0 0 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 2;
  .erv-control-left {
    font-weight: 600;
    padding-left: 33px;
    position: relative;
    line-height: 120%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:before {
      content: ' ';
      width: 20px;
      height: 20px;
      display: block;
      vertical-align: middle;
      background-color: #2465C6;
      margin-right: 13px;
      mask: url("erv-control-icon.svg" + "#pending");
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 0;
    }
  }
  .erv-control-right {
    display: flex;
    .eds-review-history{
      margin: 0 10px;
    }

    .eui-btn {
      border-color: #DBDBDB;
      span.eui-btn-text {
        color: #575757;
      }
    }
  }
  &.erv-control-signed {
    border-top-color: #27AE60;
    background-color: #EDFFF4;
    .erv-control-left {
      &:before {
        background-color: #27AE60;
        mask: url("erv-control-icon.svg" + "#signed");
      }
    }
  }
  &.erv-control-previous {
    border-top-color: #FFC738;
    background-color: #FFF9C7;
    .erv-control-left {
      &:before {
        background-color: #FFC738;
        mask: url("erv-control-icon.svg" + "#previous");
      }
    }
  }
}
