@import "src/scss/includes";

%container {
  margin: 0 10px 0 20px;
  padding: 10px 10px 10px 16px;
  border-left: 1px solid $gray15;
}
%question-name {
  font-weight: bold;
  margin-bottom: 16px;
  font-style: italic;
  margin-right: 10px;
}
%common-answer-and-reason {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 1px 3px 0px #0000004D;
  box-shadow: 0px 2px 9px 0px #0000004D;
  position: relative;
  min-height: 80px;
  margin-top: 10px;
}
%section-title {
  font-weight: 500;
  line-height: 24px;
}
%reason-answer-name {
  line-height: 24px;
}
%reason-answer-section {
  flex-grow: 1;
  width: 33.3%;
  display: flex;
  flex-direction: column;
}

.ae-log-history{
  .link{
    display: flex;
    color: $darkBlue100;
    cursor: pointer;
    height: 35px;
    line-height: 35px;
    margin-left: 10px;
  }

  .logs-table {
    padding-left: 40px;
    margin-bottom: 40px;
  }

  .status-row{
    display: flex;
  }

  .cancel-reason-container {
   @extend %container;

    .question-name {
      @extend %question-name;
    }

    .cancel-reason {
      @extend %common-answer-and-reason;
      padding-left: 20px;

      .cancel-reason-title {
        @extend %section-title;
      }
      .reason-name {
        @extend %reason-answer-name;
      }
      .reason-section {
        @extend %reason-answer-section;
      }
    }
  }
  .question-answer-container {
    @extend %container;

    .question-name {
      @extend %question-name;
    }

    .answers {
      @extend %common-answer-and-reason;
      padding-left: 44px;

      &:before {
        content: '';
        width: 4px;
        height: calc(100% - 20px);
        background-color: #7d3559;
        position: absolute;
        left: 16px;
        border-radius: 10px;
      }

      &.highlighted{
        background-color: $yellow10;
      }

      .answer-section {
        @extend %reason-answer-section;
      }

      .section-title {
        @extend %section-title;
      }

      .answer-name {
        @extend %reason-answer-name;
      }
      .answer-type {
        line-height: 24px;
      }

      .created-status-section {
        flex-grow: 0.05;
      }

    }

  }

  .signature-container {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 6px;

    div{
      margin-right: 10px;
    }
  }

  .status-container {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 8px;
    div{
      margin-right: 10px;
    }
  }

}

.signature-container {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 6px;

  div{
    margin-right: 10px;
  }
}

.ae-log-row-signatures-history-modal{
  .eds-modal-box-container{
    display: flex;
    flex-direction: column;
    height: 75%;
    .eds-modal-box-body{
      overflow-y: auto;
      height: 100%;
      .logs-table {
        .column-pages {
          flex-wrap: nowrap!important;
        }
      }
    }
  }
}

.cross-line {
  text-decoration: line-through;
}
