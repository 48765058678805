.eds-review-content {
  .eds-review-item-group-element {
    .eds-top-item-group {
      padding-bottom: 48px;
      display: flex;
      justify-content: space-between;
      line-height: 34px;

      > .d-flex {
        padding-top: 16px;
      }

      .review-item-group-element-confirm-button {
        font-weight: bold;
        font-size: 1rem;
        min-width: 134px;
        text-align: left;
        padding: 6px 12px;
        .material-icons {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
  }
}
