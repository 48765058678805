@import "src/scss/includes";
.studySiteDetails {
  .greytxt{
    color:$gray50;
  }

  .checkboxes {
    display: flex;
    justify-content: space-between;
  }

  .custummsg {
    color: $purple75;
    font-weight: 600;
    border: 1px dotted;
    padding: 8px;
  }

  .add-btn-grey {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 4px $gray50 solid;
    float: right;
    margin-right: 5px;
    color: $gray50;

    i {
      font-size: 15px;
      font-weight: 700;
      float: left;
      margin-top: 1px;
      margin-left: 1px;
    }
  }
}
