@import "src/scss/includes";

.encounters-section-wrapper {
  margin: 0;
  padding-top: 10px;
  background-color: transparent;

  .encounters-section {
    background-color: $gray2;
    border: 1px solid $gray10;
    border-radius: 0;
    color: $gray85;

    .encounters-section-header {
      background-color: $white;
      padding: 20px 20px 0 20px;
      position: relative;
      color: inherit;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $gray10;

      .encounters-section-header-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray5;
        padding-bottom: 16px;
        margin-bottom: 16px;
        h2 {
          padding-right: 30px;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 0.02em;
          color: $black;
          margin: 0;
        }
      }
      .encounters-section-header-tabs {
        margin-bottom: -1px;
        display: flex;
        .encounters-section-header-tabs-group {
          button {
            background-color: transparent;
            border: 1px solid $gray10;
            outline: none;
            padding: 8px 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.02em;
            color: inherit;
            &:first-child {
              border-top-left-radius: 8px;
            }
            &:last-child {
              border-top-right-radius: 8px;
            }
            &:first-child:not(:last-child) {
              border-right: none;
            }
            &:last-child:not(:first-child) {
              border-left: none;
            }
            &.payment-tab-border {
              border-right: 1px solid $gray10 !important;
            }
            &.active-tab {
              color: $white;
              background-color: $purple80;
              font-weight: 500;
            }
          }
          + .encounters-section-header-tabs-group {
            margin-left: 150px;
          }
        }
      }
    }
  }
}
