@import '~rc-tooltip/assets/bootstrap.css';
@import "src/scss/includes";

.eds-rc-tooltip{
  .rc-tooltip-arrow{
    display: none;
  }
  &.rc-tooltip{
    opacity: 1;
    &.over-modal{
      z-index: 1121;
      .rc-tooltip-inner{
        max-width: 300px;
        max-height: 500px;
        overflow: auto;
      }
    }
    .rc-tooltip-inner{
      box-shadow: 0 0 20px rgba(99, 117, 139, 0.35);
      border: none;
      padding: 12px;
      background-color: $white;
      color: $gray85;
    }
  }
}
