@import "src/scss/includes";

.get-domain-detail-elements{
  display: flex;
  flex-direction: column;
  position: absolute;
  height: calc(100% - 110px);
  overflow-y: auto;
  width: 100%;

  >section{
    display: flex;
    flex-direction: column;
    height: inherit;
    position: relative;
    .cancel-button{
      position: sticky;
      left: calc(100% - 65px);
      top: 40px;
      z-index: 1;
      color: $gray85;
    }
    .eds-item-group-body {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .ae-history-link {
      display: flex;
      align-items: center;
      color: $darkBlue100;
      i {
        font-size: 25px;
        margin-right: 4px;
      }
    }
  }
}
