.worklist-filters {
  display: flex;
  flex-direction: column;

  .general-header-wrapper {
    > div {
      margin-bottom: 0;
    }

    .eds-select-validation-message {
      display: none;
    }
  }

  .finance-start-end-date-selector-wrapper {
    margin-top: 10px;
  }
}
