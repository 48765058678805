@import "src/scss/includes";

.subject-id-column {
  width: 100%;
  .subject-id-is-hidden {
    color: $gray30;
  }
  .subject-id-field {
    display: flex;
    align-items: center;
    input {
      border: 0;
      border-bottom: 1px solid transparent;
      height: 20px;
      line-height: 20px;
      background-color: transparent;
      outline: 0 none;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .subject-id-field-buttons {
      width: 52px;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      .subject-id-field-button-edit {
        opacity: 0;
      }
    }
    &.subject-id-field-edit {
      input {
        border-color: $gray10;
        &:focus {
          border-color: $purple75;
        }
      }
    }
  }
}

.rt-td {
  &:hover {
    .subject-id-column {
      .subject-id-field {
        .subject-id-field-buttons {
          .subject-id-field-button-edit {
            opacity: 1;
          }
        }
      }
    }
  }
}
