@import "src/scss/includes";

.reconcile-table-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  .reconcile-payments-table.ReactTable {
    border: none;
    margin: 16px 25px;
    flex-grow: 1;
    overflow: hidden;
    .align-amount-end {
      text-align: end;
    }
    .rt-thead {
      border-bottom: solid 1px rgba(0,0,0,0.05);
      color: black;
      .rt-th{
        border: none;
      }
    }
    .rt-tbody {
      overflow-y: auto;
      .rt-tr-group{
        flex: 0;
      }
      .rt-tr {
        .rt-td {
          border: none;
          .reconcile-input {
            height: 24px;

            label {
              height: 24px;
              input {
                margin-top: 2px;
                margin-bottom: 0;
                font-size: 12px;
              }

              .eui-input-face {
                border: 1px solid $gray30;
                border-radius: 3px;
                background-color: white !important;
              }
            }
          }
        }
      }
    }

  }
  .eui-btn-group {
    justify-content: flex-end;
    margin: 0 35px 25px 43px;
  }

  .reconcile-amount-paid {
    width: 100%;
    box-sizing: border-box;
    padding: 9px 0 10px 16px;
    height: 24px;
    font-size: 12px;
    border: 1px solid #B7B7B7;
    border-radius: 3px;
    outline: none;

    &::placeholder {
      color: $black !important;
      opacity: 0.6;
    }
  }
}

.eds-modal-box.reconcile-payment-modal {
  .eds-modal-box-body {
    display: flex;
    flex-direction: column;
    .reconcile-answer {
      margin-bottom: 21px;
    }
    .reconcile-information {
      display: flex;
      flex-direction: column;
      >div {
        display: flex;
        margin-bottom: 12px;
        >span {
          min-width: 150px;
        }
        .count {
          font-weight: 600;
        }
      }
    }
  }
}

