@import "src/scss/includes";

.edc-visit-date-button{
  position: relative;
  cursor: pointer;
  display: flex;

  .rc-menu{
    max-height: 450px!important;
    width: 280px;
    margin-left: -20px;
  }
  .rc-menu__item{
    padding: 0!important;
  }

  .menu-button{
    width: 150px;
  }

  .eui-input{
    input{
      min-width: 110px;
    }
  }

  .dropdown-container{
    background-color: $white;
    padding: 15px;
    min-width: 280px;
    left: 0;

    .rdtPicker{
      border: none;
      padding: 0;
      width: 100%;
    }
    .hint{
      border-radius: 4px;
      background-color: $yellow10;
      padding: 10px;
      color: $black;
      font-size: 15px;
      margin-top: 5px;
    }
  }
}
