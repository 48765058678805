@import "src/scss/includes";

.logs-page{
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 126px);
  top: 90px;
  background-color: $white;

  .log-check{
    display: flex;
    >div{
      width: 100%;
    }
  }

  .content-section button {
    float: right;
  }

  .sign-button {
    position: absolute;
    right: 30px;
    margin-top: -35px;
  }

  .link-text {
    color: $blue100;
    text-decoration: underline;
    cursor: pointer;
  }

  .back-button{
    display: flex;
    height: 40px;
    line-height: 40px;
    position: absolute;
  }

  .absolute-section{
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
  }

}

.cross-line {
  text-decoration: line-through;
}
