@import "src/scss/includes";

.eds-modal-box.edit-custom-option-list-modal {
  .new-option-list {
    display: flex;
    align-items: center;
    .new-option-input {
      width: 400px;
      margin: 0 30px;
    }
  }
  .new-option-label {
    font-size: 17px;
    min-width: 200px;
  }
}
