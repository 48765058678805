.eds-modal-box.role-switcher-confirm {
  .eds-modal-box-container {
    .eds-modal-box-body {
      .eui-form-inputs {
        flex-direction: column;
        .eui-radio-button {
          margin: 0 0 8px;
        }
      }
    }
    .eds-modal-box-footer {
      justify-content: flex-start;
    }
  }
}
