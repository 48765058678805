@import "src/scss/includes";
.erc-not-performed {
  > p{
    font-weight: 400;
    > span{
      font-weight: 600;
    }
  }
  & :last-child{
    margin-bottom: 0;
  }
}
