@import "src/scss/includes";

.informed-consent {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .icf-info-bar {
    display: flex;
    width: 100%;

    &.yellow {
      background-color: $yellow10;
    }

    &.highlighted {
      &:before {
        content: '';
        display: block;
        background-color: $red70;
        font-size: 16px;
        width: 6px;
        border-radius: 10px;
        margin-right: 10px;
      }
    }

    .icf-info-bar-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .icf-info-bar-new {
        color: $red70;
        font-size: 16px;
      }
      .icf-info-bar-actions {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        align-items: center;
      }
    }
  }

  .icf-completed-bar {
    border-radius: 6px;
    display: flex;
    width: 100%;
    box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);
    -webkit-box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);
    -moz-box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);

    &:before {
      content: '';
      display: block;
      background-color: $purple80;
      font-size: 16px;
      width: 6px;
      border-radius: 10px;
      margin-right: 10px;
    }
    .auto-size-container {
      display: flex;
      max-width: fit-content;
      flex-direction: column;
    }
    .icf-completed-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;

      div, strong {
        display: flex;
        min-width: 170px;
      }
      .single-file-container {
        margin-right: 32px;
      }
    }
  }
}