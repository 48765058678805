.pih-show-more {
  position: relative;
  cursor: pointer;
  text-decoration: underline;
  padding: 0 15px;
  line-height: 18px;
  white-space: nowrap;
  .hidden-items {
    display: none;
    background-color: white;
    position: absolute;
    right: 0;
    top: 100%;
    padding: 10px;
    z-index: 1000;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 3px;
    > * {
      display: block;
      padding: 5px 0;
    }
  }
  &:hover {
    .hidden-items {
      display: block;
    }
  }
}
