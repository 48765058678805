@import "src/scss/includes";

.date-field {
  position: relative;

  .eui-input.eui-input-legacy .eui-input-icons-after {
    margin-left: 20px;
  }

  .delete-date-icon {
    position: absolute;
    color: $gray30;
    width: 30px;
    text-align: center;
    right: 42px;
    font-size: 24px;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    top: 3px;

    &:hover {
      color: $gray60;
    }
  }
}
