@import "src/scss/includes";

.add-new-item-group {
  display: flex;
  justify-content: flex-end;

  .igl-add {
    &.rc-menu, .rc-menu {
      padding: 0;

      .rc-menu__item {
        font-size: 16px;
        line-height: 25px;
        padding: 11px 15px;

        &.add-new-link {
          padding: 0;

          a {
            color: $black;
            width: 100%;
            padding: 11px 15px;
          }
        }

        &.igt-sub {
          span {
            margin-right: 10px;
          }

          &:after {
            right: 0;
            left: inherit;
          }
        }
      }

      .submenu-group {
        overflow: auto;
        max-height: 450px;
      }

      .igl-search-template {
        padding: 0;
        .eui-input {
          width: 100%;
        }
      }

      .igl-item-domain {
        font-weight: 600;
        width: 270px;
        position: relative;

        .igl-icon {
          position: absolute;
          right: 0;
          color: $gray60;
          font-size: 24px;
        }
      }

      .igl-template {
        padding: 0;
        width: 270px;

        a {
          width: 100%;
          padding: 11px 15px 11px 29px;
          color: black;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
