@import "src/scss/includes";

.tooltip-table-setup-alert {
  .rc-tooltip-arrow {
    width: 10px;
    height: 10px;
    background: $white;
    border: none;
    transform: rotate(45deg);
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
  }
}

.field-item-wrapper.custom {
  background-color: $blue10;
  .delete-option-item {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-image: url("../../../../../../../../images/icons/delete-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  .column.big {
    .custom-field-reminder {
      display: flex;
      justify-content: center;
    }
    .custom-field-checkbox {
      display: flex;
      justify-content: center;
      .eui-input-selectors.eui-checkbox {
        pointer-events: none;
        height: 18px;
        span{
          em {
            border-color: $gray60;
          }
        }
      }
    }
    .conditional-logic-alert {
      color: orange;
      font-size: 22px;
      margin-left: 20px;
    }
  }
  .column.short {
    flex-wrap: wrap;
    .eui-input-selectors-without-label, .delete-option-item  {
      margin: 3px;
    }
  }
}
