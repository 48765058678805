@import "src/scss/includes";


.scheduling-work-list{
  .chips-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }
  .general-header-group-container{
    .eds-select{
      margin-bottom: 0;
    }
  }
  .custom-placeholder-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow:hidden;
    width:100%;
    display:block;
    padding-right: 45px;
  }
  .eds-select .r-ss-wrap .r-ss-trigger {
    color: black;
    padding-right: 36px!important;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .placeholder{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow:hidden;
    }
    .filter-count{
      background-color: $purple80;
      color: $white;
      border-radius: 20px;
      line-height: 20px;
      padding-left: 6px;
      padding-right: 8px;
      min-width: 20px;
      height: 20px;
    }
  }
}
