@import "src/scss/includes";

.etc-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid $gray15;
  min-height: 70px;
  .eui-btn-group{
    .eui-btn{
      border-radius: 20px;
      border: none;
    }
  }
}
