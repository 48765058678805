@import "src/scss/includes";

.protocol-groups-list {
  flex: 0 0 261px;
  padding-right: 25px;
  overflow-y: auto;
  padding-bottom: 25px;

  .protocol-groups-list-title {
    padding-bottom: 14px;
    font-weight: bold;
    font-size: 14px;
  }

  > ul.protocol-groups-list-items {
    padding: 0;
    margin: 0;

    list-style-type: none;

    > li {
      height: 46px;
      line-height: 46px;
      padding: 0 16px;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;

      &.protocol-groups-list-item--active {
        background: $gray5;
        mix-blend-mode: darken;
      }
    }
  }
}
