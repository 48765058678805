@import "src/scss/includes";

.invoice-chip {
  font-family: $ffRoboto;
  display: flex;
  width: max-content;
  align-items: center;
  background-color: $gray10 ;
  height: 25px;
  border-radius: 25px;
  padding: 2px 10px;
  line-height: 14px;
  .invoice-chip-value {
    margin-left: 4px;
  }
  .close-chip-icon {
    cursor: pointer;
    margin-left: 0.5rem;
    font-weight: bold;
    color: $gray60;
  }
}
