@import "src/scss/includes";
.eds-review-content {
  flex: 1 1;
  overflow: auto;

  .eds-review-item-group-element {
    background-color: $white;
    display: inline-block;
    width: calc(100% - 30px);
    margin: 8px 15px;
    .review-element-data-loader {
      margin: 40px;
      display: flex;
      justify-content: center;
    }
  }


  .eds-review-item-group-element-table-reflection {
    width: 100%;

    .logs-table {
      .rt-noData{
        top: 85px;
      }
      .rt-td {
        min-height: 44px;
        height: auto;
        i {
          z-index: 1;
        }
      }
    }

    .table-cell-answer-layout{
      text-align: left!important;
    }

    .edited-link {
      font-style: oblique;
      text-decoration: underline;
      color: $purple80;
      cursor: pointer;
    }

    .encounter-name-value {
      font-weight: bold;
    }

    .log-title {
      color: $purple80;
      font-weight: bold;
      padding-left: 16px;
      padding-bottom: 16px;
    }
  }

  .change-bullet {
    position: relative;
    &:after {
      content: ' ';
      background-color: $red100;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 0px;
      right: -8px;
    }
  }

  .answer-required-attention-bg {
    background-color: $red10;
  }

  .new-not-signed-data-bg {
    background-color: $yellow10;
  }

  .edited-data-after-sign-bg {
    background-color: $yellow10;
  }

  .rt-tr{
    &:hover{
      .edited-data-after-sign-bg {
        background-color: inherit;
      }
      .answer-required-attention-bg {
        background-color: inherit;
      }
      .new-not-signed-data-bg {
        background-color: inherit;
      }
    }
  }

  .brand-link {
    font-style: oblique;
    text-decoration: underline;
    color: $purple80;
    cursor: pointer;
  }
}

.log-item-group-adverse-event-history-modal{
  .eds-modal-box-container{
    display: flex;
    flex-direction: column;
    height: 75%;
    .eds-modal-box-body{
      overflow-y: auto;
      height: 100%;
    }
  }
}