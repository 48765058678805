@import "src/scss/includes";

%skeletonCover {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.protocol-groups-list-skeleton {
  flex: 0 0 261px;
  padding-right: 25px;

  .protocol-groups-list-skeleton-title {
    width: 100px;
    height: 25px;
    margin-top: -2px;
    margin-left: -10px;
    @extend %skeletonCover;
  }

  ul.protocol-groups-list-skeleton-items {
    margin-top: 26px;
    padding-left: 5px;

    > li {
      width: 200px;
      height: 20px;
      list-style: none;
      margin-bottom: 26px;
      @extend %skeletonCover;
    }
  }
}


@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
