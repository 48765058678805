@import "src/scss/includes";
.log-check-items {
  padding-bottom: 48px;
  .log-check-item {
    border-bottom: 1px solid $gray10;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    .log-check-question {
      padding: 15px;
      width: 60%;
      display: flex;
      align-items: center;
      .log-check-np-item {
        display: flex;
        .log-check-np-header {
          margin-right: 4px;
          color: $purple75;
          font-weight: 700;
        }
        .log-check-np-reason {
          font-weight: 600;
        }
      }

      .log-check-question-value {
        color: $gray85;

        span {
          font-weight: 100;
          font-size: 13px;
          color: $gray70;
        }
      }
    }

    .log-check-answer {
      padding: 15px;
      text-align: center;
    }

    &:hover {
      background-color: $gray2;
    }
  }
}

.log-check-title {
  padding-left: 16px;
  color: $purple80;
  font-weight: bold;
}

.log-check-data-about {
  padding-bottom: 16px;

  span {
    padding: 16px;
    display: inline-flex;
  }
}

