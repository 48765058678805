@import "src/scss/includes";

.r-ss-wrap.ern-review-log-history {
  border-radius: 0;
  min-width: 126px;
  .r-ss-trigger {
    display: flex;
    border-color: #DBDBDB !important;
    color: #575757;
    padding-right: 26px;
    padding-left: 9px;
    font-size: 14px;
    border-radius: 4px;
    height: 28px;
    line-height: 22px;
    font-weight: 600;
    .carat {
      border-width: 5px;
      margin-right: 0;
      bottom: auto;
      top: 50%;
      margin-top: -1.5px;
      right: 9px;
      &.up {
        border-color: transparent transparent #575757 transparent;
        border-top-width: 0;
      }
      &.down {
        border-color: #575757 transparent transparent transparent;
        border-bottom-width: 0;
      }
    }
    b {
      margin-right: 5px;
    }
  }
  .r-ss-dropdown {
    background: $white;
    border: 1px solid $gray10;
    box-shadow: 0 0 34px rgba($black, 0.2);
    width: 650px;
    position: absolute;
    right: -20px;
    border-radius: 5px;
    max-height: none;
    .r-ss-options-wrap {
      max-height: 400px;
      .r-ss-dropdown-options {
        max-height: none;
        .r-ss-option-group-heading {
          border: 0 none;
          background-color: transparent;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: rgba($gray50, .8);
          padding: 22px 19px 19px 19px;
          margin-top: 0;
        }
        .r-ss-dropdown-option {
          border-top: 1px solid $gray10;
          border-bottom: 0 none;
          padding: 12px 52px 12px 19px;
          font-size: 14px;
          line-height: 24px;
          .review-log-data{
            display: flex;
            justify-content: space-between;
            margin: 0 -3px;
            > div {
              padding: 0 3px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              &:nth-child(1) {
                flex: 3 3;
              }
              &:nth-child(2) {
                flex: 5 5;
              }
              &:nth-child(3) {
                flex: 3 3;
                min-width: 150px;
              }
              &:nth-child(4) {
                flex: 3 3;
                min-width: 150px;
              }
            }
          }
          .reason{
            background-color: #FFF9C7;
            padding: 10px;
            border-radius: 4px;
            font-weight: normal!important;
            color: $gray60;
            line-height: 14px;
          }
          &:focus, &:hover {
            border-left: 0 none;
            border-right: 0 none;
            border-bottom: 0 none;
            background-color: $gray2;
          }
          &.r-ss-selected {
            background: transparent;
            font-weight: bold;
            &:after {
              content: 'done';
              font-family: $ffMaterialIcons;
              font-weight: normal;
              font-style: normal;
              font-size: 24px;
              line-height: 1;
              letter-spacing: normal;
              text-transform: none;
              display: inline-block;
              white-space: nowrap;
              word-wrap: normal;
              direction: ltr;
              -webkit-font-feature-settings: 'liga';
              -webkit-font-smoothing: antialiased;
              position: absolute;
              right: 14px;
              top: 50%;
              margin-top: -12px;
            }
          }
        }
      }
    }
  }
}
