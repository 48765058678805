@import "src/scss/includes";
.protocol-setup-encounter {
  .incorrect-protocol-encounter {
    background-color: $red70;
  }

  .protocol-encounter {
    background-color: $black
  }

  .non-protocol-encounter {
    background-color: $blue100;
  }
}

.enmargin {
  width: 66px!important;
  height: 45px!important;
  display: inline-block;
  float: left;

  .enboxgrey {
    width: 33px!important;
    height: 32px!important;
    display: inline-block;
    float: left;
  }

  .protocol-box-overlay {
    position: absolute;
    width: 32px;
    height: 34px;
    z-index: -1;
    bottom: 18px;
    left: 15px;

    i {
      color: $white;
      top:2px;
      font-size: 10px;
      text-indent: 1px;
      width: 10px;
      float: right;
      position: absolute;
      right:4px;
    }
  }
}


