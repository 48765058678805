@import "src/scss/includes";

.eds-modal-box.element-history-log-check-modal {
  .eds-modal-box-container {
    .logs-table {
      .rt-th {
        div {
          align-items: center;
        }
      }

      .eds-review-history-changed-cell {
        background: $yellow10;
      }

      .rt-tr {
        &:hover {
          .eds-review-history-changed-cell {
            background-color: inherit;
          }
        }
      }

      .history-log-cell, .tool{
        text-align: left;
        justify-content: left;
      }
    }
  }
}
