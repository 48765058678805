.erc-when-was-it-done-and-who-did-it {
  padding: 15px 0 16px 0;
  display: flex;

  .eds-select.eds-select-type-default > .r-ss-wrap > .r-ss-trigger.r-ss-disabled {
    border-color: #F3F3F3;
  }

  * {
    cursor: not-allowed !important;
  }

  > div {
    margin-right: 25px;
  }
}

