.eds-finance-table {
  .ReactTable.missed-events-table {
    flex: 1 0;
    overflow: auto;
    &.met-disabled {
      .rt-table {
        pointer-events: none;
      }
    }

    .rt-tbody {
      overflow-y: scroll;
    }

    .rt-thead {
      padding-right: 10px;

      > .rt-tr {
        > .rt-th {
          padding-top: 4px;

          &:first-child {
            opacity: 1;
          }

          &:last-child {
            margin-right: 20px;
          }
        }
      }
    }

    .rt-td {
      &:last-child {
        margin-right: 20px;
      }

      > p {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .met-right-level {
      display: flex;
      justify-content: flex-end;
    }

    .met-left-level {
      display: flex;
      justify-content: flex-start;
    }
  }

  .checkbox-status {
    pointer-events: none;
  }
}
