@import "src/scss/includes";

.encounter-search-section {
  position: relative;
  padding: 0 25px;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;

  .search-input {
    width: 219px;
    height: 28px;
    label {
      width: 219px;
      height: 28px;
      input {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 12px;
      }
      .eui-input-face {
        border: 1px solid $gray10;
        border-radius: 20px;
        background-color: white !important;
      }

      .eui-input-prefix, .eui-input-icons-after {
        padding: 5px 5px 7px 9px;
        .eui-icon {
          font-size: 19px;
        }
      }
      .eui-input-icons-after {
        cursor: pointer;
        color: $gray60;
        .eui-icon {
          margin-top: -5px;
          margin-right: -10px;
        }
      }
    }
  }
  .search-results {
    width: 490px;
    height: 400px;
    background: $white;
    position: absolute;
    z-index: 100;
    right: 25px;
    top: 36px;
    box-shadow: 0 2px 9px rgba(5, 5, 5, 0.1), 0 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    .search-tabs-wrapper {
      display: flex;
      align-items: baseline;
      .search-tabs {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 16px;
        margin: 16px 8px 8px 8px;
      }
      .tab-element {
        display: flex;
        align-items: baseline;
        justify-content: center;
        min-width: max-content;
        height: 24px;
        padding: 0 2px;
        cursor: pointer;
        font-size: 14px;
        color: $gray70;
        &.selected {
          border-bottom: 1px solid $gray70 ;
        }
        .tab-elements-count {
          background-color: $gray7;
          padding: 3px 6px;
          font-weight: 500;
          margin-left: 8px;
          border-radius: 4px;
          line-height: 10px;
          font-size: 12px;
        }
      }
    }

    .search-result-content {
      display: flex;
      flex-direction: column;
      height: 330px;
      overflow-y: auto;
      padding: 0 16px;
      .search-part {
        padding-top: 8px;
        margin-top: 8px;
        border-top: 1px solid $gray7;
        .search-part-header {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          line-height: 14px;
          color: $gray70;
          span {
            font-weight: 600;
            color: $darkBlue100;
            cursor: pointer;
          }
        }
        .search-part-element {
          border-radius: 4px;
          color: $gray85;
          font-size: 14px;
          line-height: 25px;
          display: flex;
          padding-left: 9px;
          cursor: pointer;
          .truncated{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          a {
            color: $gray85;
            display: flex;
          }
          .found {
            font-weight: 600;
          }
          &:hover {
            color: $darkBlue100;
            background-color: $gray5;
          }
        }
      }
      .eds-loader {
        align-self: center;
        height: 100%;
        min-height: 50px;
      }
      .no-results-message {
        align-self: center;
        margin: auto 0;
      }
    }
  }
}

@media (max-width: 990px) {
  .encounter-search-section {
    .search-results {
      right: -40px;
    }
  }
}
