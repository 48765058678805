@import "src/scss/includes";

.eui-notifications {
  .notifications-bell-container{
    position: relative;
    cursor: pointer;
    .notifications-counter-circle{
      border-radius: 20px;
      min-height: 20px;
      padding: 3px;
      color: $white;
      background-color: $red100;
      min-width: 20px;
      text-align: center;
      font-size: 10px;
      position: absolute;
      top: -5px;
      right: -10px;
      z-index: 1;
    }

    .eds-icon-btn{
      color: $white;
      font-size: 30px;
      &:hover:not(:disabled){
        color: $white;
        opacity: 0.8;
      }
      &:focus{
        outline: none;
      }
    }
  }

  .notifications-dialog {
    position: absolute;
    width: 500px;
    background-color: white;
    border-radius: 4px;
    top: 50px;
    right: 5px;
    z-index: 33324;
    -webkit-box-shadow: 0 0 12px 6px rgba($black,0.15);
    -moz-box-shadow: 0 0 12px 6px rgba($black,0.15);
    box-shadow: 0 0 12px 6px rgba($black,0.15);
    display: flex;

    .notifications-container{
      color: black;
      overflow-y: auto;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      border-radius: 4px;
      background-color: $gray2;
      height: 500px;
      .notifications-container-header{
        font-size: 18px;
        border-bottom: 1px solid $gray15;
        line-height: 36px;
        justify-content: space-between;
        display: flex;
      }

      .notifications-scrollable-container{
        overflow-y: auto;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        .notification-element{
          border: 1px solid $gray15;
          border-radius: 3px;
          background-color: white;

          .notification-header{
            display: flex;
            justify-content: space-between;

            .notification-date{
              color: $gray70;
              font-size: 13px;
            }
            .notification-read-status{
              background-color: $purple80;
              width: 15px;
              height: 15px;
              border-radius: 20px;
              cursor: pointer;
              &.read{
                background-color: $gray10;
              }
            }
          }
          .copy-text{
            font-weight: bold;
          }
        }
        .notifications-default-message{
          display: flex;
          flex-grow: 1;
          justify-content: center;
          align-items: center;
        }
      }
      .notifications-container-footer{
        text-align: center;
      }
    }
    .link{
      color: $purple80;
      cursor: pointer;
      font-size: 14px;
      &:hover{
        text-decoration: underline;
      }
    }
    .notifications-mark-read{
      color: $purple80;
      font-size: 14px;
      padding: 10px 10px 0 10px;
      min-height: 36px;
      text-align: right;
      >span{
        cursor: pointer;
      }
    }
  }
}
.eds-rc-tooltip.notification-tooltip{
  z-index: 10000;
  .rc-tooltip-inner{
    white-space: nowrap;
  }
}