@import "src/scss/includes";

.eds-calendar-date-time-picker {
  position: relative;
  width: 100%;
  max-width: 300px;

  .year-select {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 17px 17px 17px;

    .left{
      display: flex;
    }

    .right{
      display: flex;
      padding-top: 2px;
    }

    .year-select-container {
      position: static !important;
      background-color: transparent;
      min-width: 100px;

      .r-ss-wrap {
        position: static !important;
      }

      .r-ss-trigger {
        border: none !important;
        line-height: 20px;
        font-weight: bold;
        color: $gray85;
        padding-right: 30px;
        position: relative;
        background-color: transparent;
        height: 28px;

        .carat {
          border: 5px solid transparent;
          margin-right: 4px;

          &.down {
            bottom: 6px;
            border-color: $gray85 transparent transparent transparent;
          }

          &.up {
            bottom: 14px;
            border-color: transparent transparent $gray85 transparent;
          }
        }
      }

      .r-ss-dropdown {
        top: 45px;
        width: calc(100% - 30px);
        left: 15px;
        border: 1px solid transparent;
        -webkit-box-shadow: 0 0 12px 0 rgba($black,0.25);
        box-shadow: 0 0 12px 0 rgba($black,0.25);
        background: transparent;
        border-radius: 2px;
        .r-ss-options-wrap {
          background-color: $gray2;
          min-height: 230px;
          border-radius: 2px;
          .r-ss-dropdown-options {
            display: flex;
            flex-wrap: wrap;
            padding: 10px;

            .r-ss-dropdown-option {
              flex-basis: 25%;
              text-align: center;
              border: none;
              display: inline-block;
              border-radius: 3px;
              padding: 0;

              &:focus {
                background-color: transparent;
              }

              .year-option {
                padding: 3px 0;
                line-height: 25px;

                &.current-year {
                  background-color: $gray85;
                  color: $white;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }
    }
  }

  .eds-date-time {
    .rdtPicker {
      width: 100%;
      max-width: 300px;
      padding: 14px;
      min-width: 256px;
    }

    &.view-mode-days{
      tfoot{
        display: none;
      }
    }

    &.view-mode-time{
      .rdtSwitch{
        display: none;
      }
    }

    &.inline{
      .rdtDays {
        position: relative;
        table {
          thead {
            tr {
              height: 36px;
              width: 100%;
              th {
                border: none;
                line-height: 21px;
                &.rdtNext {
                  display: none;
                }
                &.rdtPrev {
                  display: none;
                }
                &.rdtSwitch {
                  display: none;
                }
              }
            }
          }
        }
      }
      tfoot {
        display: none;
      }
    }

    .rdtMonth.rdtActive{
      background-color: $gray85;
      border-radius: 3px;
    }
    .rdtYear.rdtActive{
      background-color: $gray85;
      border-radius: 3px;
    }

    .rdtDay {
      line-height: 27px;

      &:hover {
        .dateWithLabel {
          &:before {
            background-color: $white;
          }
        }
        .dateWithUnderscore {
          &:before {
            background-color: $white;
          }
        }
      }

      &.rdtToday {
        background: none;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          border: 2px solid $purple80 !important;
          width: 100%;
          right: 0;
          border-radius: 4px;
          height: 100%;
        }

        div {
          background: $purple80 !important;
          color: $white;
        }

        &:hover {
          div {
            background: $gray30 !important;
            border-radius: 0;
            color: $white;
          }
        }
      }

      &.rdtActive {
        background: none !important;

        &:hover {
          div {
            background: $gray30 !important;
          }
        }

        &.rdtToday {
          div {
            background-color: $purple80;
            border-radius: 0;
            color: $white;
          }
        }

        &.highlight {
          div {
            background: $gray10;
            border-radius: 3px;
            text-shadow: none;
          }
        }

        div {
          background: $gray10;
          border-radius: 3px;
          text-shadow: none;
          color: $black;
        }

        .dateWithLabel {
          &:before {
            background-color: $white;
          }
        }
        .dateWithUnderscore {
          &:before {
            background-color: $white;
          }
        }
      }

      &.highlight {
        background-color: $gray10 ;

        &:hover {
          div {
            background: $gray30 !important;
          }
        }
      }

      div {
        margin: 1px 1px 1px 0;

        &:hover {
          background: $gray30 !important;
          border-radius: 3px;
          color: $black;

        }
      }

      .dateWithLabel {
        position: relative;
        &:before {
          content: ' ';
          position: absolute;
          border-radius: 6px;
          right: 3px;
          top: 3px;
          height: 4px;
          width: 4px;
          background-color: $gray85;
        }
      }

      .dateWithUnderscore {
        position: relative;
        &:before {
          content: ' ';
          position: absolute;
          border-radius: 23px;
          height: 3px;
          right: 5px;
          bottom: 3px;
          width: 19px;
          background-color: $gray85;
        }
      }
    }
  }
}


