@import "src/scss/includes";
.eds-modal-box.eds-missing-item-dialog {
  .eds-modal-box-container {
    .eds-modal-box-body {
      padding: 30px 46px 0;
      max-height: 508px;
      overflow-y: auto;
      .eds-missing-item-apply-to-all {
        border-bottom: 1px solid $gray10;
        margin-bottom: 30px;
      }
    }

    .eds-modal-box-footer {
      justify-content: space-between;
      padding: 31px 26px 29px 44px;
      .eds-missing-item-info {
        align-items: center;
        display: flex;
        span.material-icons {
          color: $green80;
          transform: rotate(180deg);
        }

        .eds-missing-item-warning-message {
          font-size: 16px;
          line-height: 150%;
          margin-left: 12px;
        }
      }
    }

  }
}
