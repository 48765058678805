@import "src/scss/includes";

.encounters-section-content {
  min-height: 100px;
  overflow: auto;
  padding: 2px 20px 0 20px;

  .no-data-yet {
    line-height: 24px;
    text-align: center;
    margin: 38px auto;
    color: $gray60;
  }

  .encounter-container:first-child {
    margin: 12px 0;
  }

  .encounter-container {
    margin: 30px 0;
  }

  > .eui-btn-group {
    padding-bottom: 20px;
  }
}
