.table-item-group-header {
  display: flex;
  position: sticky;
  bottom: 0;
  margin-bottom: 0;
  background-color: white;
  padding: 15px;
  z-index: 100;

  .eds-btn-group {
    flex-grow: 1;
    display: flex;
  }

  .table-item-group-header-field {
    display: flex;
    align-items: center;
    margin-right: 16px;

    label {
      padding-right: 16px;
      margin: 0;
    }

    .eds-select {
      max-width: 208px;
    }

    .eui-date-picker {
      max-width: 160px;
    }
  }
}
