@import "src/scss/includes";
.eds-modal-box.add-or-edit-patient-info-modal {
  .eds-modal-box-container {
    .eds-modal-box-body {
      height: 600px;
      overflow: auto;
      .top-selectors {
        padding-left: 0;
        padding-right: 10px;
        .aoepim-study-site-status-source-select {
          flex-direction: column;
          > div {
            display: flex;
            > div {
              flex: 1 1;
              padding-bottom: 10px;

              &:first-child {
                padding-right: 30px;
              }
            }
          }
        }
      }
      > .row {
        > div {
          .country-description {
            color: $gray50;
            font-size: 0.9em;
          }
          &.apm-form-label {
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
            color: $gray85;
            padding-bottom: 30px;
            display: flex;
            align-items: center;
            .edit-patient-modal-finance-details-header {
              padding: 0 20px 11px 0;
            }
          }
          &:not(.apm-form-label) {
            padding-bottom: 10px;
          }
        }
        &:not(:first-child) {
          > div.apm-form-label {
            padding-top: 25px;
          }
        }
      }
    }
  }
}
.eds-modal-boxes {
  .eds-modal-box.eds-modal-box-confirm.add-patient-study {
    .eds-modal-box-container {
      .eds-modal-box-body{
        ul {
          padding: 0 0 0 18px;
        }
      }
    }
  }
}
