@import "src/scss/includes";
$roll-in-out-animation-time: 1s;
$roll-in-out-animation-delay: 1s;
$relative-width: 303px;

body{
  overflow-x: hidden;
}

@keyframes move-from-right {
  0% {
    right: -430px;
  }
  100% {
    right: 0;
  }
}

.full-screen-layout {
  .floating-panel-show {
    animation: move-from-right 0.0s;

    & + .content-panel{
      margin-right: 350px;
    }
  }
}

@keyframes move-to-right {
  0% {
    right: 0;
  }
  100% {
    right: -430px;
  }
}



.full-screen-layout {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
  top: 0;
  display: flex;

  .content-panel{
    height: 100%;
    display: flex;
    position: relative;
    flex-grow: 1;
    padding: 10px 15px 15px 0;
    &.showPanelSpace{
      margin-right: 350px;
    }
  }

}


.navigation-flex-container{
  height: 100%;
  $navigation-max-width: 308px;
  $extended-with-for-overflow: 325px;
  $navigation-min-width: 35px;
  $transition-time: 0.2s;
  $transition-type: linear;
  transition: width $transition-time $transition-type;
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: $navigation-min-width;
  z-index: 8;

  &.closed{
    .round-button{
      background: $purple80;
      .round-button-item{
        color: $white;
      }
    }

    .navigation-overlap-wrapper{
      overflow-y: hidden;
    }

    .navigation-overlap-wrapper+button{
      transition: left $transition-time $transition-type;
      left: 3px;
    }
  }

  &.opened{
    width: $extended-with-for-overflow;

    .navigation-body{
      width: $extended-with-for-overflow;
      @keyframes delay-overflow {
        from{
          overflow-x: initial;
          overflow-y: initial;
        }
        to {
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
      animation: 1s delay-overflow;
      .navigation-overlap-wrapper {
        width: $navigation-max-width;
        transition: left $transition-time $transition-type, background 0s linear 0s;
        left: 0;
        min-height: 100%;
        height: 100%;
        overflow-y: auto;
        &+button{
          transition: left $transition-time $transition-type;
          left: 292px;

        }
      }
    }
  }

  .round-button{
    position: absolute;
    top: 140px;
    right: -14px;
    $round-button-edge: 28px;
    height: $round-button-edge;
    width: $round-button-edge;
    border-radius: 50%;
    border:none;
    outline: none;
    z-index: 1;
    background: $white;
    -webkit-box-shadow: 0 1px 5px 0 rgba($black,0.29);
    box-shadow: 0 1px 5px 0 rgba($black,0.29);
    &:hover {
      background: $purple80;
      .round-button-item{
        color: $white;
      }
    }
    .round-button-item{
      position: absolute;
      top: 3px;
      left: 3px;
      color: $purple80;
      font-size: 22px;
    }

    .round-button-item:hover{
      color: $white;
    }
  }

  .navigation-body{
    position: relative;
    z-index: 5;
    background:none;
    width: 295px;

    .navigation-overlap-wrapper{
      position: absolute;
      left: -290px;
      height: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      transition: left $transition-time $transition-type;
      width: $navigation-max-width;
      min-height: 100%;
      background: $gray2;

      &.preview{
        width: $navigation-max-width;
        background: $gray2;
        left: 0;
        transition: left $transition-time $transition-type;
        overflow-y: auto;
        &+button{
          left: 292px;
        }
      }
    }
  }

  .navigation-panel {
    min-width: 290px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
