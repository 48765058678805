@import "src/scss/includes";

.projection-revenue .ReactTable .rt-th,
.projection-revenue .ReactTable .rt-td {
  white-space: normal;
  &.align-amount-end {
    text-align: end;
  }
}

.projection-revenue .btn {
  height: 35px;
  margin: 29px 0 0 5px;
}
.pr-buttons{
  align-items: flex-end;
  padding-bottom: 15px;
  padding-left: 5px;
}
