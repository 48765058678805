@import "src/scss/includes";
.eds-file-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black,.8);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  .eds-file-viewer-header {
    background-color: $gray85;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 10px 20px rgba($black,0.19), 0 6px 6px rgba($black,0.23);
    z-index: 1;
    .eds-icon-btn {
      font-size: 26px;
      padding: 10px 15px;
      color: rgba($white, .7);
      transition: all .2s ease 0s;
      &:hover {
        color: $white;
        background-color: $black;
      }
    }
  }
  .eds-file-viewer-container {
    flex: 1;
    overflow: auto;
    position: relative;
    .eds-loader {
      position: absolute;
      top: 30%;
      margin-top: -25px;
      left: 50%;
      margin-left: -95px;
    }
  }
}
