.trlf-filters{
  display: flex;
  flex-direction: column;
  .trlf-all-inputs-and-button{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    > div {
      width: 100%;
    }
    .trlf-radio-inputs {
      flex-wrap: wrap;
      display: flex;
      .trlf-radio {
        flex-wrap: wrap;
        padding-right: 61px;
        display: flex;
        > span {
          font-weight: 500;
          margin-right: 18px;
        }
      }
    }
  }
}
