@import "src/scss/includes";

.eds-modal-box.edit-statuses-popup {
    .eds-modal-box-body {
      display: flex;
      justify-content: space-between;
      .edit-statuses-category {
        &:first-child {
          flex: 1 1 57%;
          padding-right: 35px;
        }
        &:last-child {
          flex: 1 1 43%;
          padding-left: 35px;
        }
        .status-item > span {
          margin-right: 10px;
        }
        .eui-toggle {
          flex-direction: row-reverse;
          flex: 1;
          justify-content: flex-end;
          .eui-toggle-label {
            padding: 0 0 0 10px;
          }
        }

        p {
          font-weight: bold;
          margin-top: 10px;
        }
        .status-item {
          height: 44px;
          border-bottom: 1px solid $gray15;
          display: flex;
          align-items: center;
          &:last-child {
            border: none;
          }
          .status-name {
            flex-grow: 1;
          }
          .eui-checkbox > span{
            color: $gray30;
          }
        }
      }
  }
}
