.page-info-header .page-info .left-section h1 span a.patient-profile-header-link {
  color: #106EEC;
  text-decoration: none;
}

.eds-patient-info {
  display: flex;
  height: calc(100vh - 54px - 34px - 37px - 96px);
  .eds-patient-info-left {
    width: 390px;
    height: 100%;
    overflow: auto;
  }
  .eds-patient-info-right {
    flex: 1;
    padding: 0 24px;
    height: 100%;
    overflow: auto;
  }
}

@media (min-width: 100px) {
  .eds-patient-info .eds-patient-info-left{
    width: 350px;
  }
}
@media (min-width: 1280px) {
  .eds-patient-info .eds-patient-info-left{
    width: 390px;
  }
}
