@import "src/scss/includes";

.validate-code-definition-changes {
  .eds-modal-box-body {
    max-height: 700px;
    min-height: 100px;
    overflow: auto;

    .column-headers {
      display: flex;

      div {
        flex: 50%;
        padding: 10px;
        text-align: center;
        font-weight: bold;
      }
    }

    .changed-values-group {
      .changed-values-group-header {
        text-align: center;
        background-color: $gray15;
      }

      .changed-values-group-body {
        display: flex;

        .column {
          flex: 50%;
          padding: 10px;

          ul {
            text-align: center;
            list-style-type: none;
            padding-left: 0;
          }
        }

      }
    }
  }
}
