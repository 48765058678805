.rc-menu-container {
  .rc-menu__item {
    > a {
      color: inherit;
    }

    &.rc-menu__item--disabled {
      cursor: not-allowed;
      > a {
        pointer-events: none;
      }
    }

    &.bold-text {
      font-weight: bold;
      width: 200px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      white-space: normal;
      word-wrap: break-word;
      cursor: pointer;
      overflow: hidden;
      max-height: 3.9em;
    }
  }
}
