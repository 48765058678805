@import "src/scss/includes";
.rie-not-performed{
  padding: 15px;
  box-shadow: none;
  border-top: 1px solid $gray5;
  font-weight: 600;
  >span{
    color: $purple75;
  }
}
