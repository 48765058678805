@import "src/scss/includes";

.user-section {
  position: relative;
  display: flex;
  margin-left: 20px;
  .user-description {
    color: $white;
    height: 47px;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    align-items: flex-end;
    font-size: 14px;
    line-height: 15px;
    padding: 8.5px 35px 0 8.5px;
    cursor: pointer;
    user-select: none;
    .user-description-active-role {
      font-weight: bold;
    }
    &:after {
      content: "";
      position: absolute;
      top: 21px;
      right: 14px;
      color: $white;
      border-top: 4px solid;
      border-bottom: none;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
    &:hover{
      background-color: var(--environment-extra-color-1, $purple75);
    }
  }
  .user-dialog {
    display: none;
    -webkit-box-shadow: 0 0 12px 6px rgba($black,0.15);
    -moz-box-shadow: 0 0 12px 6px rgba($black,0.15);
    box-shadow: 0 0 12px 6px rgba($black,0.15);
    position: absolute;
    background-color: $white;
    width: 100%;
    top: 100%;
    color: $gray50;
    min-width: 280px;
    right: 10px;
    margin-top: 4px;
    padding: 5px 0;
    .user-dialog-roles {
      padding: 5px 0;
      .user-dialog-roles-title {
        font-weight: bold;
        font-size: 12px;
        color: $gray70;
        line-height: 150%;
        padding-right: 15px;
        padding-left: 15px;
      }
      .user-dialog-roles-list {
        color: $black;
        .user-dialog-roles-item {
          font-size: 14px;
          line-height: 150%;
          padding: 9px 15px;
          cursor: pointer;
          position: relative;
          &.user-dialog-roles-item-selected {
            font-weight: bold;
            &:after {
              font-size: 16px;
              position: absolute;
              right: 20px;
              top: 50%;
              margin-top: -10px;
              line-height: 20px;
              content: 'done';
              display: block;
              font-family: $ffMaterialIcons;
              font-weight: normal;
              font-style: normal;
              letter-spacing: normal;
              text-transform: none;
              white-space: nowrap;
              word-wrap: normal;
              direction: ltr;
            }
          }
          &:hover{
            background-color: rgba($gray60, 0.11);
          }
        }
      }
      + .user-dialog-preferences, + .user-dialog-btn {
        border-top: 1px solid $gray15;
      }
    }
    .user-dialog-preferences {
      padding: 10px 15px;
      .user-dialog-preference {
        .user-dialog-preference-toggle {
          color: $black;
          font-weight: bold;
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between;
        }
      }
      + .user-dialog-btn {
        border-top: 1px solid $gray15;
      }
    }
    .user-dialog-btn {
      color: $purple80;
      font-weight: bold;
      cursor: pointer;
      padding: 12px 15px;
      width: 100%;
      display: flex;
      &:hover{
        background-color: rgba($gray60, 0.11);
      }
      &.link{
        border-bottom: 1px solid $gray15;
      }
    }
  }
  &.active {
    .user-description {
      background-color: var(--environment-extra-color-1, $purple75);
    }
    .user-dialog {
      display: block;
    }
  }
}
