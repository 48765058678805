@import "src/scss/includes";

.edc-visit-date{
  position: relative;
  cursor: pointer;
  display: flex;

  .eui-input{
    input{
      min-width: 110px;
    }
  }

  .dropdown-container{
    -webkit-box-shadow: 0 0 12px 6px rgba($black,0.15);
    -moz-box-shadow: 0 0 12px 6px rgba($black,0.15);
    box-shadow: 0 0 12px 6px rgba($black,0.15);
    position: absolute;
    background-color: $white;
    top: 100%;
    z-index: 11;
    padding: 15px;
    min-width: 280px;
    left: 0;

    .rdtPicker{
      border: none;
      padding: 0;
      width: 100%;
    }
    .hint{
      border-radius: 4px;
      background-color: $yellow10;
      padding: 10px;
      color: $black;
      font-size: 15px;
      margin-top: 5px;
    }
  }
}
