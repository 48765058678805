@import "src/scss/includes";

.invoice-email-modal.eds-modal-box {
  font-family: $ffRoboto;
  .eds-modal-box-header {
    font-weight: 500;
  }
  .eds-modal-box-body {
    display: flex;
    flex-direction: column;
    padding: 27px;
    font-family: $ffRoboto;
    .email-setup-row {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .email-setup-row-label {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: $gray50;
      }
      .toggle-to-button {
        font-size: 16px;
      }
    }
    .attached-file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 45px;
      background-color: $gray5;
      color: $gray50;
      &.first-pdf {
        margin-top: 30px;
      }
    }
    .email-textarea {
      width: 100%;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 12px;
      border: none;
      background: transparent;

      &:hover {
        border: none;
      }

      &:focus {
        border: none;
      }

      &:focus-visible {
        outline: 0;
      }
    }
  }
  .eds-modal-box-footer {
    justify-content: space-between;
    align-items: center;
    .available-size {
      margin-left: 15px;
    }
    .attach-file-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
