@use 'sass:math';
@import "src/scss/includes";

.collapsible-list {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  > div {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    &.shadow-items {
      visibility: hidden;
    }
  }
}
