@import "src/scss/includes";
.erv-review-content {
  flex: 1 1;
  overflow: auto;
  z-index: 1;

  .erc-element {
    .review-element-data-loader {
      margin: 40px;
      display: flex;
      justify-content: center;
    }
  }


  .erc-item-group-element-table-reflection {
    width: 100%;

    .logs-table .column-pages .custom-title {
      font-size: 14px;
    }

    .logs-table .rt-td {
      min-height: 44px;
      height: auto;
    }

    .table-cell-answer-layout{
      text-align: left!important;
    }

    .edited-link {
      color: #2465C6;
      cursor: pointer;
      text-decoration: none;
      font-style: inherit;
      font-weight: 600;
    }

    .encounter-name-value {
      font-weight: bold;
    }
  }

  .change-bullet {
    position: relative;
    &:after {
      content: ' ';
      background-color: $red100;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 0px;
      right: -8px;
    }
  }

  .answer-required-attention-bg {
    background-color: #DEE8F7;
  }

  .new-not-signed-data-bg, .edited-data-after-sign-bg{
    background-color: #FFF9C7;
    &.procedure-comment, &.erc-new-table-comment{
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      max-height: 1200px;
    }
    &.erc-new-table-q {
      background-color: transparent;
      .erc-question-answer {
        background-color: #FFF9C7;
      }
    }
    &.erc-item-group-procedure-q {
      background-color: transparent;
      .erc-question-answer {
        background-color: #FFF9C7;
      }
    }
  }

  .rt-tr{
    &:hover{
      .edited-data-after-sign-bg {
        background-color: inherit;
      }
      .answer-required-attention-bg {
        background-color: inherit;
      }
      .new-not-signed-data-bg {
        background-color: inherit;
      }
    }
  }

  .brand-link {
    color: #2465C6;
    cursor: pointer;
    text-decoration: none;
    font-style: inherit;
    font-weight: 600;
  }
}
