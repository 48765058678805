@import "src/scss/includes";
.erv-comment-section {
  width: 100%;
  display: inline-block;
  .edit-comment-area {

    .comments-author {
    font-weight: bold;
    font-size: 13px;
    padding: 10px 0 5px 15px;
    color: $gray70;

    span {
      font-weight: 100;
      font-size: 13px;
      color: $gray70;
    }
  }
  .comment {
    background-color: $gray5;
    border-radius: 10px;
    padding: 15px;
    margin-right: 82px;
    margin-bottom: 23px;
    max-height: 1050px;
    overflow-y: auto;
  }
    .comments-area {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;

      .textarea-layout {
        flex: 1;
        width: 100%;
        > .eds-textarea {
          > label {
            > textarea {
              margin-bottom: 0;
              padding-bottom: 15px;
              max-height: 1050px;
              overflow-y: auto;
            }
          }
        }
      }

      .button-layout {
        display: flex;
        align-items: flex-end;
        margin-left: 20px;
        .eui-btn {
          border-color: #DBDBDB;
          span.eui-btn-text {
            color: #575757;
          }
        }
      }
    }
  }
}
