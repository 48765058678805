@import "src/scss/includes";

.eds-calendar-custom-toolbar {
  display: flex;
  max-height: 56px;

  .label {
    color: $gray85;
    line-height: 28px;
    font-size: 24px;
    min-width: 220px;
  }
  .eui-btn.menu-button{
    width: 140px;
    text-align: left;
    margin-right: 15px;
  }
  .rc-menu__item.active{
    background-color: $gray10;
  }
  i{
    z-index: 1!important;
  }

  .eds-select.time-zone-dropdown {
    height: 28px;
    z-index: 6;

    .r-ss-wrap {
      .r-ss-trigger {
        height: 28px;
        border-color: $gray15;
        &:hover {
          background-color: $gray7;
        }
        .carat {
          bottom: 5px !important;
        }
        .r-ss-selection-clear {
          top: 10px;
        }
      }
    }
    .eds-select-label{
      top: 12px;
      font-size: 16px;
      font-weight: bolder;
      color: $purple80;
    }
    .label {
      font-size: 12px;
      line-height: 18px;
      color: $gray70;
    }
    &.eds-select-not-empty {
      .eds-select-label {
        display: none;
      }
      .r-ss-wrap {
        .r-ss-trigger {
          border-radius: 5px;
          padding-bottom: 4px;
          padding-top: 4px;
          padding-right: 35px;
          .r-ss-selected-label {
            font-weight: bolder;
            color: $purple80;
            font-size: 14px;
          }
        }
      }
    }
  }
}
