.widget-calendar-appointments{
  .rc-menu__item{
    flex-direction: column;
    align-items: start!important;
  }
  .text-align-right{
    text-align: right;
  }
  .eds-calendar {
    height: calc(100% - 60px - 30px)!important;
  }
  .rbc-event {
    border-radius: 9px;
    border-width: 3px;
  }
  .eds-calendar-big .no-wrap {
    display: block;
  }
  .rc-menu{
    max-height: 300px;
    overflow-y: auto;
  }
}