@import "src/scss/includes";

.appointments-rows {
  padding-top: 16px;
  padding-bottom: 4px;

  .appointments-rows-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 6px;
  }

  .appointments-out-of-window {
    margin-bottom: 6px;
    margin-left: 4px;
  }

  .appointments-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;

    > div {
      line-height: 20px;

      &.event-start-date {
        width: 250px;
        padding-right: 20px;
        font-size: 14px;
        color: $gray70;
        font-weight: normal;
      }

      &.checkin-button {
        width: 115px;
        > div {
          margin: -3px 0;
        }
      }

      &.appointment-comments {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }

    &.cancelled {
      .event-start-date {
        text-decoration: line-through;
      }
    }

    &:not(.cancelled):hover {
      .control-buttons {
        .cancel-appointment-btn {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
