@import "src/scss/includes";

.eui-chip{
  background-color: white;
  border: 1px solid $gray15;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  margin-bottom: 16px;

  .values{
    max-width: 150px;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .close-chip{
    cursor: pointer;
    font-weight: bold;
    color: $gray60;
  }
  >div{
    &:first-child{
      margin-right: 5px;
    }
  }
}

.eui-chip-label{
  background-color: white;
  border: 1px solid $gray15;
  padding: 5px 15px 5px 15px;
  border-radius: 20px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  margin-bottom: 16px;

  .values{
    max-width: 150px;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .close-chip{
    cursor: pointer;
  }
}