@import "src/scss/includes";

.optional-item-groups-setup-table-cell {
  background-color: $green10;
  width: 89px;
  height: 44px;
  cursor: pointer;
  line-height: 44px;
  text-align: center;
  .spinner-border {
    border: 2px solid currentColor;
    border-right-color: transparent;
    width: 20px;
    height: 20px;
  }
  &:before{
    color: $white;
  }
  &:hover{
    background: lighten($green80, 15%);
    &:before{
      content: "Exclude";
    }
  }
  &.optional-item-groups-setup-table-cell-exclude {
    background: $green80;
    &:hover{
      background: lighten($green80, 15%);
    }
    &:before{
      content: "Exclude";
    }
  }
  &.optional-item-groups-setup-table-cell-loading, &.optional-item-groups-setup-table-cell-not-editable{
    pointer-events: none;
  }
}
