@import "src/scss/includes";
.eds-question-answer {
  border-bottom: 1px solid $gray10;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  .eds-question {
    padding: 15px;
    width: 60%;
    display: flex;
    align-items: center;

    .question {
      color: $gray85;

      span {
        font-weight: 100;
        font-size: 13px;
        color: $gray70;
      }
    }
  }

  .eds-answer {
    padding: 15px;
    text-align: center;

    .answer {
      font-size: 15px;
      font-weight: bold;
      bottom: 0;
      height: 100%;
      display: inline;

      &.not-answered {
        font-style: italic;
        font-weight: 100;
      }

      .answer-file {
        font-weight: normal;
        color: $purple75;
        cursor: pointer;
        font-style: italic;
        text-decoration: underline;

        &:not(:last-child) {
          padding-right: 10px;
        }
      }
    }
  }

  &:hover {
    background-color: $gray2;
  }
}
