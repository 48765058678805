@import "src/scss/includes";
.erl-item-group-label {
  padding-top: 16px;
  padding-left: 16px;
  font-weight: bold;
  font-size: 1rem;
  color: $purple75;
  display: flex;
  line-height: 34px;

  .material-icons-outlined {
    color: $gray60;
    font-size: 20px;
    z-index: 1;
  }

  > div, a {
    color: inherit;
    margin-left: 5px;
  }

  > p {
    padding-left: 5px;
    font-weight: normal;
  }
}
