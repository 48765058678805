@import "src/scss/includes";

.eds-modal-box.eds-sign-certify-copy-modal-box {
  .eds-modal-box-container {

    .eds-modal-box-body {
      padding-top: 5px;
      background-color: $white;
      max-height: 600px;
      overflow: auto;

     .ccsm-certify-copy-files-box {
       margin: 20px 0;
        .ccsm-certify-copy-files {
          display: flex;
          flex-flow: row wrap;

          .ccsm-certified-label {
            line-height: 38px;
            margin-bottom: 0;
            font-size: 15px;
            font-weight: bold;
          }

          .ccsm-certify-copy-file {
            margin: 0 3px;
            font-size: 15px;
            display: flex;

            .ccsm-file-upload-icon {
              font-size: 24px;
            }

            .ccsm-upload-file-name {
              line-height: 38px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
