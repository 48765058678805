@import "src/scss/includes";

.eds-create-invoice-table.ReactTable {
  display: flex;
  flex-direction: column;
  font-family: $ffRoboto;
  width: 100%;
  border: none;
  overflow: auto;
  .rt-thead.-header {
    border: none;
    box-shadow: 0 5px 15px -13px rgba($black, 0.4);
    padding-right: 10px;
    .rt-th {
      border: none;
      font-size: 12px;
      font-weight: 500;
      color: $gray85;
      line-height: 18px;
      padding: 11px;
      &.amount-value{
        text-align: end;
      }
    }
  }
  .rt-tbody {
    display: flex;
    overflow-y: scroll;
    .rt-tr-group {
      flex: 0 0 auto;
      border: none;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      .rt-tr {
        &:hover {
          background-color: transparent !important;
        }
        .rt-td {
          border: none;
          padding: 6px 11px;
          &.amount-value{
            text-align: end;
          }
          .eui-icon {
            cursor: pointer;
          }
        }
      }
    }
  }
}
