@import "src/scss/includes";

.erc-overview-title {
  display: flex;
  justify-content: space-between;

  .overview-history-link {
    color: #2465C6;
    text-decoration: none;
    font-style: inherit;
    font-weight: 600;
    line-height: 26px;
    cursor: pointer;
    > svg {
      margin-right: 7px;
    }
  }
}
