@import "src/scss/includes";
.eds-icon-btn.eds-round-icon-btn {
  padding: 6px;
  color: $blue100;
  border-radius: 50%;
  background-color: $white;
  border: 1px solid $gray15;
  line-height: 16px;
  width: 16px;
  height: 16px;
  box-sizing: content-box;
  &:hover:not(:disabled) {
    background-color: $purple50;
    border: 1px solid $purple75;
    color: $white;
  }
}
