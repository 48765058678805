@import "src/scss/includes";
.revenue-adjustment{
  border: 1px solid $gray10;
  background: $gray2;
  box-sizing: border-box;
  margin: 0 23px 13px 23px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 139px - 67px);
}
