@import "src/scss/includes";

.eds-find-patient-popup {
  position: absolute;
  width: 830px;
  max-height: 392px;
  background-color: #ffffff;
  top: 10px;
  right: -100px;

  border-radius: 4px 4px 0 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1490196078), 0 2px 9px 0 rgba(0, 0, 0, 0.1490196078);

  display: flex;
  flex-direction: column;

  .eds-find-patient-popup-input {
    position: relative;
    background-color: #f6ecf1;
    border-bottom: 1px solid #DBDBDB;
    border-radius: 4px 4px 0 0;
    flex: 0 0 55px;
    margin: 0;
    > input {
      height: 30px;
      margin-top: 20px;
      background-color: transparent;
      border: 0 none;
      display: block;
      width: 100%;
      outline: none;
      padding: 0 16px;
    }
    > span {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      padding-left: 16px;
      padding-top: 5px;
      margin: 0;
      color: #691E44;
      font-size: 12px;
    }
    > i.eds-find-patient-popup-input-clear-btn {
      opacity: 0.25;
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      right: 16px;
      cursor: pointer;
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
      line-height: 1;
      content: url("../../../../../../../images/icons/cancel-clear-button.svg");
      top: 35px;
      margin-top: -10px;
      color: initial;
      &:hover {
        opacity: 1
      }
    }
  }
  .eds-find-patient-popup-tabs {
    display: flex;
    color: rgba(0,0,0,.6);
    font-weight: 500;
    border-bottom: 1px solid #c7c7c7;
    margin: 0 16px;
    .eds-find-patient-popup-tab {
      padding: 10px 16px 6px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      margin-bottom: -1px;
      user-select: none;
      &.eds-find-patient-popup-tab-active {
        color: #691E44;
        border-bottom: 2px solid #691E44;
      }
    }
  }
  .eds-find-patient-popup-recent-notice {
    padding: 15px 16px;
    height: 42px;
    box-shadow: 0 3px 9px -6px $aero80;
  }
  .eds-find-patient-popup-list {
    overflow: auto;
    .eds-loader {
      margin: 20px auto;
    }
    .eds-find-patient-popup-list-no-result {
      padding: 10px 16px;
      font-style: italic;
    }
  }
}
