@import "src/scss/includes";
.eds-item-group-data-form {
  width: 100%;
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .eds-highlight-for-review-general{
    span {
      font-weight: bold;
    }
  }
  .form_required_class:before{
    content:"*";
    font-weight:bold;
    color: $red100;
  }
  .comment-field {
    margin-top: auto;
  }
}
