@import "src/scss/includes";

.ReactTable .rt-table .rt-tbody { overflow-x: hidden; }
.ReactTable .rt-th, .ReactTable .rt-td { padding: 7px 8px;}
.ReactTable .rt-thead.-header { box-shadow: none;    border-bottom: 1px solid $purple75;font-weight: 600;color:$purple75; }
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td { padding: 8px 8px; }
.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc { box-shadow: inset 0 3px 0 0 $purple80;}
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc { box-shadow: inset 0 -3px 0 0 $purple80; }
.ReactTable .rt-tbody .rt-td { border-right: 1px solid rgb(222, 226, 230);}
.ReactTable .rt-tfoot .rt-td { border-right: 1px solid rgb(222, 226, 230);}
.ReactTable  .rt-th{ text-align: left; }
.ReactTable .rt-tfoot {
  border-left: 1px solid $gray15;
  border-right: 1px solid $gray15;
  border-bottom: 1px solid $gray15;
}
.ReactTable .-pagination .-pageJump input { -moz-appearance: textfield; }
.ReactTable  .rt-noData{ padding: 0; top: 50%; z-index: 0!important;}
.ReactTable .nav-link { display: block;    padding: 0 0.1rem;}
.ReactTable .pagination-bottom { border-top: 1px solid $gray2; }
.ReactTable .-pagination { float: right;  font-size: 11px; border-top:0; box-shadow: none;}
.ReactTable .-btn { width: auto!important; }
.ReactTable .-pagination .-center { -webkit-box-flex: unset;    -ms-flex: unset;    -webkit-flex: unset;
  flex: unset;    text-align: center;    margin-bottom: 0;    display: -webkit-box;    display: -ms-flexbox;
  display: -webkit-flex;    display: unset;    -webkit-box-orient: unset;    -webkit-box-direction: unset;    -ms-flex-direction: row;
  -webkit-flex-direction: row;    flex-direction: row;    -ms-flex-wrap: wrap;    -webkit-flex-wrap: wrap;    flex-wrap: wrap;
  -webkit-box-align: center;    -ms-flex-align: center;    -webkit-align-items: center;    align-items: center;    -ms-flex-pack: distribute;    -webkit-justify-content: space-around;
  justify-content: space-around;}
.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {    -webkit-box-flex: unset;    -ms-flex: unset;    -webkit-flex: unset;    flex: unset;    text-align: center;}
.ReactTable .rt-tbody .rt-tr .activelink:hover{cursor: pointer;}
.ReactTable .rt-tbody .rt-tr:hover{ background-color:$gray7!important; cursor: default; }
.ReactTable  .rt-tbody .rt-tr:focus{ background-color:$gray7!important; }
.ReactTable  .rt-tbody .rt-tr:active{ background-color:$gray7!important; }

@media print {
  .ReactTable .-pagination .-btn{ height: 35px !important;}
}