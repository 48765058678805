@import "src/scss/includes";

.patient-content {
  width: 100%;
  max-width: 500px;
  min-width: 340px;
  border-left: 1px solid $gray10;

  .react-tabs__tab-panel{
    position: relative;
  }

  .react-tabs__tab-list {
    display: flex;
    height: 42px;
    margin: 0;
    border-bottom: 1px solid $gray10;
  }
  .react-tabs__tab{
    border: none;
    border-radius: 0;
    bottom: 0;

    font-weight: 700;
    font-size: 14px;
    padding: 0 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 25px;
    label {
      margin: 0 0 0 10px;
      vertical-align: -2px;
    }
    button {
      margin: 0 0 0 10px;
      vertical-align: -2px;
    }

  }
  .react-tabs__tab--selected{
    background: $gray5;
    border: none;
    border-radius: 0;
    bottom: 0;
  }

  .patient-instructions-items {
    height: 185px;
    overflow: auto;
    padding: 15px 0;
    outline: 0 none;

    > span {
      padding: 0 20px;
    }

    .patient-instructions-item {
      display: flex;
      flex-direction: row;
      padding-right: 10px;

      .dropdown-menu {
        min-width: auto;
        box-shadow: 0 0 20px rgba(28, 81, 88, 0.35);
        button {
          font-size: 14px;
          line-height: 200%;
        }
      }

      > span {
        flex-grow: 1;
        font-size: 14px;
        color: $gray85;
        line-height: 21px;
        transition: all .3s ease 0s;
        cursor: pointer;
        padding: 10px 10px 10px 20px;
        position: relative;
        word-break: break-all;

        &:before {
          content: ' ';
          display: inline-block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          margin-right: 4px;
          vertical-align: middle;
          background-color: $gray50;
        }
      }

      .eds-icon-btn {
        opacity: 0;
      }

      &:hover {
        background-color: $gray2;
        > .eds-icon-btn {
          opacity: 1;
        }
      }
    }

    > span {
      color: rgba($gray85, 0.4);
    }
  }

  .patient-files-items {
    height: 167px;
    overflow: auto;
    padding: 15px 20px 15px 20px;
    outline: 0 none;
    margin-bottom: 25px;

    &:before {
      content: 'Choose files or drag here to upload';
      color: $gray60;
      font-size: 12px;
      background-color: $white;
      position: absolute;
      line-height: 14px;
      left: 5px;
      bottom: -20px;
      padding: 0 5px;
      z-index: 3;
      border-radius: 3px;
    }
    &.drop-disabled:before  {
      content: inherit;
    }

    .patient-files-item {
      margin: 0 -20px;
      font-size: 14px;
      color: $gray85;
      line-height: 21px;
      transition: all .3s ease 0s;
      cursor: pointer;
      padding: 5px 45px 5px 20px;
      position: relative;
      word-break: break-all;
      display: flex;
      align-items: center;

      .delete-file-icon {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: $gray60;
        vertical-align: bottom;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -15px;
        opacity: 0;
        transition: all .3s ease 0s;
      }

      .patient-file-status-icon {
        margin-right: 3px;
        font-size: 20px;
        line-height: 20px;
      }

      &:hover {
        background-color: $gray2;
        > i {
          opacity: 1;
        }
      }
    }

    > span {
      color: rgba($gray85, 0.4);
    }
  }


  .patient-files-loader {
    bottom: 0;
    right: 0;
    top: 100px;
    left: 0;
    cursor: wait;
    position: absolute;
    z-index: 2;

    .eds-loader {
      position: absolute;
      top: 50%;
      margin-top: -30px;
      left: 50%;
      margin-left: -95px;
    }
  }

  &.is-drag-active {
    &:after {
      pointer-events: none;
    }
  }

  &.show-overlay {
    border-left: 1px solid $gray10;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba($white, 0.95);
      z-index: 1;
    }
  }
}



