@import "src/scss/includes";
.ledger-events-table {
  border-bottom: 1px solid $gray10;
  box-sizing: border-box;
  background: $white;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto;

  .let-header {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: $gray85;
    padding: 16px 24px;
  }

  .eds-finance-table {
    .ReactTable.ledger-events {
      flex: 1 0;
      overflow: auto;

      .rt-table {
        .rt-tbody .rt-tr-group .rt-tr {
          &.let-main-event:hover {
            background-color: transparent !important;
          }

          &.let-preview-event:hover {
              background-color: $purple20 !important;
            }
        }

        .center-leveling {
          display: flex;
          justify-content: center
        }

        .display-flex {
          display: flex;
        }

        .let-amount-view {
          .let-grey-text {
            color: $gray50;
            padding-right: 5px;
          }
        }

        .let-event-id {
          margin-left: 45px;
        }
      }
    }
  }
}
