@import "src/scss/includes";

.fields-to-select{
  .fields-header{
    background-color: $gray5;
  }
  .form-header {
    .qhead {
      border: 1px dashed $gray15;
      padding: 2px 0 !important;
      color: $purple80;
    }
    .investigator-ml {
      margin-left: 70px;
    }
  }
  .fields-to-select-list{
    .drag-item {
      display: flex;
      padding: 1px 1px 1px 0;

      border-bottom: 1px solid $gray5;
      border-top: 1px solid $gray5;
      margin-top: -1px;
      &:hover{
        background-color: $gray5!important;
        outline: 1px dashed $blue100;
      }
    }
    .custom-item{
      background-color: $blue10;

      &:hover{
        background-color: $gray5!important;
      }

      .col-md-1{
        padding-left: 14px!important;
      }
      .eds-toggle-switch.radio-template-toggle-switch {
        min-width: 245px;
        width: 100%;
      }
      .vital-label-heading {
        color:$purple80;
        font-weight: 600
      }
    }

    .input-group{
      background: transparent;
    }

    .dragging{
      background-color: $gray5!important;
      outline: 1px dashed $blue100;
      padding: 2px;
      &:hover{
        background-color: $gray5!important;
      }

      .custom-item{
        background-color: $gray5;
      }
    }
  }
}
