@import "src/scss/includes";

.widget-config-modal {
  .eds-modal-box-container {
    .eds-modal-box-body {
      padding-bottom: 10px!important;
      .table-widget-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 10px 10px 15px;
        height: 44px;
        gap: 10px;
        background: white;
        border: 1px solid $gray30;
        border-bottom: none;
        &:hover {
          background-color: $gray5;
        }
        &:last-child {
          border-bottom: 1px solid $gray30;
        }
        .table-widget-name {
          font-size: 14px;
          line-height: 150%;
          height: 21px;
          font-weight: 400;
          font-style: normal;
        }
        .eui-toggle {
          .eui-toggle-control {
            min-height: 18px;
            min-width: 38px;
          }
        }
      }
      .table-config-error-message {
        display: block;
        margin-top: 10px;
        height: 30px;
        text-align: right;
      }
    }
  }
}