@import "src/scss/includes";

.ims-study-history {
  padding-bottom: 15px;
  flex-direction: column;
  .study-history-table {
    border: 0 none;
    padding: 0 20px;
    .rt-table {
      .rt-thead {
        border: 0 none;
        .rt-tr {
          .rt-th {
            font-size: 12px;
            line-height: 150%;
            letter-spacing: 0.02em;
            color: $gray50;
            padding: 0 40px 5px 0;
            border: 0 none;
            font-weight: 300;
            &.study-history-start-date {
              padding-right: 0;
            }
            &.study-history-date-dash {
              padding-right: 0;
              padding-left: 0;
              text-align: center;
            }
            &.study-history-completion-date {
              padding-left: 0;
            }
            &.-sort-asc, &.-sort-desc {
              box-shadow: none;
              &:after {
                content: ' ';
                display: block;
                position: absolute;
                right: 45px;
                top: 50%;
                margin-top: -5px;
                border: solid transparent;
                height: 0;
                width: 0;
                opacity: .3;
              }
            }
            &.-sort-asc:after {
              border-bottom-color: $gray60;
              border-width: 6px;
              border-top-width: 0;
            }
            &.-sort-desc:after {
              border-top-color: $gray60;
              border-width: 6px;
              border-bottom-width: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      .rt-tbody {
        .rt-tr-group {
          border-color: $gray5;
          .rt-tr {
            align-items: center;
            .rt-td {
              font-size: 14px;
              line-height: 150%;
              color: $gray85;
              padding: 10px 40px 10px 0;
              border: 0 none;
              &.study-history-start-date {
                padding-right: 0;
              }
              &.study-history-date-dash {
                padding-right: 0;
                padding-left: 0;
                text-align: center;
              }
              &.study-history-completion-date {
                padding-left: 0;
              }
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
            &:hover {
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}
