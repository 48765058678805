@import "src/scss/includes";

.patient-source-column-wrapper {
  width: 100%;
  display: flex;
  align-items: center;

  .patient-source-column-field-buttons {
    display: flex;
    padding-left: 5px;
  }
}


// TODO: Remove all importants!
.patient-source-column-wrapper-custom-select {
  &__control {
    width: 170px;
    height: 25px !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #E7E7E7 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  &__option {
    color: black !important;

    &:hover {
      background-color: $gray5 !important;
      cursor: pointer;
    }

    &--is-selected {
      background-color: $purple20 !important;
      font-weight: 600;

      &:hover {
        background-color: $purple20 !important;
      }
    }
  }

  &__indicator-separator {
    display: none;
  }
}