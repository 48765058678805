.add-fixed-expense-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 20px 0;

  .add-comment-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}