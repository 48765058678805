@import "src/scss/includes";

.element-question .col-md-9:hover .setup-edit-btn-group{visibility:visible}
.element-question .row{    margin-left: 0 !important;    margin-right: 0 !important;}
.element-question .row .styled-text{
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: flex;
  align-items: center;
}
.element-question .formLabel-close{   position: absolute;    right: 5px;    top: 0;}
.element-question .custom-control.custom-checkbox:focus,
.element-question .custom-control.custom-checkbox:active,
.element-question .accordion .accordion__title:focus{
  outline: none !important;
  user-select: none;
}
.element-question .custom-close{   position: absolute;    right: 5px;    top: calc(50% - 14px);}
.element-question .istable-checkbox{ position: absolute; top: 5px; }
.tableseqicon{    font-size: 22px;    color: $blue100;    padding-top: 8px;    padding-right: 5px;}