.eds-modal-box.eds-confirm-edc-date-modal {
  .eds-modal-box-container {
    .eds-modal-box-body {
      .title {
        display: flex;
        font-size: 16px;
        padding-bottom: 10px;
      }
      .edc-visit-date-picker-layout {
        display: flex;
        align-items: center;

        .label {
          font-size: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}
