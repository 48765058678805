.eds-modal-box.add-payments-items-modal {
  .eds-modal-box-container {
    width: 96%;
    max-width: 1620px;
    height: calc(100vh - 40px);
    display: grid;
    grid-template-rows: auto 1fr;
    .eds-modal-box-body {
      padding: 0;
      overflow: hidden;
      display: grid;
      height: 100%;
      grid-template-columns: 1fr 1fr;
      > .MuiBox-root {
        flex: 0 0 50%;
        padding: 35px 50px;
        overflow: auto;
      }
      > .eui-file-upload-preview {
        background-color: #ffffff;
        overflow: hidden;
        padding-right: 50px;
        .eds-fv-image-viewer {
          overflow: auto;
          width: 100%;
          height: 100%;
          position: relative;
        }
      }
    }
  }
}
