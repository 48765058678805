.eui-form-group {
  display: inline-flex;
  .eui-form-label {
    font-weight: 500;
    margin-right: 18px;
  }
  .eui-form-inputs {
    display: flex;
  }

  .eui-form-direction-column {
    flex-direction: column;
  }
  .eui-form-direction-row {
    flex-direction: row;
    label.eui-input-selectors {
      margin: 0 11px;
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
