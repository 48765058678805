@import "src/scss/includes";

.prediction-container {
  padding-top: 35px;
}

.previous-container {
  padding-top: 15px;
}

.enrollment-font-size {
  padding-left: 15px;
}

.row > div:nth-child(odd) > .previous-container {
  background-color: $gray5;
}

.prediction-cont-1 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.predictions-all-items-1 .prediction-cont-1:nth-child(odd) {
  background-color: $gray5;
}

.enrollment-bold-font-size-link {
  padding-left: 15px;
  cursor: pointer;
  color: $blue100;
}
.enrollment-bold-font-size-link:hover {
  text-decoration: underline;
}
.prediction-input-width {
  min-width: 90px;
}
