@import "src/scss/includes";
.eds-loader-overlay {
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  cursor: wait;
  position: absolute;
  background-color: rgba(158, 142, 142, 0.6);
  z-index: 10000;
  .eds-loader {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    left: 50%;
    margin-left: -95px;
  }
  &.eds-loader-overlay-light {
    background-color: rgba($white, 0.6);
  }
}
