@import "src/scss/includes";

.dashboard{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    max-height: calc(100% - 126px);
    background-color: white;
    width: 100%;
    height: calc(100% - 126px);

    .dashboard-header{
        display: flex;
        width: 100%;
        background-color: $gray2;
        border-bottom: 1px solid $gray10;
        justify-content: space-between;
    }

    .grid-layout-container{
        display: flex;
        width: 100%;
        height: calc(100% - 65px);
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: auto;
        align-content: flex-start;
        .react-grid-layout{
            width: 100%;
        }
    }
}