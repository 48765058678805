.direction-container {
  margin-top: 30px;
  max-width: 70%;
  &.on-logs-page{
    margin-bottom: 0;
    margin-left: 1.2rem;
  }
  &.preview-modal {
    margin-bottom: 1.5rem;
  }
  .single-direction-container {
    margin-left: 1rem;
    &.encounter {
      margin-bottom: 2.5rem;
    }
    .direction-title {
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.1rem;
    }
    .direction-message {
      line-height: 1.2;
      font-size: 0.9rem;
    }
  }
}