@import "src/scss/includes";
.eds-multi-select-list {
    .eds-multi-select-list-option {
        position: relative;
        padding: 5px 0 5px 50px;
        user-select: none;
        > i {
            content: ' ';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 1rem;
            top: 50%;
            margin-top: -10px;
            text-align: center;
            line-height: 20px;
            font-size: 13px;
            border: 1px solid $gray15;
            color: transparent;
        }
        &.selected {
            color: $purple75;
            > i {
                color: $red100;
                border: 1px solid $red100;
            }
        }
        &:hover {
            background-color: $gray2;
        }
    }
    &.eds-multi-select-list-fixed-height {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
        .eds-multi-select-list-options {
            flex: 1;
            overflow: auto;
        }
    }
}
