@import "src/scss/includes";

.encounter-segment {
  .encounter-segment-epoch-information {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;

    .encounter-segment-epoch-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
    }
  }

  .encounter-segment-card-container {
    position: relative;
    width: 100%;
    background-color: $white;
    padding: 4px 25px 4px 20px;
    box-shadow: 0 2px 9px rgba(5, 5, 5, 0.1), 0 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border-left: 5px solid var(--encounter-status, $purple80);

    .appointment-window + .appointments-rows {
      padding-top: 0;
    }

    .encounter-segment-card-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray5;

      > div {
        vertical-align: middle;
        padding: 8px 0;
        min-height: 40px;
        box-sizing: content-box;
      }

      .est-name {
        width: fit-content;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $gray85;
      }

      .status-chip {
        width: fit-content;
        background: $gray10;
        border-radius: 24px;
        padding: 2px 10px;
        margin-left: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .est-more-options {
        color: $gray60;
        align-items: center;
        display: inline-flex;

        .eds-icon-btn {
          font-size: 22px;
        }
      }
    }
  }

  &.encounter-segment-unexpected, &.encounter-segment-non-protocol, &.encounter-segment-no-appointments {
    .encounter-segment-card-container {
      .encounter-segment-card-header {
        border: 0 none;
      }
    }
  }

  &.encounter-segment-unexpected-appointment {
    .encounter-segment-card-container {
      border-left-color: transparent;
    }
  }
}
