.study-details-section {
  .study-details-section-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 0.5rem;
    border: 1px solid #dee2e6;
  }
  .study-details-section-header {
    border-bottom: 2px #E7E7E7 dotted;
    box-sizing: border-box;
    color: #691E44;
    padding-bottom: 10px;
    font-weight: bold;
    .study-details-section-study-name {
      color: #2D9CDB;
      font-weight: normal;
    }
  }
  .study-details-section-body {}
}
