.eds-access-denied-tooltip {
    pointer-events: none;
    cursor: not-allowed;
    .overlay-container{
        display: flex;
        .material-icons{
            font-size: 24px;
            line-height: 24px;
        }
        >span{
            line-height: 24px;
        }
    }
}