@import "src/scss/includes";

.etc-body-details-assignees-select {
  .eds-select > .r-ss-wrap.r-ss-expanded {
    z-index: 104;
    .r-ss-dropdown {
      top: 0;
      z-index: 1;
    }
  }
  .etc-task-assignees-user-info{
    .etc-task-assignees-user-info-label{
      font-size: 12px;
      line-height: 12px;
      color: $gray60;
    }
    .etc-task-assignees-user-info-name{
      font-size: 14px;
      line-height: 21px;
    }
  }
}
