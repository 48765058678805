@import "src/scss/includes";
.eds-modal-box.add-or-edit-progress-note-modal {
  .eds-modal-box-container {
    padding: 40px 40px 20px;
    .eds-modal-box-header {
      margin-bottom: 20px;
      padding: 0;
      > button {
        visibility: hidden;
      }
    }
    .eds-modal-box-body {
      padding: 0;
      background-color: $white;
      .notes-chips {
        padding-bottom: 20px;
      }
      .date-type-info {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        > div:first-child {
          margin-right: 40px;
        }
        > div {
          width: 50%;
        }
      }
    }
    .eds-modal-box-footer {
      padding: 25px 0;
      > button:first-child {
        margin-right: 20px;
      }
    }
  }
}