@import "src/scss/includes";
.form-row {
  .remove-row{
    z-index: 2;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .add-more {
    margin-top: 10px;
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-end;
  }
}
