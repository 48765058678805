@import "src/scss/includes";

.eui-date-picker-manual {
  position: relative;

  .eui-input > label > input{
    min-width: 110px;
  }
  .dropdown-menu-wrapper{
    background-color: $white;
    padding: 26px;
    position: absolute;
    top: 100%;
    width: 300px;
    z-index: 99999 !important;
    box-shadow: 0 1px 3px rgba($black,.1);
    border: 1px solid $gray10;
    &:after{
      display: block;
      position: absolute;
      content: '';
      width: 100%;
      height: 30px;
      bottom: -30px;
      left: 0;
      background-color: transparent;
    }

    .message{
      div{
        &:first-child{
          margin-bottom: 10px;
          font-weight: bold;
        }
      }
    }

    .buttons{
      width: 100%;
      display: flex;
      margin-bottom: 10px;

      .button{
        width: inherit;
        text-align: center;
        background-color: $white;
        border-radius: 0;
        border: 1px solid $gray10;
        padding: 3px;
        &:first-child{
          border-right: none;
        }
        &:last-child{
          border-left: none;
        }
        &:focus{
          outline: none;
        }
        &.active{
          background-color: $gray10;
        }
      }
    }
  }

  .rdtOpen{
    input{
      display: none;
    }
    .rdtPicker{
      padding: 0;
      position: relative;
      width: auto;
      border: none;
      box-shadow:none;
    }
  }
}
