.erc-scroll-top {
  width: 56px;
  height: 56px;
  border-radius: 16px;
  background-color: #691E44;
  position: absolute;
  bottom: 30px;
  right: 40px;
  z-index: 100;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  transition: right  .1s linear 0s, background-color  .1s linear 0s;
  .eui-icon {
    display: block;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    padding-top: 5px;
  }
  &.erc-scroll-top-hidden {
    right: -56px;
  }
  &:hover {
    background-color: darken(#691E44, 10);
  }
}
