@import "src/scss/includes";

.widget-container{
  width: 50%;
  min-height: 250px;
  max-height: 450px;
  display: flex;

  .widget{
    display: flex;
    width: 100%;
    border: 1px solid $gray10;
    background-color: white;
    flex-direction: column;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);
    -webkit-box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);
    -moz-box-shadow: 0 1px 1px 0 rgba($black,0.14), 0 2px 1px 0 rgba($black,0.12), 0 1px 3px 0 rgba($black,0.20);
    .widget-header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 24px!important;
      padding-bottom: 16px!important;
      .title{
        color: $gray85;
        font-weight: 500;
        font-size: 20px;
        margin: 0px;
      }
    }

    .widget-table-container{
      display: flex;
      min-height: 100px;
      width: 100%;
      flex-direction: column;
    }
    .ReactTable{
      border: none;
      min-height: 100px;
      .rt-thead{
        .rt-tr{
          padding-right: 10px;
        }
      }
      .rt-tbody{
        overflow-y: scroll;
      }
      .rt-td, .rt-th{
        padding: 14px 8px;
      }
      .rt-tr{
        &.highlight{
          background-color: $purple20;
          &:hover{
            background-color: $purple20!important;
          }
        }
      }
    }
  }
  .eds-loader{
    width: 100%;
    height: 100%;
  }
}