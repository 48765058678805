@import "src/scss/includes";

.logs-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  .ReactTable{
    position: unset;
    .rt-table{
      overflow: unset;
      .rt-thead{
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }
  }

  .column-pages {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 10px;
    min-height: 50px;
    flex-wrap: wrap;

    .custom-title {
      display: flex;
      flex-grow: 1;
      font-size: 18px;
      font-weight: 600;
      padding-left: 0!important;
      cursor: auto;
    }

    > div {
      padding: 10px 0 10px 10px;
      cursor: pointer;
    }

    .column-page {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background-color: $gray10;

      &.active {
        background-color: #7d3559;
      }
    }
  }

  .ReactTable {
    border: none;
    .rt-table{
      border: 1px solid rgba(0,0,0,0.1);
    }
    .pagination-bottom {
      position: relative;
      display: flex;
      justify-content: center;
      .-pageSizeOptions{
        position: absolute;
        left: 0;
        top: 13px;
        margin: 0;
      }
      .-pagination{
        float: none;
        margin-top: 5px;
      }

      button {
        background: none;
        padding: 0;
        .eui-icon {
          font-size: 26px;
          line-height: 32px;
        }
      }
    }

    .rt-td {
      height: 44px;
      padding: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      >div{
        padding: 0 8px;
        margin: 7px 0;
        white-space: normal;
        text-align: center;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        word-break: break-word;
        justify-content: flex-start;
      }
      >div.file-type-cell {
        -webkit-line-clamp: unset;
      }

      &.text-left {
        justify-content: flex-start;
        text-align: left;
        div{
          text-align: left;
        }
      }
      &.text-center {
        justify-content: center;
        text-align: center;
        div{
          text-align: center;
        }
      }
      i {
        z-index: 1;
      }
    }

    .rt-thead {
      .rt-th {
        white-space: normal;
        font-weight: 600;
        padding: 10px 8px;
        color: $gray85;
        border-color: $gray10;
        height: 60px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        .eui-icon{
          margin-top: 10px;
        }
        > div {
          display: flex;
          height: 100%;
          width: 100%;
          flex-grow: 1;
        }
      }
    }

    .rt-table {
      padding-right: 25px;
    }

  }

  .ReactTable .rt-thead .rt-tr {
    background-color: $gray5;
  }

  .ReactTable .rt-thead.-header {
    border-bottom: 1px solid $gray15;
  }

  .ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;

    .eui-icon {
      :first-child {
        color: $gray60;
      }
    }
  }

  .ReactTable  .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none;

    .eui-icon {
      :last-child {
        color: $gray60;
      }
    }
  }

  .ReactTable .rt-thead .-sort-asc .eui-icon {
    &:first-child {
      color: $gray85;
    }
  }

  .ReactTable .rt-thead .-sort-desc .eui-icon {
    &:last-child {
      color: $gray85;
    }
  }

  .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: 1px solid rgba(0,0,0,0.05);
  }

  .custom-sort-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    max-width: 100%;

    .title{
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      font-weight: 600;
    }

    .one-line{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    .icons {
      display: flex;
      flex-direction: column;

      .eui-icon {
        font-size: 24px;
        line-height: 10px;
        color: $gray30;
      }
    }
  }

  //custom header

  //carousel buttons
  .nav-button {
    width: 25px;
    min-width: 25px;
    background: white;
    background: $gray15;
    position: absolute;
    text-align: center;
    height: 100%;
    display: flex;
    top: 1px;
    cursor: pointer;
    user-select: none;
    z-index: 3;

    &.with-pagination{
      height: calc(100% - 48px);
    }

    .eui-icon {
      font-size: 24px;
    }

    > div {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;

      i{
        position: sticky;
        top: 30%;
        bottom: 30%;
      }

      &.shadow-left {
        -webkit-box-shadow: 4px 0 10px -6px $gray60;
        -moz-box-shadow: 4px 0 10px -6px $gray60;
        box-shadow: 4px 0 10px -6px $gray60;
      }

      &.shadow-right {
        -webkit-box-shadow: -4px 0 10px -6px $gray60;
        -moz-box-shadow: -4px 0 10px -6px $gray60;
        box-shadow: -4px 0 10px -6px $gray60;
      }
    }

    &:hover {
      background: $gray10;
    }

    &[aria-disabled = true]{
      background-color: $gray5;
      cursor: auto;
      .eui-icon {
        color: $gray30;
      }
    }
  }

  //carousel buttons

}

.logs-table-tooltip{
  max-width: 336px;
}