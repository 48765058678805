@import 'src/scss/includes';

.history-link {
  display: flex;
  color: $darkBlue100;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  margin-left: 10px;
}
