@import "src/scss/includes";
.element-setup-item-group-details {
  .controlled-button-group {
      i {
      padding: 6px;
      font-size: 17px;
      color: $blue100;
      border-radius: 50%;
      cursor: pointer;
      background-color: $white;
      margin: 0 3px;
      border: 1px solid $gray15;
    }

     i:hover {
      background-color: $purple50;
      border: 1px solid $purple75;
      color: $white;
    }

     i.active {
      background-color: $white;
      color: $blue100;
    }
  }

  .plcustom {
    padding-left: 14px !important;
    float: right;
  }

  .headfright {
    float: right;
  }
}