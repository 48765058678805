.erv-highlight-for-review {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.38);
  mask: url("highlight-for-review.svg" + "#not-highlighted");
  &.erv-highlight-for-review-highlighted {
    background-color: #2465C6;
    mask: url("highlight-for-review.svg" + "#highlighted");
  }
  &.erv-highlight-for-review-confirmed {
    background-color: #2465C6;
    mask: url("highlight-for-review.svg" + "#confirmed");
  }
}
