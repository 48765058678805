@import "src/scss/includes";

.eui-table-input{
  .eui-input{
    label{
      border: none;

      &:focus-within{
        border-bottom: 1px solid $gray30;
      }
      input{
        padding: 0 0 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .eui-input-face{
        border: none;
        background-color: transparent !important;
      }
      .eui-input-prefix{
        display: none;
      }
      .eui-input-suffix{
        display: none;
      }
    }
  }
}

