@import "src/scss/includes";

.collapse-button {
  width: 28px;
  height: 28px;
  cursor: pointer;
  &.hidden{
    .collapse-icon{
      background-color: $purple80;
      color: white;
    }
  }
  .collapse-icon{
    line-height: 28px;
    font-size: 22px;
    margin: 0;
    padding: 0 3px 0 3px;
    color: $purple80;
    background-color: white;
    -webkit-box-shadow: 0 1px 5px 0 rgba($black,0.29);
    box-shadow: 0 1px 5px 0 rgba($black,0.29);
    border-radius: 100px!important;
  }

}