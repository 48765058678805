@import "src/scss/includes";

.budget-event-form-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 15px;
  gap: 1rem;
  width: 100%;

  >.eui-form-group .eui-form-inputs{
    flex-wrap: wrap;
    gap: 18px;
    .eui-checkbox {
      width: max-content;
      margin: 0;
    }
  }

  .event-name-length-notification {
    font-size: 12px;
    color: $red100;
    padding: 5px 16px 0 0;
    margin-bottom: -0.5rem;
  }

}
