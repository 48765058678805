@import "src/scss/includes";
.invoice-email-details-modal.eds-modal-box {
  font-family: $ffRoboto;
  .eds-modal-box-header {
    font-weight: 500;
  }
  .eds-modal-box-body {
    max-height: 450px;
    overflow: auto;
    padding: 20px 30px 0 30px;
  }
  .eds-modal-box-footer {
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    .action-panel {
      display: flex;
      gap: 52px;
      color: $gray50;
    }
  }
}