.encounter-element-header {
  background-color: white;
  display: flex;
  flex-direction: row;
  z-index: 99;
  align-items: center;
  :not(.adverse) {
    justify-content: center;
  }
  h5{
    flex-grow: 1;
  }
  .eds-date-time-picker {
    > div {
      .form-control {
        height: 37px;
      }
    }
  }
  .item-group-buttons {
    display: flex;
    margin-left: 15px;
    margin-bottom: 25px;
    button {
      margin-right: 10px;
    }
  }
  .eds-btn-group {
    padding: 3px 8px 3px 17px;
    float: right;
  }
}
