@import "src/scss/includes";

.time-duration {
  padding: 0;
  .eui-date-picker-manual .eui-input{
    width: 100%;
  }

  > div {
    display: flex;
    align-items: stretch;

    .time-select, .duration-select {
      min-width: auto;
      width: 50%;
      .r-ss-wrap{
        min-width: auto;
      }
    }
    .date-select, .time-select, {
      input{
        margin: 26px -5px 10px -5px;
      }
      .eds-input-label {
        font-size: 13px!important;
        top: 4px!important;
      }
    }

    .time-select{
      input{
        background: transparent;
        padding-left: 6px;
        &:focus{
          outline: none;
          box-shadow: none;
        }
      }
      .rdtPicker{
        display: none;
      }
    }
  }
}
