.patient-profile-subheader {
  padding-top: 0;
  padding-left: 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E0E0E0;
  .go-to-patient-studies-button {
    display: flex;
    color: #2465c6;
    cursor: pointer;
    height: 35px;
    line-height: 35px;
    margin-left: 10px;

    width: fit-content;
    align-items: center;
    .eui-icon {
      font-size: 25px;
      margin-right: 8px;
    }
  }
}
