@import "src/scss/includes";

.notes-table {
  max-height: 700px;
  .apn-drawer {
    width: 24px;
  }

  table {
    tbody {
      .previous-version td {
        border-bottom: 0;
      }
      .expanded-row {
        > td {
          border-bottom: 0;
        }
      }
      tr {
        .apn-note > div {
          max-width: 1000px;
          overflow-y: auto;
          max-height: 150px;
        }
        &.current-version {
          cursor: pointer;
        }
        &.selected {
          background-color: $blue10;
        }
      }
    }
  }
}
