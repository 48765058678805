@import "src/scss/includes";

.payable-to-section {
  display: flex;
  flex-direction: column;
  .cards-container {
    display: flex;
    padding: 25px 2px;
    gap: 25px;
    flex-wrap: wrap;
  }
}