@import "src/scss/includes";
.export-case-report-forms {
  padding: 24px;
  height: calc(100vh - 54px - 34px - 37px - 96px);
  display: flex;
  flex-direction: column;
  .export-case-report-forms-selector {
    display: flex;
    border: 1px solid $gray10;
    background: $white;
    color: $black;
    flex: 1;
    overflow: hidden;
    .ecr-options {
      .ecr-option {
        display: flex;
        align-items: center;
        padding: 13px 8px 13px 10px;
        position: relative;
        line-height: 20px;
        cursor: pointer;
        user-select: none;
        &.ecr-option-selected {
          background: $gray5;
        }
        &.ecr-option-disable-selection {
          cursor:default;
        }
      }
    }
    > .alert-unavailable-items {
      font-size: 14px;
      line-height: 150%;
      color: $gray85;
    }
    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }
    > div {
      width: 325px;
      padding: 20px;
      overflow-y: scroll;
      &:last-child {
        flex: 1;
      }

      &.hide-selector-form {
        display: none;
      }
    }
  }
}
