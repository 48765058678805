
.eui-input.eui-search-input {
  width: 320px;
}

@media (max-width: 1280px) {
  .eui-input.eui-search-input {
    width: 220px;
  }
}

@media (max-width: 985px) {
  .eui-input.eui-search-input {
    width: 195px;
  }
}
