.revenue-filters{
  display: flex;
  flex-direction: column;
  .rf-all-inputs-and-button{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    > div {
      width: 100%;
    }
    .rf-inputs{
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      >:nth-child(1n){
        margin-right: 15px;
        width: 196px;
      }
    }
    .rf-radio{
      display: flex;
      >span {
        font-weight: 500;
        margin-right: 18px;
      }
    }
  }
}
