@import "src/scss/includes";

.text-box-field {
  position: relative;
  .eui-input.eui-input-legacy > label {
    height: 46px;
  }
  .text-box-counter {
    font-size: 12px;
    color: $gray70;
    position: absolute;
    right: 14px;
    bottom: 0;
  }

}