@import "src/scss/includes";
.eds-icon-btn {
  padding: 0;
  margin: 0;
  font-size: 17px;
  color: $purple75;
  cursor: pointer;
  background: none;
  border: 0 none;
  vertical-align: middle;
  transition: all .3s ease 0s;
  &.glyphicon {
    top: 0;
    &:before {
      top: 1px;
      position: relative;
    }
  }
  &.eds-icon-btn-blue{
    color: $blue100;
    &:hover {
      color: darken($blue100, 20%);
    }
  }
  &.eds-icon-btn-grey{
    color: $gray85;
    &:hover {
      color: darken($gray85, 20%);
    }
  }
  &.eds-icon-btn-light-grey{
    color: $gray30;
    &:hover {
      color: darken($gray30, 20%);
    }
  }
  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    color: darken($purple75, 20%);
  }
}
