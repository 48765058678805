.eds-modal-boxes {
  .eds-modal-box.item-group-preview-modal {
    .eds-modal-box-container {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
    }
    .eds-modal-box-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
    }
  }
}