.eds-modal-box.edit-cdash-modal {
  .eds-modal-box-body {
    .edit-cdash-label-wrapper {
      display: flex;
      align-items: center;
      margin-top: 30px;
      .edit-cdash-label {
        min-width: 200px;
        font-size: 17px;
      }
      .eui-input {
        width: 400px;
      }
    }
  }
}
