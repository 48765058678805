.study-details-list {
  max-width: 250px;
  .study-details-list-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 14px;
    line-height: 150%;
    .study-details-list-title {
      font-weight: bold;
      align-self: center;
      padding-top: 10px;
      padding-bottom: 16px;
      white-space: nowrap;
    }
    .study-details-list-right-side {
      font-weight: normal;
      color: #691E44;
      align-self: center;
      grid-column: 3;
      margin-left: -9px;
    }
  }
  .study-details-list-container {
    .study-details-list-item {
      display: grid;
      grid-template-columns: 126px 1fr;
      grid-template-rows: 10px 20px 10px;
      .study-details-list-title {
        grid-row: 2;
      }
      .study-details-list-right-side {
        grid-column: 2;
        grid-row: 2;
        align-self: center;
      }
    }
  }
}
