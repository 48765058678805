@import "src/scss/includes";

.eds-modal-box.update-milestones-modal {
  .eds-modal-box-container {
    .eds-modal-box-body {
      max-height: 700px;
      overflow-y: auto;
      background-color: $white;
    }
  }
}