@import "src/scss/includes";

.eds-btn-with-icon {
  background: transparent;
  border: 0 none;
  padding: 7px 10px 7px 40px;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  color: $blue100;
  position: relative;
  line-height: 21px;
  transition: color .2s ease 0s;

  > i {
    position: absolute;
    top: 50%;
    margin-top: -12.5px;
    left: 10px;
    font-size: 15px;
    font-weight: 700;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 4px $blue100 solid;
    background-color: $blue10;
    color: $blue100;
    line-height: 17px;
    transition: all .2s ease 0s;
    &.glyphicon {
      font-size: 11px;
    }
  }

  &:hover:not(:disabled) {
    color: $blue50;
    > i {
      border-color: $blue50;
      color: $blue50;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.eds-btn-with-icon-right {
    padding: 8px 40px 8px 10px;

    > i {
      right: 10px;
      left: auto;
    }
  }

  &.eds-btn-with-icon-small {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 22px;
  }

  &.eds-btn-with-icon-large {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 22px;
  }
}
