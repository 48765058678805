@import "src/scss/includes";
.eett-encounter-cell {
  background-color: $white;
  min-width: 90px;
  position: relative;
  .eett-encounter {
    cursor: default;
    position: absolute;
    top: 10px;

    .eett-unexpected-encounter-board {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      left: 32px;

      &.eett-back-unexpected-encounter-board {
        z-index: 0;
        left: 38px;
        top: -6px;
        opacity: 0.8;

        &.eett-unexpected-encounter {
          background-color: #6E6E6E;
        }
      }
    }

    .eett-encounter-board {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      left: 32px;

      &.eett-front-encounter-board {
        text-align: center;
        z-index: 1;
        background-color: $gray10;
      }

      &.eett-back-encounter-board {
        z-index: 0;
        left: 38px;
        top: -6px;
        opacity: 0.8;

        &.eett-regular-encounter {
          background-color: $black
        }

        &.eett-non-protocol-encounter {
          background-color: $blue100;
        }
      }
    }
  }
}
