.add-or-edit-budget-event .dialog {
  max-width: 800px;
}

.enc-ig.add-or-edit-budget-event .dialog {
  max-width: 1100px;
}

.add-or-edit-budget-event .bem-form {
  flex: auto;
  align-items: stretch;
  flex-direction: column;
}

.add-or-edit-budget-event .bem-form > .form-row {
  flex: auto;
  align-content: flex-start;
}

.add-or-edit-budget-event-sidebar {
  width: 250px;
  height: 400px;
  overflow: auto;
  position: relative;
}
