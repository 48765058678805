@import "src/scss/includes";

.file-upload-field {
  .multiple-upload-container {
    display: flex;
    flex-direction: column;
    .drag-zone-outer {
      .drag-zone-inner {
        i {
          z-index: 1;
        }
      }
    }
    .list-group .mft-list-group-item-wrap {
      flex-direction: column;
      .list-group-item {
        i {
          z-index: 1;
        }
      }
      .mft-file-upload-name {
        display: block;
        max-width: 200px;
      }
      .mft-file-upload-toggle-block {
        position: initial;
        width: auto;
        flex-wrap: wrap;
      }
    }
    .list-group .list-group-item {
      i {
        z-index: 1;
      }
    }
  }
}

button.file-field-view {
  background-color: $gray2;
  height: 38px;
  color: $gray60;
  border: 1px solid $gray15;
  padding: 0 20px;
  border-radius: .25rem;
  margin: 5px 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  > .eui-icon {
    vertical-align: middle;
  }
}

