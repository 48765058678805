@import "src/scss/includes";

.eui-table-select{
  width: 100%;
  &.eui-table-select--is-disabled{
    .eui-table-select__control{
      .eui-table-select__value-container{
        color: $gray30;
      }
      .eui-table-select__indicators{
        color: $gray30;
      }
    }
  }
  .eui-table-select__control{
    .eui-table-select__value-container{
      padding: 5px;
    }
    .eui-table-select__indicators{
      font-size: 20px;
    }
  }
}

.eui-table-select__menu-portal{
  background-color: $white;
  min-width: 150px;
  .eui-table-select__menu{
    padding: 5px 0 5px 0;
    background-color: $white;
    max-height: 160px;
    .eui-table-select__menu-list{
      max-height: 150px;
      overflow-y: auto;
      .eui-table-select__option{
        padding: 10px;
        &.eui-table-select__option--is-selected{
          background-color: $gray5;
        }
        &:hover{
          background-color: $gray5;
        }
      }
    }
  }
}
