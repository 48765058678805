@import "src/scss/includes";

.default-widget {
  min-height: 500px;
  max-height: 1500px;

  .ReactTable {
    min-height: 290px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .rt-thead.-header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: $gray85;
      border: 1px solid $gray15 !important;
    }

    .rt-tbody {
      border: 1px solid $gray15 !important;
      border-top: none!important;
    }

    .pagination-bottom {
      display: flex;
      justify-content: center;

      button {
        background: none;
        padding: 0;
        .eui-icon{
          font-size: 26px;
          line-height: 32px;
        }
      }
    }
  }

  .ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;

    .eui-icon {
      :first-child {
        color: $gray60;
      }
    }
  }

  .ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none;

    .eui-icon {
      :last-child {
        color: $gray60;
      }
    }
  }

  .ReactTable .rt-thead .-sort-asc .eui-icon {
    &:first-child {
      color: $gray85;
    }
  }

  .ReactTable .rt-thead .-sort-desc .eui-icon {
    &:last-child {
      color: $gray85;
    }
  }


  .custom-sort-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .icons {
      display: flex;
      flex-direction: column;
      width: 18px;

      .eui-icon {
        font-size: 18px;
        font-weight: 600;
        line-height: 12px;
        color: $gray30;
      }
    }
  }

  .ReactTable {
    width: 100%;
    .total-title {
      font-weight: 500;
    }
    .total-val {
      &.link {
        color: $blue100;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .ReactTable .rt-tfoot {
    box-shadow: none;
  }

  .widget .ReactTable .rt-tbody {
    overflow-y: auto;
  }

  .link {
    color: $blue100;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  .widget .ReactTable .rt-thead .rt-tr {
    padding-right: 0;
  }

  .chips-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  .filters-row {
    display: flex;
    flex-direction: row;

    .eds-select {
      margin-right: 16px;
    }
  }

  .eds-select.eds-select-multiselect {
    min-width: 10px !important;
    width: fit-content;
    height: 28px !important;

    .r-ss-wrap {
      min-width: 10px !important;
      width: 100% !important;

      .r-ss-trigger {
        height: 28px !important;
        padding: 0 !important;
        border-bottom-width: 1px !important;
        border-bottom-color: $gray10 !important;
        border-radius: 5px !important;
        min-width: 10px !important;

        .carat {
          bottom: 5px !important;
          &.up {
            bottom: 10px !important;
            border-color: transparent transparent $purple80 transparent;;
          }
          &.down {
            border-color: $purple80 transparent transparent transparent;
          }
        }

        .r-ss-selection-clear {
          display: none !important;
        }
      }

      .r-ss-dropdown {
        width: 300px;
        .r-ss-options-wrap{
          border-radius: 0;
        }
        .r-ss-option-group-heading:first-child {
          border: none;
        }
      }
    }
  }

  .eds-select.eds-select-type-default.eds-select-not-empty > label,
  .eds-select.eds-select-type-default > label {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    display: flex;
    margin: -23px 35px 0 10px;
    line-height: 18px;
    font-size: 15px;
    color: #7d3559;
    font-weight: 600;

    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 5px;
    }
  }

}