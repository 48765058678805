@import "src/scss/includes";

.footfx {
  position: absolute;
  bottom: 0;
  text-align: center;
  background: $white;
  z-index: 1111;  width: 100%;
  border-top: 1px solid $gray10;
}
