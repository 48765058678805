@import "src/scss/includes";

.rdtPicker {
  border: 1px solid $gray15;
  width: 298px;
  padding: 30px;

  td {
    height: 32px;
  }
  th {
    height: 40px;
    line-height: 40px;
    border-bottom: none;
  }

  .rdtDay {
    line-height: 32px;
  }

  .rdtActive {
    background-color: $purple80 !important;

    &.rdtToday {
      &:before {
        display: none;
        border-bottom-color: $white !important;
      }
    }
  }

  .rdtToday {
    background-color: $gray10;

    &:before {
      display: none !important;
    }
  }

  .rdtMonth, .rdtYear {
    height: 40px;
  }

  .rdtNext > span, .rdtPrev > span {
    font-size: 30px;
    font-weight: 200;
    width: 32px;
    margin: 0 auto;
  }
}