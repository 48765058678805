@import "src/scss/includes";

.completed-icf-page {
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 126px);
  top: 90px;

  .link{
    color: $blue100;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}
