.transaction-ledger-top-section{
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 11px 19px;
  align-items: center;
  b {
    padding-left: 5px;
  }
  .tlts-export{
    margin-right: 20px;
  }
}
