@import "src/scss/includes";

.dropdown-menu-wrapper {
  background-color: $white;
  padding: 26px;
  position: absolute;
  top: 100%;
  width: calc(100% - 30px);
  z-index: 99999 !important;
  box-shadow: 0 1px 3px rgba($black, .1);
  border: 1px solid $gray10;

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 30px;
    bottom: -30px;
    left: 0;
    background-color: transparent;
  }

  .message {
    div {
      &:first-child {
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
  }
}
