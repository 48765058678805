.element-setup-page {
  overflow-y: auto;
  height: calc(100% - 126px);
  width: 100%;
  position: absolute;
  margin-bottom: 36px;
  top: 0;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  .element-setup {
    .rdtPicker {
      z-index : 1 !important;
    }
  }
}
