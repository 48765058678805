.ReactTable.missed-budget-table {
  display: flex;
  flex: 1 0;
  overflow: auto;
  border: none;
  &.mbt-disabled {
    .rt-table {
      pointer-events: none;
    }
  }
  .rt-tbody {
    overflow-y: scroll;
  }
  .rt-thead {
    padding-right: 10px;

    > .rt-tr:last-child {
      margin-right: 20px;
    }
  }
  .rt-td {
    &:last-child {
      margin-right: 20px;
    }
  }

  .mbt-id-cell {
    justify-content: flex-end;
  }
  .mbt-end-leveling{
    justify-content: flex-end;
    display: flex;
  }
  p.mbt-value {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width,min-width,padding,opacity;
  }
}
