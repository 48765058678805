@import "src/scss/includes";
.eds-finance-enrollments {
  .eds-finance-enrollments-control {
    justify-content: space-between;
    display: flex;
    align-content: center;
    padding: 23px 23px 0;
    flex-wrap: wrap;

    .general-header-wrapper {
      border: none;
      padding: 0;
      margin: 0;
      .month-select {
        width: 100%;
        min-width: 150px;
        max-width: 400px;
      }
    }

    .eds-finance-enrollments-filters {
      display: flex;
      flex-direction: row;
      margin: 0 22px 0 0;
      flex-wrap: wrap;
      .eds-ssu-select {
        min-width: 250px;
        margin-right: 15px;
      }
      .calendar-input
      {
        min-width: 150px;
        width: 150px;
      }
    }
    .enrollment-export-button{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .rt-td.enrollment-column-with-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .center-table-column-content {
    text-align: center !important;
  }
  .no-padding-no-border > .btn-link {
    padding: 0 !important;
    border: none !important;
  }
  .font-size-inherit > .btn-link {
    font-size: inherit;
  }
  .r-ss-trigger .r-ss-selection-clear {
    font-size: 12px;
  }
  .lock-button {
    margin-left: 15px;
  }
  .filter-section .form-row {
    margin-left: 0;
  }
  .ssu-filter .form-group:first-child {
    padding-left: 0;
  }
}
