.etc-body-navigation{
  line-height: 0;
  position: absolute;
  bottom: -8px;
  left: 10px;
  display: flex;
  justify-content: space-between;
  .etc-body-navigation-item{
    background-color: #B7B7B7;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 7.5px;
    cursor: pointer;
    &.active{
      background-color: #691E44;
    }
  }
  .etc-body-navigation-button-next{
    font-size: 24px;
    position: relative;
    top: 9px;
    cursor: pointer;
  }
}
