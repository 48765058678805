@import "src/scss/includes";
.ledger-event-info-history-modal {
  height: 50px;
  background: $white;
  border-bottom: 1px solid $gray10;
  width: 100%;
  display: flex;
  padding-left: 24px;
  align-items: center;
  .header {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: $gray85;
    padding-right: 53px;
    padding-left: 0;
  }
  .info-item {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    padding-right: 20px;
    .info-item-title {
      color: rgba($black, 0.8);
      opacity: 0.6;
      padding-right: 5px;
    }
    .info-item-content {
      color: $gray85;
    }
  }
}
