@import "src/scss/includes";
.account-card {
  min-width: 300px;
  width: auto;
  padding: 16px;
  position: relative;
  height: 226px;
  .card-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 106px;
    height: 88px;
    top: 50px;
    right: 16px;
    z-index: 2;
    background-color: white;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    >div {
      font-size: 16px;
      cursor: pointer;
      height: 36px;
      padding: 6px 16px;
      &:hover {
        background-color: $gray5;
      }
      &.disabled {
        color: $gray30;
        cursor: initial;
      }
    }
  }
  .account-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray60;
    margin-bottom: 10px;
    .header {
      font-family: $ffRoboto;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
  }
  .information {
    font-family: $ffRoboto;
    font-size: 14px;
    color: $gray60;
    line-height: 20px;
    &.email {
      color: $darkBlue100;
    }
    &.phone {
      margin: 16px 0;
    }
  }
  .information-header {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
  }
}