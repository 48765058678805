@import "src/scss/includes";

.eds-textarea.eds-textarea-legacy {
  >label {
    > .eds-textarea-outer {
      border-color: $gray15;
      border-radius: 0;
      &.eds-textarea-underlined {
        border-left: 3px solid $red100;
        border-bottom-color: $gray15;
      }
    }
    > textarea {
      &:focus {
        + .eds-textarea-outer {
          border-bottom-color: $gray15;
          border-bottom-width: 1px;
          background-color: $white;
        }
      }

    }
  }
}