@import "src/scss/includes";
.eds-item-group-procedure {
  .procedure-comment {
    padding: 24px 100px 15px 15px;
    border-top: 1px solid $gray15;
    margin-top: -1px;
  }
  .eds-data-about-filling {
    padding-bottom: 16px;

    span {
      padding: 16px;
      display: inline-flex;
    }
  }
}
