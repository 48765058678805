@import "src/scss/includes";

.fontweight500 {
  font-weight: 500;
}
.eds-modal-box.encounter-detail-modal .eds-modal-box-container {
  .edm-from-visit {
    padding-top: 10px;
    h5 {
      font-size: .9rem;
      margin: 0;
      padding: 15px 0 10px 0;
    }
    .eds-select, .r-ss-wrap  {
      min-width: 100px;
    }
  }
  .time-range-validation {
    font-weight: 600;
    color: $elligoRed;
  }
}
