@import "src/scss/includes";

.eds-users-select {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  position: relative;

  .user-info{
    .label{
      font-size: 12px;
      line-height: 12px;
      color: $gray60;
    }
    .name{
      font-size: 14px;
      line-height: 21px;
    }
  }

  .r-ss-wrap{
    .r-ss-trigger{
      padding: 0;
      border: 1px solid $gray15!important;
      border-radius: 3px!important;
      color: $purple80;
      font-weight: 600;
      text-align: center;
      line-height: 32px;

      .carat{
        display: none!important;
      }

      .r-ss-tag{
        display: none;
      }
    }
    .r-ss-dropdown{
      top: 40px;
      .r-ss-dropdown-option{
        padding: 8px 14px 4px 14px!important;
      }
    }
  }

  .selected-groups-container {
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    max-width: 500px;

    .selected-group {
      .group-label {
        font-size: 12px;
        line-height: 40px;
      }

      .group-item {
        font-size: 14px;
        padding: 8px 9px 8px 12px;
        display: flex;
        justify-content: space-between;
        border: 1px solid $gray15;
        border-radius: 3px;
        background-color: $gray2;

        .eds-icon-btn {
          font-size: 24px;
          color: $gray15;
          display: none;
          &:focus{
            outline: none;
          }
          &:hover{
            color: $gray60;
          }
        }

        .button-container{
          min-width: 18px;
          max-width: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          background-color: $gray5;
          .eds-icon-btn {
            display: block;
            color: $gray60;
          }
        }

        &.mobile{
          .eds-icon-btn {
            display: block;
          }
        }
      }
    }
  }
}
