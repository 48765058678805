@import "src/scss/includes";

label.eui-input-selectors {
  cursor: pointer;
  margin: 0 7px;
  padding: 3px 0;
  position: relative;

  > span {
    display: block;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    color: $black;
    padding-left: 25px;
  }

  > input {
    &:checked {
      + span {
        em:before {
          background-color: var(--eui-input-color, $purple80);
        }
      }
    }

    + span {
      em {
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        display: block;
        border: 1px solid var(--eui-input-color, $purple80);

        &:before {
          background-color: transparent;
          position: absolute;
        }
      }
    }
  }

  &.eui-input-selector-disabled {
    cursor: not-allowed;

    > input {
      &:checked {
        + span {
          em {
            border-color: $gray30;

            &:before {
              background-color: $gray30;
            }
          }
        }
      }
    }

    > span {
      user-select: none;
      color: $gray30;
      em {
        border-color: $gray30;
      }
    }
  }

  &.eui-input-selectors-without-label {
    width: 18px;
    height: 18px;
    position: relative;
    span {
      position: static;
    }
  }
}

