@import "src/scss/includes";
.eds-fv-csv-viewer {
  background-color: $white;
  height: 100%;
  .ReactTable {
    height: 100%;
    .rt-thead, .rt-tbody {
      overflow-y: scroll;
    }
  }
}
