@import "helpers";
@import "src/scss/includes";

.eui-btn {
  background-color: transparent;
  padding: 0;
  color: inherit;
  text-decoration: inherit;
  letter-spacing: 0.02em;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  .eui-btn-text{
    flex-grow: 1;
  }
  &:focus, &:active {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.eui-btn-priority-high {
    @include buttonPriority(($purple80, $purple80, $white), ($purple75, $purple75, $white), ($purple30, $purple30, $white));
  }

  &.eui-btn-priority-medium {
    @include buttonPriority(($white, $gray15, $purple80), ($gray7, $gray15, $purple80), ($white, $gray15, $purple30))
  }

  &.eui-btn-priority-low {
    @include buttonPriority((transparent, transparent, $purple80), ($gray7, $gray7, $purple80), (transparent, transparent, $purple30))
  }

  &.eui-btn-size-h56 {
    font-size: 18px;
    padding: 0 14px;
    height: 56px;
    line-height: 56px;
    @include buttonContent(15px, -5px, 24px, 5px);
  }

  &.eui-btn-size-h40 {
    font-size: 16px;
    padding: 0 9px;
    height: 40px;
    line-height: 40px;
    @include buttonContent(5px, 2px, 24px, 4px);
  }

  &.eui-btn-size-h28 {
    font-size: 14px;
    padding: 0 5px;
    height: 28px;
    line-height: 28px;
    @include buttonContent(4px, 1px, 16px, 2.5px);
  }

  &.eui-btn-icon-only {
    &.eui-btn-size-h56 {
      padding: 0;
      i.eui-icon {
        margin: 15px;
      }
    }

    &.eui-btn-size-h40 {
      padding: 0;
      i.eui-icon {
        margin: 7px;
      }
    }

    &.eui-btn-size-h28 {
      padding: 0;
      i.eui-icon {
        margin: 5px;
      }
    }
  }

  &.eui-btn-loading {
    position: relative;

    .eui-btn-content-wrapper {
      display: inline-flex;
      visibility: hidden;
    }

    .spinner-border {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 50%;
      margin-left: -10px;
    }
  }
}
