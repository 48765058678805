@import "src/scss/includes";

.work-list-page {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
  height: calc(100vh - 125px);

  .patient-preferred-name {
    font-weight: 300;
    color: $gray70;
  }
}
