@import "src/scss/includes";

$hint-marks: (
        "read": #71C108,
        "edit": #31E1B2,
        "sign": #30B077,
        "deny": $red70
);

.groups-setup-table-cell {
  width: 89px;
  height: 44px;
  position: relative;
  z-index: 0;
  background-color: #f2f2f2;

  .groups-setup-table-cell-status {
    line-height: 45px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;

    &.groups-setup-table-cell-status-read-only {
      opacity: 1 !important;
    }
  }

  .groups-setup-table-cell-actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    .groups-setup-table-cell-action {
      width: 30px;
      height: 30px;
      font-size: 20px;
      cursor: pointer;
      text-align: center;
    }
  }

  @each $hint-marks, $mark-color in $hint-marks {
    &.groups-setup-table-cell-#{$hint-marks} {
      background-color: $mark-color;
    }
  }

  &:hover {
    .groups-setup-table-cell-actions {
      opacity: 1;
    }

    .groups-setup-table-cell-status {
      opacity: 0;
    }
  }
}
