@import "src/scss/includes";
.eds-item-group-data-table {
  overflow-y:auto;
  table {
    tr {
      th, td {
        text-align: center;

        &:first-child {
          width: 50px
        }
      }
    }
    tbody{
      tr {
        cursor: pointer;
        &:hover {
          background-color: $purple20;
        }
      }
    }
  }
}
