.missed-events-calendars{
  margin-bottom: 22px;
  display: flex;
  align-items: flex-start;
  .mec-search-button, .mec-calendar{
    padding-right: 21px;
  }
  .mec-calendar{
    width: 320px;
  }
}
