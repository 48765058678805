.eds-task-container.eds-task-container-view {

  .etc-body {
    .etc-body-details{
      .etc-body-details-block-value {

        .task-link {
          color: #2465C6;
          cursor: pointer;
          display: flex;
          align-items: center;

          .eui-icon {
            font-size: 19px;
          }
        }

        .eui-date-picker-legacy > div {
            .eui-input-face {
              border: none;
            }

            .eui-input {
              > label {
                > input {
                  width: 100px;
                  border-radius: 0;
                  padding-bottom: 5px;
                  box-sizing: content-box;

                  &:hover {
                    border-bottom: 1px solid grey;
                  }
                }

                .eui-input-prefix {
                  display: none;
                }
              }
            }
          }
      }
    }
  }
}
