@import "src/scss/includes";

.table-item-group {
  display: flex;
  height: calc(100% - 60px);
  flex-direction: column;
  .table-item-group-section-headline {
    background: #F3F3F3;
    padding: 8px 16px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    position: sticky;
    left: 0;
  }
  .table-item-group-scroll {
    padding-bottom: 150px;
    overflow: auto;
  }
}

.eds-modal-box.pre-save-items-modal {
  .eds-modal-box-container {
    max-width: 900px;
    .eds-modal-box-body {
      max-height: 500px;
      overflow-y: auto;

      .pre-save-items-modal-info {
        align-items: center;
        display: flex;
        padding-bottom: 20px;

        i.eui-icon {
          font-size: 24px;
          color: $green80;
          transform: rotate(180deg);
        }

        .pre-save-items-modal-warning-message {
          font-size: 16px;
          line-height: 150%;
          margin-left: 12px;
        }
      }

      .pre-save-items-modal-section {
        padding-bottom: 20px;
        > h4 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 30px;
        }
      }

      .pre-save-items-modal-group {
        border: 1px solid #DBDBDB;
        margin-bottom: 20px;
        padding: 20px;
        > h5 {
          margin-bottom: 20px;
        }
        .pre-save-items-modal-group-item {
          padding-bottom: 20px;
          > h6 {
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
      }

      .pre-save-items-modal-change-reason {
        max-width: 500px;
        .eds-textarea-validation-message {
          display: none;
        }
      }
    }
    .eds-modal-box-footer {
      align-items: center;
      .pre-save-items-modal-counter {
        padding-right: 20px;
        font-weight: 400;
        font-size: 14px;
      }
      .eui-btn {
        width: 132px
      }
    }
  }
}
