@import "src/scss/includes";

.eds-review-page {
  display: flex;
  height: calc(100% - 54px - 34px - 37px - 46px - 36px);
  width: 100%;
  padding: 0 24px;
  position: absolute;
  margin-top: -15px;
  .eds-review-page-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    > .eds-review-page-body {
      flex: 1 1;
      display: flex;
      overflow: hidden;
      border-left: 1px solid $gray10;
      .eds-main-content{
        flex: 1 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-left: -17px;
      }
    }
  }
}
