@import "src/scss/includes";

.eui-preferences{
  section {
    border: 1px solid $gray10;
    h5{
      font-weight: 300;
      line-height: 30px;
      font-size: 20px;
    }
    .header-section{
      display: flex;
      border-bottom: 1px solid $gray10;
      justify-content: space-between;
    }
    .section-label{
      font-size: 16px;
      color: $purple80;
    }
    .preference{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .name{
        font-weight: bold;
      }
      .description{
        .eui-toggle {
          .eui-toggle-control {
            min-width: 36px;
          }
        }
      }
    }
    .preferences-message{
      background-color: $yellow10;
    }
  }
}
