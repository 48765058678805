@import "src/scss/includes";

.log-check-preview{
  .log-check-items-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .log-check-item{
      max-width: 34%;
      border: 2px solid $blue10;
      border-left: none;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: $purple80;
      position: relative;

      &:first-child{
        border-left: 2px solid $blue10;
      }

      .note{
        font-style: italic;
        bottom: 15px;
        position: absolute;
        color: $gray50;
      }
    }
  }
}
