@import "src/scss/includes";

.collapse-list{
  display: flex;
  flex-wrap: wrap;
  .collapse-item {
    color: $gray70;
    background-color: $gray15;
    border: 1px solid $gray30;
    border-radius: 20px;
    padding: 1px 12px 1px 12px;
    margin: 0 24px 16px 0;
    cursor: pointer;
    &.collapse-active {
      color: $white;
      background-color: $purple75;
      border: 1px solid $purple75;
    }
    .not-empty-mapping {
      color: $purple80;
      font-weight: 800;
    }
  }
}
