.with-auto-direction{
  &.reverse{
    .rdtPicker{
      bottom: 100%;
    }
    .r-ss-dropdown{
      bottom: 100%;
      top: auto;
    }
  }
}
