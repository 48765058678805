@import "src/scss/includes";

label.eui-input-selectors.eui-checkbox {
  > input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;

    &:checked {
      + span {
        em {
          border: 0;
          &:before {
            border-radius: 3px;
            content: 'done';
          }
        }
      }
    }

    &[indeterminate="true"] {
      + span > em {
        &:before {
          background-color: var(--eui-input-color, $purple80);
          content: 'remove';
        }
      }
    }

    &:disabled {
      &[indeterminate='true'] {
        + span > em::before {
          background-color: $gray30;
        }
      }
    }
  }

  > span em {
      border-radius: 3px;

      &:before {
        width: 100%;
        height: 100%;
        text-transform: lowercase;
        font-size: 12px;
        line-height: 16px;
        color: white;
        font-family: $ffMaterialIcons;
        font-weight: bold;
        display: flex;
        justify-content: center;
        font-style: normal;
        align-items: center;
      }
    }
}
