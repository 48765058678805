@import "src/scss/includes";

.eds-review-item-group-element-table-reflection {
  .eds-general-data-about-filling {
    padding-bottom: 16px;
    span {
      padding: 16px;
      display: inline-flex;
    }
  }
}

.overview-title {
  display: flex;
  justify-content: space-between;

  .overview-history {
    margin-right: 50px;
  }

  .overview-history-link {
    font-style: oblique;
    text-decoration: underline;
    color: $purple80;
    cursor: pointer;
  }
}
