.load-patients {
}

.load-patients .custom-file-label {
  height: 35px;
}

.load-patients .custom-file-label:after {
  height: 34px;
  padding: 0.35rem 0.75rem;
}

