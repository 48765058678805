@import "src/scss/includes";

.log-check-edit{
  width: 100%;
  position: relative;

  .log-ig-buttons{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 15px;
    padding-top: 24px;
    padding-bottom: 10px;
    .log-check-button{
      margin-left: 15px;
      height: 26px;
      font-size: 14px;
    }
  }
  .rc-collapse .rc-collapse-item .rc-collapse-header{
    padding-left: 15px;
    min-height: 70px;
  }

  .rc-collapse-content-active{
    border-top: none!important;
    position: relative;
  }
  .eds-editor-date-select{
    position: absolute;
    z-index: 1;
    right: 50px;
    margin-top: 8px;
  }

  .log-check-items-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .log-check-item{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: $purple80;
      position: relative;

      &.bordered{
        border: 2px solid $blue10;
        border-left: none;
        &:first-child{
          border-left: 2px solid $blue10;
        }
      }

      .check-box-label{
        cursor: pointer;
        &.disabled{
          color: $gray50;
          cursor: default;
        }
      }

      &.background{
        background-color: $blue10;
      }

      .note{
        font-style: italic;
        bottom: 15px;
        position: absolute;
        color: $gray50;
      }
    }
  }

  .log-check-footer{
    width: 100%;
    .text-danger{
      display: inline-block;
    }
    .eui-btn{
      width: 118px;
      float: right;
    }
  }
  .r-ss-wrap{
    height: fit-content;
  }
}
