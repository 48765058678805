@import "src/scss/includes";

.eds-calendar-big {
  width: 100%;
  position: relative;

  .no-wrap {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .rbc-calendar {
    width: 100%;

    .rbc-allday-cell {
      display: none;
    }
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid transparent;
  }

  .rbc-current-time-indicator {
    background-color: $red100;
  }

  .eds-calendar-time-range {
    border-radius: 0;
    opacity: 0.3;

    .rbc-addons-dnd-resizable {
      display: none !important;
    }
  }

  .rbc-time-content {
    border: none;
  }

  .rbc-today {
    background-color: transparent;
  }

  .rbc-day-slot {
    background-color: $white;

    .rbc-events-container {
      margin-right: 0;
    }
  }

  .rbc-time-header.rbc-overflowing {
    border-right: none;
  }

  .rbc-time-header-content {
    border-left: 1px solid transparent;
    border-bottom: 1px solid $gray15;
  }

  .rbc-time-view {
    border: 1px solid transparent;
  }

  .rbc-header {
    border: 1px solid transparent;
  }

  .rbc-header + .rbc-header {
    border: 1px solid transparent;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid transparent;
  }

  .rbc-time-gutter.rbc-time-column {
    .rbc-timeslot-group {
      border: none;
    }
  }

  .rbc-time-view .rbc-row {
    min-height: 26px;
  }

  .rbc-row-segment {
    position: relative;

    .rbc-event {
      position: absolute;
    }
  }

  .rbc-timeslot-group {
    min-height: 100px;
  }

  .eds-calendar {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .inactive {
    background-color: $gray2;
  }

  .encounter-time-range-slot {
    display: flex;
    flex-grow: 1;
    position: relative;

    .encounter-time-range-sub-slot {
      background-color: $blue50;
      position: absolute;
      height: 100%;
      opacity: 0.3;
      width: 100%;
      left: 0;
    }
  }
}
