@import "src/scss/includes";

.eds-modal-box.pre-save-items-modal {
  .eds-modal-box-container {
    .eds-modal-box-body {
      .pre-save-items-modal-group-fields {
        display: flex;
        justify-content: space-between;
        padding-bottom: 23px;
        .pre-save-items-modal-change-performed-status {
          padding-right: 20px;
          flex-wrap: nowrap;
          .eds-toggle-select {
            margin-left: -15px;
          }
        }
        .pre-save-items-modal-change-reason {
          flex: 1 1;
        }
      }
    }
  }
}
