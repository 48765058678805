@import "src/scss/includes";

.eds-btn-radio-group {
  display: inline-flex;
  .eui-btn{
    margin: 0;
    border-radius: 0;
    border-left: none;
    &:first-child{
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      border-left: 1px solid $gray15;
    }
    &:last-child{
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }
    &.eui-btn-priority-high{
      border-color: $purple80;
      &:hover, &:active, &:focus {
        border-radius: 0;
      }
    }
  }

}
