@import "src/scss/includes";
.eds-card-balance {
  position: relative;
  .eds-card-balance-tooltip {
    position: absolute;
    align-items: center;
    top: 100%;
    background-color: $white;
    box-shadow: 0 1px 3px rgba($black,0.12), 0 1px 2px rgba($black,0.24);
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    transform: translateX(-50%);
    left: 50%;
    min-width: 60px;
    color: $purple75;
    white-space: nowrap;
    z-index: 100;
    .spinner-border {
      width: 22px;
      height: 22px;
      border: 2px solid $purple80;
      border-right-color: transparent;
    }
  }
}
