@import 'src/scss/includes';

.read-only-cell-text-field {
  font-size: 14px;
  padding: 0 0 0 10px;
  margin: 10px -5px 14px -5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
}
