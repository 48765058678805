.patient-profile {
  .patient-profile-information-content-section {
    &:not(:first-child) {
      margin-top: 40px;
    }

    .patient-profile-information-header {
      height: 35px;
      display: flex;
      align-items: center;

      .patient-profile-information-header-title {
        font-weight: 500;
        font-size: 16px;
      }

      .patient-profile-information-header-edit {
        .eds-btn-with-icon i {
            border-color: transparent;
            color: #2465C6;
        }
        > button{
          padding: 0;
          color: #2465C6;
          border: none;
          background-color: inherit;
          margin-left: 7px;
          &[disabled]{
            color: gray;
          }
          &:hover &:focus{
            color: #2465C6;
          }
          .eui-icon{
            margin: 0;
          }
        }
      }
    }

    .patient-profile-information-data-row {
      display: flex;
      align-items: center;
      margin-top: 15px;

      // TODO: Make textarea change more specific to instructions section.
      .eds-textarea {
        flex: 0 0 440px;
      }

      .patient-profile-information-label {
        display: flex;

        .patient-profile-information-label-field + div {
          margin-left: 10px;
          font-weight: 600;
        }
      }

      .patient-profile-information-label ~ div {
        margin-left: 30px;
      }

      .patient-profile-information-highlighted-field {
        font-weight: 500;
        color: #fc1111;
        font-size: 18px;
      }

      .patient-profile-information-deceased-chip {
        width: 82px;
        height: 27px;
        padding: 2px 10px;

        display: flex;
        justify-content: center;

        margin-left: 15px;
        background-color: #E7E7E7;
        border-radius: 27px;
      }
    }
  }
}
