@import "src/scss/includes";

.final-option-list {
  display: flex;
  margin-top: 20px;
  .new-option-label {
    font-size: 17px;
    min-width: 200px;
  }
  .option-list {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    min-height: 60px;
    max-height: 394px;
    overflow-y: auto;
    margin-left: 30px;
    border: 1px solid $gray10;
    border-bottom-color: $purple75 ;
    .option-wrapper {
      display: flex;
      flex-direction: column;
      width: 397px;
      height: auto;
      border: 1px solid $gray5;
      border-collapse: collapse;
      cursor: grab;
      &:hover, &.dragging {
        border: 1px dashed $blue100;
        background-color: $gray5;
      }
      .option-preview {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 20px;
        .button-wrapper {
          display: flex;
          .delete-option-item {
            cursor: pointer;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            background-image: url("../../../../../../../../../../images/icons/delete-icon.svg");
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
      .edit-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .option-edit {
          width: 300px;
        }
      }
    }
  }
}
