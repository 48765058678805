@import "src/scss/includes";

@mixin listWrapper {
  display: flex;
  flex-direction: column;
  min-height: 46px;
  margin-top: 22px;
  border: 1px dashed $purple80;
}
@mixin listItemWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 46px;
  cursor: pointer;
  border: 1px solid $gray5;
  border-collapse: collapse;
}

@mixin listItemName {
  flex-grow: 1;
  padding: 10px 15px;
}

@mixin listDeleteIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 10px 15px;
  background-image: url("../../../../../../../images/icons/delete-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.setting-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-top: 16px;
  .setting-header {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: $gray5;
    h5 {
      margin: 0;
      color: $purple75;
    }
  }
  .setting-body {
    padding: 30px;
    .questions-layout {
      display: flex;
      align-items: center;
      span {
        margin-right: 29px;
      }
      .radio {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width:18px;
        min-height: 18px;
        margin: 12px;
        border: 1.5px solid $purple80;
        border-radius: 50%;
        cursor: pointer;
        &.active {
          &:after {
            content: ("");
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $purple80;
          }

        }
      }
    }
    .title-input {
      min-width: 150px;
      max-width: 400px;
    }
    .label-for-input {
      position: relative;
      top: 40px;
    }
    .add-label-wrapper {
      display: flex;
      width: 100%;
      margin-top: 50px;
      justify-content: space-between;
      .label-input {
        min-width: 150px;
        max-width: 1215px;
        flex-grow: 1;
        white-space: nowrap;
      }
      .add-button {
        margin-left: 20px;
      }
    }
    .add-label-description {
      color: $gray50;
      position: relative;
      top: -20px;
    }
    .label-list {
      @include listWrapper;
      .label-wrapper {
        @include listItemWrapper;
        &:hover, &.dragging {
          background-color: $gray5;
          border: 1px solid $gray10;
        }
        .label-item {
          @include listItemName;
        }
        .delete-label-item {
          @include listDeleteIcon;
        }
      }
    }
  }
}