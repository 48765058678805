button.eui-btn.erc-item-group-element-confirm-button {
  font-weight: bold;
  min-width: 134px;
  text-align: left;
  padding: 0 12px;
  height: 34px;
  color: #575757 !important;
  font-size: 14px;

  .material-icons {
    font-size: 20px;
    margin-right: 5px;
    color: #2465C6 !important;
  }

  &:disabled {
    color: rgba(#575757, .4);

    .material-icons {
      color: rgba(#2465C6, .4);
    }
  }
}
