@import "src/scss/includes";

.load-patients-result .lpr-blocks {
  display: flex;
  margin-bottom: 15px;
}

.load-patients-result .lpr-blocks .lpr-block {
  width: 150px;
  border: 1px solid $purple80;
  margin-right: 5px;
}

.load-patients-result .lpr-blocks .lpr-block .lpr-block-title {
  background-color: $purple100;
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 12px;
  color: $white;
  padding: 5px 0;
}

.load-patients-result .lpr-blocks .lpr-block .lpr-chart-wrapper {
  padding-top: 28px;
}

.load-patients-result .lpr-blocks .lpr-block .lpr-chart {
  height: 100px;
  width: 70px;
  position: relative;
  margin: 0 auto;
}

.load-patients-result .lpr-blocks .lpr-block .lpr-chart .lpr-chart-block {
  background-color: $purple80;
  min-height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease 0s;
}

.load-patients-result .lpr-blocks .lpr-block .lpr-chart .lpr-chart-block > span {
  color: $purple80;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: -15px;
  display: block;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 100;
}

.load-patients-result .ReactTable .failure-reason {
  background-color: $purple50;
  color: $white;
  padding: 0 5px;
  margin-right: 5px;
}
