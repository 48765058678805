.commenthover {
  cursor: pointer;
  .wordcss {
    display: -webkit-box;
    max-width: 400px;
    margin: 0 auto;
    line-height: 1.5;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:pre-line;
  }
}