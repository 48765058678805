@import "src/scss/includes";

.budget-details-page {
  display: flex;
  overflow: auto;
  flex-direction: column;
  height: calc(100vh - 125px);

  .budget-detail-top {
    display: flex;
    justify-content: space-between;
  }

  .budget-event-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    min-height: 800px;
    overflow: hidden;

    .budget-event-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 15px 0 15px;

      h5 {
        min-width: 112px;
      }

      .table-header-buttons {
        flex-wrap: wrap;
        justify-content: flex-end;

        .eui-btn {
          margin: 8px;
        }
      }
    }
  }
}
