.eds-modal-box {
  &.add-new-study-modal {
    .eds-modal-box-container {
      .eds-modal-box-footer {
        .ansm-button {
          width: 130px;
          &:first-child{
            margin-right: 15px;
          }
          &:last-child{
            margin-left: 15px;
          }
        }
      }
    }
  }
}
