@import "src/scss/includes";
@import "src/components/pages/setup/shared/ElementSetupNew/TableSetup/TableSettings/TableSettings";

@mixin standardLabel {
  margin: 0 16px 23px 0;
  font-size: 15px;
}
@mixin standardSelect {
  width: 100%;
  max-width: 400px;
  min-width: 210px;
}

.platform-configuration-modal {
  .eds-modal-box-body {
    display: flex;
    flex-direction: column;
    height: 74vh;
    //overflow: auto;
    .endpoint-label {
      @include standardLabel;
    }
    .endpoint-input {
      @include standardSelect;
      margin-bottom: 23px;
    }
    .endpoint-select {
      @include standardSelect;
    }
    .name-configuration {
      display: flex;
      align-items: center;
      .platform-enabled {
        display: flex;
        margin: 0 0 23px 24px;
        justify-self: end;
        span {
          font-size: 15px;
        }
      }
    }
    .platform-configuration {
      .url-configuration {
        display: flex;
        align-items: center;
        .endpoint-input {
          margin: 0 16px 23px 16px;
        }
      }
      .authentication-configuration {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .authentication-wrapper {
          display: flex;
          width: 835px;
          .endpoint-input {
            margin-right: 16px;
          }
        }
        .endpoint-label {
          margin-bottom: 0;
          color: $red100;
        }
        .test-auth-button {
          margin-bottom: 23px;
        }
      }
    }
    .endpoint-configuration {
      overflow: auto;
      height: 100%;
      .add-url-button {
        margin: 24px 24px 24px 0;
      }
      .url-item-wrapper {
        display: flex;
        .endpoint-input, .endpoint-select {
          margin: 0 16px 0 0;
        }
        .delete-url-icon {
          @include listDeleteIcon;
          min-width: 20px;
          margin-top: 16px;
        }
        .url-enabled {
          min-width: 120px;
          margin-top: 16px;
          span {
            font-size: 15px;
          }
        }
      }
    }
  }
}