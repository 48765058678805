.eds-modal-box.add-or-edit-patient-info-modal {
  .add-or-edit-patient-info-modal-race-ethnicity-select {
    .r-ss-dropdown-options {
      #id_aria_option_0[data-option-value="Select All"] {
        display: none;
      }
    }
  }

  .add-or-edit-patient-info-modal-body-index-label {
    font-size: 16px;
  }
}
