@import "src/scss/includes";

.patient-profile-medications{

  .patient-profile-medications-header{
    //padding-top: 20px; Solve padding-top issue due to container padding
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h5{
      color: black;
      margin-right: 15px;
    }
    button{
      padding: 0;
      margin-bottom: 7px;
      color: #2465C6;
      border: none;
      background-color: inherit;
      &:hover{
        color: #2465C6;
      }

      &:focus{
        color: #2465C6;
      }


      .eui-icon{
        margin: 0 !important;
      }

      .eui-btn-text{
        margin-left: 0 !important;
      }
    }
  }
  .patient-profile-medications-table{
    .ReactTable .rt-tbody,
    .ReactTable .rt-tr-group .rt-tr .rt-td {
      overflow: initial;
    }
  }
}








