.eds-modal-box.eds-review-sign-modal-box {
  .eds-modal-box-container {
    top: 20%;
    max-height: 495px;
    display: flex;
    flex-direction: column;
    .eds-modal-box-body{
      overflow-y: auto;
    }

    .eui-input:not(:first-child) {
        margin-top: 10px;
    }
  }
}
