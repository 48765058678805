@import "src/scss/includes";

.add-ledger-footer-control {
  background: $white;
  box-shadow: 0px -4px 3px $gray2;
  height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  > div {
    display: flex;
    align-items: center;

    b {
      font-size: 15px;
      margin-bottom: 6px;
      margin-right: 42px;
    }
  }
}
