@import "src/scss/includes";

.eui-file-upload-list {
  background-color: $gray5;
  border-radius: 4px;
  padding: 12px;
  .eui-file-upload-list-file {
    margin-top: 12px;
    height: 60px;
    background-color: $white;
    border-radius: 4px;
    position: relative;
    display: flex;
    border: 1px dashed $gray30;
    cursor: pointer;
    > .MuiIconButton-root {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -10px;
      cursor: pointer;
    }
    .eui-file-upload-list-file-icon {
      flex: 0 0 60px;
      padding: 18px;
      border-right: 1px dashed $gray30;
      overflow: hidden;
      position: relative;
      > .MuiSvgIcon-root {

      }
      > img {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        margin: 0;
        transform: translateY(-50%);
      }
    }
    .eui-file-upload-list-file-container {
      flex: 1;
      padding: 6px 12px;
    }
    &.eui-file-upload-list-file-active {
      border-color: $gray70;
      .eui-file-upload-list-file-icon {
        border-color: $gray70;
      }
    }
  }
}
