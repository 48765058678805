@import "src/scss/includes";

.item-groups-encounters-table-header {
  display: flex;
  position: absolute;
  top: -76px;
  left: -300px;
  right: 0;
  bottom: 0;

  .item-groups-encounters-table-header-main {
    flex: 0 0 300px;
    height: 76px;

    background-color: $gray2;
    border-right: 1px solid $gray10;
    border-bottom: 1px solid $gray10;

    position: sticky;
    left: -300px;
    top: -76px;
    z-index: 3;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 15px;
  }
  .item-groups-encounters-table-header-epoch {
    position: sticky;
    top: -76px;
    z-index: 2;
    height: 76px;
    background-color: $white;
    .item-groups-encounters-table-header-epoch-title {
      height: 38px;
      background-color: $gray2;
      border-right: 1px solid $gray10;
      border-bottom: 1px solid $gray10;
      position: relative;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        padding: 0 5px;

        line-height: 37px;
        text-align: center;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .item-groups-encounters-table-header-epoch-encounters {
      display: flex;
      .item-groups-encounters-table-header-epoch-encounter {
        flex: 0 0 90px;
        height: 38px;
        border-right: 1px solid $gray10;
        border-bottom: 1px solid $gray10;
      }
    }
  }
}
