.encounter-component-wrap {
  .shadows-enc {
    position: absolute;
    width: 24px;
    height: 28px;
    opacity: 0.8;
    border-radius: 3px;
  }

  .data-preview{
    bottom: 2px;
    z-index: -1;
    left: 7px;
  }
}
