@import "src/scss/includes";

.reviews-sidebar-block {
  h5 {
    padding-left: 16px;
    margin-top: 24px;
    @media (max-width: 1280px) {
      padding-left: 8px;
    }
  }

  .encounter-item-group {
    .details-container {
      color: $gray70;

      > div {
        padding: 8px;
      }

      .reviews-sidebar-block-progress {
        background-color: $purple80;
        width: 40px;
        height: 10px;
        display: inline-block;
        margin-right: 8px;

      }
    }

    &.reviews-sidebar-block-completed {
      .details-container {
        .reviews-sidebar-block-progress {
          background-color: $green100;
        }
      }
    }

    &.reviews-sidebar-block-for-current-user {
      .details-container {
        .item-group-name {
          font-weight: 600;
        }
      }
    }
  }
}
