.editor-container{
  .toolbar {
    display: flex;
    align-items: center;
    padding: 4px 16px;
    flex-wrap: wrap;
    gap: 12px;
    .editor-header-divider {
      flex-grow: 1;
    }
  }
}