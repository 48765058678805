@use 'sass:math';
@import "src/scss/includes";


.eds-select.eds-select-type-default.eds-select-legacy {
  min-width: 206px;

  .eds-select-validation-message {
    display: none;
  }

  &.eds-select-required {
    .r-ss-trigger {
      border-left: 3px solid $red100;
      border-bottom-color: $gray15;
    }
  }

  .r-ss-trigger {
    border-radius: 0;
    height: 36px;
    padding-top: 7px !important;
    padding-left: 7px;
    padding-bottom: 7px;
    color: $gray85;

    &.r-ss-disabled {
      color: $gray85;
    }

    &.r-ss-open {
      border-bottom: 1px solid $gray10;
      background-color: $white;
    }

    .carat {
      bottom: 16px;

      &.up {
        bottom: 14px !important;
      }

      &.down {
        bottom: 9px !important;
      }
    }
  }

  label {
    top: 2px !important;
  }

  .r-ss-selection-clear {
    top: 7px !important;
  }

  .r-ss-wrap {
    .count {
      top: 7px;
    }
  }
}
