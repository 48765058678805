@import "src/scss/includes";

.single-file-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  .file-name {
    color: $darkBlue100;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 7px;
    text-decoration: underline;
  }
}