@import "src/scss/includes";



.patient-bulk-update-section-header-tabs {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  .patient-bulk-update-section-header-tabs-group {
    button {
      background-color: transparent;
      border: 1px solid $gray10;
      outline: none;
      padding: 8px 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: inherit;
      &:first-child {
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
      }
      &:first-child:not(:last-child) {
        border-right: none;
      }
      &:last-child:not(:first-child) {
        border-left: none;
      }

      &.active-tab {
        color: $white;
        background-color: $purple80;
        font-weight: 500;
      }
    }
    + .patient-bulk-update-section-header-tabs-group {
      margin-left: 150px;
    }
  }
}
