@import "src/scss/includes";
.eds-invoice {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
  height: calc(100vh - 125px);
  .invoice-section {
    background: $white;
    border: 1px solid $gray10;
    box-sizing: border-box;
    margin: 0 25px 8px 25px;
  }
  .hided-invoice-template {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    visibility: hidden;
    overflow: hidden;
    min-width: 612px;
  }
}
