.ReactTable.cra-review-table {
  .rt-tbody {
    .summary-cell {
      padding: 0;
      display: flex;
      align-items: center;

      .tooltip-cell {
        display: flex;
        padding: 7px 8px;
      }
    }
  }
}

.footpadb {
  .filter-section {
    .border {
      .form-row {
        width: 100% !important;;
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }
}


