@import "src/scss/includes";
.rc-collapse {
  background-color: $white;
  border: 1px solid $gray10;
  border-radius: 0;
  color: $gray85;
  .rc-collapse-item {
    .rc-collapse-header {
      background-color: transparent;
      border-bottom: 1px solid $gray10;
      padding: 10px 115px 10px 20px;
      position: relative;
      color: inherit;
      justify-content: space-between;
      .rc-collapse-header-expand-icon {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -12px;
      }
      .rc-collapse-header-title {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.02em;
        color: $black;
      }
      .rc-collapse-header-content {
        display: flex;
        align-items: center;
      }
    }
    .rc-collapse-content {
      padding: 0;
      color: inherit;
      .rc-collapse-content-box {
        margin: 0;
      }
    }
  }

  .rc-collapse-header:focus{
    outline: none;
  }
}
