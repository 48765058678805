@import "src/scss/includes";

.optional-item-groups-container {

  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  max-height: calc(100vh - 222px);
  height: calc(100vh - 222px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .optional-item-groups-container-description {
    padding: 24px 0 8px 0;
    color: $purple75;
  }

  .optional-setup-table-legacy {
    border-color:$gray30;
    flex: 7;
    .item-groups-encounters-table-header-epoch, .item-groups-encounters-table-sidebar, .item-groups-encounters-table-header-main {
      font-weight: 600;
      color: $purple75;
      line-height: 37px;
    }

    .item-groups-encounters-table-header-main {
      align-items: flex-end;
      border-color: $purple75;
    }

    .item-groups-encounters-table-header-epoch {
      .item-groups-encounters-table-header-epoch-title {
        border-bottom-color: $gray30;
        border-right-color: $gray30;
      }
      .item-groups-encounters-table-header-epoch-encounters {
        .item-groups-encounters-table-header-epoch-encounter {
          border-right-color: $gray30;
          border-bottom-color: $purple75;
          position: relative;
          > div {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 0 5px;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .item-groups-encounters-table-sidebar-item-group {
      border-right-color: $purple75;
      border-bottom-color: $gray30;
    }

    .item-groups-encounters-table-cell-wrapper {
      background-color: $gray10;
      border-bottom: 1px solid $gray30;
      border-right: 1px solid $gray30;
    }
    + .optional-setup-table-legacy {
      margin-top: 20px;
      flex: 3;
    }
  }
}
