@import "src/scss/includes";

.work-progress{
  height: fit-content;
  max-height: 1000px;
  .work-progress-bars{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    .el-container{
      background-color: white;
      line-height: 40px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:hover{
        background-color: $gray7;
        cursor: pointer;
      }

      .percent{
        display: flex;
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
      }
      .bar-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        flex-grow: 1;
        width: 100%;
        padding-right: 10px;
        >div{
          display: flex;
          line-height: 30px;
          align-items: center;
          width: 100%;
          .bar{
            height: 6px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
