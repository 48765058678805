@import "src/scss/includes";

.encounter-check-container{
  border: 1px solid $gray10;

  .encounter-check-header {
    background: $gray5;
    border: 1px solid $gray10;
    color: $purple80;

    h5 {
      margin-top: 6px;
      margin-left: 6px;
    }
  }

  .configured-sections {
    padding-top: 0!important;
  }
}
