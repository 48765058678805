.eds-reimbursement-request {
  .MuiDataGrid-root {
    border: none;
    .MuiDataGrid-row {
      cursor: pointer;
    }
  }
  .reimbursement-request-comment-field {
    flex: 1;
  }
}
