@use "sass:math";
@import "src/scss/includes";

.eds-find-patient-popup-item {
  .custom-option{
    padding: 0 20px;
    display: block;
    .group {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 10px 0;
      border-bottom: 1px solid #c7c7c7;
      .column-item {
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        color: #757575;
        &.xl{
          flex: 1 1 37%
        }
        &.lg{
          flex: 0 0 30%
        }
        &.md{
          flex: 0 0 23%
        }
        &.sm{
          flex: 0 0 13%
        }
        .status{
          display: inline-block;
        }
        .value {
          margin-left: 5px;
          font-size: 13px;
          font-weight: 500;
          line-height: 24px;
          color: #323232;
          white-space: nowrap;
          &.link{
            color: #2465C6;
          }
          .found {
            color: $blue100;
            font-weight: bold;
          }
          .status{
            width: fit-content;
            background: $gray10;
            border-radius: 24px;
            padding: 2px 10px;
            margin-left: 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
