.eds-styled-tooltip {
  .rc-tooltip-inner {
    color: black;
    background-color: white;
  }
  &.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: white;
  }
  &.rc-tooltip-placement-top .rc-tooltip-arrow {
    border-top-color: white;
  }
}
