@import "src/scss/includes";
.erv-review-sidebar {
  background-color: #F8F0F4;
  border-right: 1px solid $gray10;
  border-top: none;
  height: 100%;
  padding-top: 7px;
  overflow-y: auto;
  .erv-review-sidebar-list {
    padding-top: 8px;
    padding-bottom: 8px;
    h4 {
      padding-left: 28px;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      margin: 0;
      padding-bottom: 8px;
    }
  }
}
