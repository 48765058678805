@import "src/scss/includes";

@mixin two-line-text {
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: break-spaces;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.ReactTable.rt-hoc {
  border: none;


  .rt-table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: unset;
  }

  .pagination-bottom {
    position: relative;
    display: flex;
    justify-content: center;

    .-pageSizeOptions {
      position: absolute;
      left: 0;
      top: 13px;
      margin: 0;
    }

    .-pagination {
      float: none;
      margin-top: 5px;
    }

    button {
      background: none;
      padding: 0;

      .eui-icon {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }

  .rt-td {
    white-space: normal;
    align-items: center;
    display: flex;


    .tooltip-container {
      display: flex;
      flex-grow: 1;
      max-width: 100%;
      height: 100%;
      align-items: center;

      .tooltip-cell {
        @include two-line-text;
      }
    }

    a {
      @include two-line-text;
    }

    &.rt-hoc-centered {
      text-align: center;
      justify-content: center;
    }

    &.rt-hoc-pointer {
      cursor: pointer;
    }
  }

  .rt-thead {
    position: sticky;
    top: 0;
    z-index: 222;
    .rt-th {
      overflow: visible;
      color: $gray85;
      height: 60px;
      > div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        >span{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.two-row-header {
            @include two-line-text;
          }
        }
      }
    }
  }

  .rt-thead .rt-tr {
    background-color: $gray5;
  }

  .rt-thead.-header {
    border-bottom: 1px solid $gray15;
  }

  .rt-thead {
    .rt-th.-sort-asc {
      box-shadow: none;

      .eui-icon {
        &:first-child {
          color: $gray85;
        }
      }
    }

    .rt-th.-sort-desc {
      box-shadow: none;

      .eui-icon {
        &:last-child {
          color: $gray85;
        }
      }
    }
  }

  i {
    z-index: 1;
  }
  a{
    color: $darkBlue100;
  }

  .rt-hoc-sort-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    max-width: 100%;
    height: 100%;
    .filter-icon {
      font-size: 24px;
    }
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
    }

    .icons {
      display: flex;
      flex-direction: column;
      width: 18px;

      .eui-icon {
        font-size: 18px;
        font-weight: 600;
        line-height: 12px;
        color: $gray30;
      }
    }
    .r-ss-dropdown {
      overflow-y: visible;
    }
  }
}
