.ReactTable.scheduling-worklist {
  .rt-tbody {
    .scheduling-worklist-summary-column {
      align-items: center;
      display: flex;
      .eui-btn-group {
        padding: 0 8px;
      }
    }
    .rt-td {
      display: flex;
      padding: 7px 8px;
    }
  }
}
.note-value {
  white-space: pre-line;
}
