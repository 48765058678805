@import "src/scss/includes";

.eds-modal-box.edit-controlled-term-list-modal {
  .eds-modal-box-body {
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: auto;
    .new-option-list {
      display: flex;
      align-items: center;
      .new-option-input {
        width: 400px;
        margin: 0 30px;
      }
    }
    .new-option-label {
      font-size: 17px;
      min-width: 220px;
    }
    .final-option-list {
      height: 250px;
      overflow-y: auto;
    }
  }
}
