.eui-file-template {
  position: relative;

  .file-name {
    z-index: 1;
    margin-left: 15px;
    margin-top: 5px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .file-layout {
    display: flex;
    position: absolute;
    top: 18px;
    width: calc(100% - 70px);
    z-index: 1;
  }

  .clear-button-hidden {
    visibility: hidden;
  }
}
