@import "src/scss/includes";

.medical-record-number-column {
  width: 100%;
  .mrn-is-hidden {
    color: $gray30;
  }
  .medical-record-number-field {
    display: flex;
    align-items: center;
    input {
      border: 0;
      border-bottom: 1px solid transparent;
      height: 20px;
      line-height: 20px;
      background-color: transparent;
      outline: 0 none;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .medical-record-number-field-buttons {
      width: 52px;
      display: flex;
      justify-content: flex-end;
      .medical-record-number-field-button-edit {
        opacity: 0;
      }
    }
    &.medical-record-number-field-edit {
      input {
        border-color: $gray10;
        &:focus {
          border-color: $purple75;
        }
      }
    }
  }
}

.rt-td {
  &:hover {
    .medical-record-number-column {
      .medical-record-number-field {
        .medical-record-number-field-buttons {
          .medical-record-number-field-button-edit {
            opacity: 1;
          }
        }
      }
    }
  }
}
