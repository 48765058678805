@import "src/scss/includes";

.appointment-component{
  height: 100%;
  display: flex;
  flex-direction: column;

  .appointment-edit-header{
    padding: 15px 25px 15px 25px;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    position: absolute;
    z-index: 1;
    border-bottom: 1px solid $gray15;
  }

  .appointment-edit-content{
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px 25px 15px 25px;
    flex-grow: 1;
    margin-bottom: 60px;
    margin-top: 60px;

    .eds-select-label{
      width: 230px;
    }

    .eui-input {
      > label {
        > input {
          ~ span.eui-input-label {
            font-size: 15px;
          }
          &:placeholder-shown {
            ~ span.eui-input-label {
              font-size: 12px;
            }
          }
          &:focus {
            ~ span.eui-input-label {
              font-size: 12px;
            }
          }
        }
      }
      &.eui-input-not-empty > label > span.eui-input-label {
        font-size: 12px !important;
      }
    }

    .appointment-view-field {
      padding: 0 14px;
      background-color: $gray2;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid $gray15;
      margin-bottom: 15px;
      min-height: 48px;
    }
  }
  .eds-btn-radio-group{
    button{
      height: 27px;
      border-radius: 0!important;
      font-size: 14px;
      &.eui-btn-priority-high{
        background-color: $gray70;
        border-color: $gray70;
      }
      &.eui-btn-priority-medium{
        color: $gray70;
        background-color: $gray15;
        font-weight: normal;
      }
    }
  }

  .action-buttons{
    width: 100%;
    background-color: $white;
    border-top: 1px solid $gray15;
    height: 60px;
    min-height: 60px;
    padding: 0 25px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    .action-item{
      padding: 10px 5px;
      button{
        font-size: 14px;
        padding: 0 21px;
      }
    }
  }
}

