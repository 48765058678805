@import "src/scss/includes";

.message-and-search-field-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .general-message {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #323232;
  }

  .select-status-message {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #737373;

  }

  .investigator-worklist-search-icon {
    position: absolute;
    left: 15px;
    font-size: 22px;
    top: 8px;
  }
  .investigator-worklist-search-field {
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 50px;
    width: 416px;
    height: 50px;
    padding-left: 50px;
    font-size: 16px;
  }
  .investigator-worklist-search-field::placeholder {
    color: $black !important;
    font-size: 14px;
    opacity: 1;
  }
  .investigator-worklist-search-field:focus {
    outline: none !important;
    border: 2pt solid #828282;
    box-shadow: 0 0 5pt #828282;
  }
}