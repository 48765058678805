.invoice-page-header {
  margin-bottom: 0;
  .invoice-top-section{
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 11px 19px;
    align-items: center;
    .its-amounts{
      > div {
        display: flex;
        flex-direction: row;
        > span {
          padding-right: 5px;
        }
      }
    }
    .its-export{
      margin-right: 20px;
    }
  }
}
