@mixin buttonPriority($default, $active, $disabled) {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;

  background-color: nth($default, 1);
  border-color: nth($default, 2);
  color: nth($default, 3);
  &:active, &:focus, &:hover {
    background-color: nth($active, 1);
    border-color: nth($active, 2);
    color: nth($active, 3);
  }
  &:disabled {
    background-color: nth($disabled, 1);
    border-color: nth($disabled, 2);
    color: nth($disabled, 3);
  }
}

@mixin buttonContent($textMargin, $lastIconMargin, $fontSizeIcon, $iconMargin){
  span.eui-btn-text {
    margin: 0 $textMargin;
    & + i.eui-icon {
        margin-left: $lastIconMargin;
      }
  }
  i.eui-icon {
    font-size: $fontSizeIcon;
    &:last-child {
      margin-left: $iconMargin;
    }

    &:first-child {
      margin-right: $iconMargin;
      & + span.eui-btn-text {
        margin-left: $iconMargin;
      }
    }
  }
}
