@import "src/scss/includes";
.add-ledger-steps-bar {
  margin-right: 42px;

  p {
    margin-top: 3px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
  }

  ul {
    list-style-type: none;
    margin-top: 20px;
    padding: 0;

    li {
      padding: 8px 4px;
      display: flex;
      align-items: center;
      cursor: default;
      user-select: none;
      width: 227px;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 8px;
      color: $gray50;
      .adsb-icon-done {
        font-size: 15px;
        font-family: $ffMaterialIcons;
        font-weight: 600;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        color: $green80;
      }
      .adsb-before-pointer{
       width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .adsb-step {
        margin-left: 14px;
      }
    }

    li.active-step {
      background: $purple20;
      mix-blend-mode: darken;
      font-weight: 500;
      color: $gray85;
    }
  }
}
