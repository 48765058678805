@import "src/scss/includes";

.table-item-group-general {
  position: sticky;
  left: 0;
  z-index: 2;
  .table-item-group-section-sub-headline {
    padding-top: 10px;
  }
  .table-item-group-general-rows {
    padding: 20px 0;
    max-width: 1400px;
    .table-item-group-general-row {
      display: flex;
      padding: 10px 20px;
      align-items: center;
      > div {
        &.table-item-group-general-field-name {
          display: flex;
          align-items: center;
          flex: 3 3;
          &.table-item-group-general-field-name-required:before {
            content: "*";
            font-weight: bold;
            color: $red100;
          }
        }
        &.table-item-group-general-field {
          flex: 2 2;
          .time-field, .toggle-field, .select-field, .text-box-field, .check-box-field,
          .free-text-long-field, .text-block-field, .multi-select-field, .date-field, .file-upload-field {
            width: 375px;
          }
        }
        &.table-item-group-general-required-attention {
          .eui-checkbox {
            display: block;
          }
        }
        &.table-item-group-general-field-comment {
          padding-left: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
          font-style: italic;
          flex: 2 2;
          white-space: nowrap;
        }
      }
    }
  }
}
