@import "src/scss/includes";

.eds-task-container.eds-task-container-create {
  .etc-header {
    background-color: #691E44;
    color: #ffffff;
    .eui-btn {
      color: #ffffff;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        background-color: rgba(#ffffff, 0.1);
      }
    }
  }
  .etc-body {
    padding-top: 8px;
    .etc-body-field-wrapper {
      padding-top: 16px;
    }
    .etc-body-details {
      margin-top: 10px;
    }
  }
}
