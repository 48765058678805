@import "src/scss/includes";
.adjustment-result {
  border-top: 1px solid $gray10;
  background: $white;
  padding: 23px 28px 7px 23px;

  .ar-top {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 16px;

    .ar-header {
      font-weight: 500;
      color: $black;
    }

    .ar-overhead-withholding {
      font-weight: 300;
      color: $gray50;
    }

    span {
      margin-right: 20px;
    }
  }

  .ar-inputs {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .ar-amount-input input {
      width: 130px;
      margin-right: 15px;
    }

    .ar-type-amount{
      padding-top: 10px;
      padding-left: 3px;
    }

    .ar-calendar-input.eui-date-picker {
      margin-right: 15px;
      display: flex;
      flex-direction: column-reverse;
      .eui-input {
        width: 131px;
      }
      .rdtPicker {
        bottom: 90px;
      }
    }

    .eds-select.ar-vendor-name {
      min-width: 191px;
      width: 191px;
      margin-right: 15px;

      .carat {
        &.up, &.down {
          transform: rotate(180deg)
        }

        &.down {
          bottom: 21px;
        }

        &.up {
          bottom: 16px;
        }
      }

      .r-ss-wrap.r-ss-expanded {
        display: flex;
        flex-direction: column-reverse;


        .r-ss-dropdown {
          top: unset;
          bottom: 68px;

          .r-ss-options-wrap {
            max-height: 250px;
          }
        }
      }
    }

    .reason-field-patient-stipend {
      width: 513px;
      margin-right: 15px;
    }

    .reason-field {
      width: 163px;
      margin-right: 15px;
      .eui-input {
        width: 163px;
        transition: width 1s, margin-left 1s;
        z-index: 1;
        &:focus-within {
          width: 600px;
          margin-left: -437px;
          transition: width 1s, margin-left 1s;
        }
      }
    }
  }
}
